export type EmptyObject = Record<string, never>;
export type Timeout = ReturnType<typeof setTimeout>;
export type Nullable<T> = T | null;

export type ExtractTypeFromArrayOrUndef<T> = T extends (infer U)[] ? U : never;

export type AddSelectProperties<T, U = string> = T & {
  label: string;
  value: U;
};

export interface Pagination {
  last_page: number;
  limit: number;
  next_href: Nullable<string>;
  page: number;
  total_count: number;
}

export interface ErrorWithResponse extends Error {
  response?: {
    status: number;
  };
}

export function isErrorWithResponse(
  error: unknown,
): error is ErrorWithResponse {
  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    typeof (error as ErrorWithResponse).response?.status === 'number'
  );
}

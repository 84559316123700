<template>
  <BittsModal
    :name="reportModalName"
    save-text="Save Preferences"
    :title="titleText"
    :loading="initiallyLoading"
    :visible="showReportNotificationsModal"
    :show-divider="true"
    :disabled="!areAllEmailsValid"
    @saved="onSave"
    @closed="hide"
  >
    <template #content>
      <div class="c-notification-settings">
        <div class="c-notification-settings__content">
          <BittsSelectTags
            v-model="emails"
            :form-label="{ title: 'Email Addresses' }"
            class="mb-16"
          />
          <span v-if="!areAllEmailsValid" class="text-danger-text mb-24 text-sm"
            >Must be valid email addresses</span
          >
          <div>
            <div v-if="slackApp && slackApp.is_enabled">
              <BittsSelect
                v-model="selectedChannelName"
                :allow-clear="true"
                :form-label="{
                  title: 'Slack Channel',
                  helpText: `Connecting a Slack channel will notify ${ACCOUNT_OWNER_DISPLAY}s directly through Slack mentions. If you are not syncing your ${ACCOUNT_OWNER_DISPLAY} emails, we will use their first and last name.`,
                }"
                :options="slackChannels"
                option-type="svg"
                placeholder="Select Channel"
                class="mb-16"
                @update:model-value="checkChannelStatus"
              />
              <BittsAlert
                v-if="needsReauth"
                :description="reauthDescription"
                message="Your Slack Integration requires an update"
                color="error"
                class="mb-24"
              />
              <BittsAlert
                v-else-if="channelWasDeleted"
                :description="deletedChannelDescription"
                message="We stopped sending Slack notifications to a channel"
                color="error"
                class="mb-24 pt-12"
              >
                <template #link>
                  <a
                    href="https://help.crossbeam.com/en/articles/3639783-slack-app"
                    class="border-b-solid border-b-2 border-neutral-400"
                  >
                    Learn more
                  </a>
                </template>
              </BittsAlert>
              <BittsAlert
                v-else-if="noSharedChannels"
                :description="trySlackConnectDescription"
                message="You should try using Slack Connect"
                color="info"
                class="mb-24 pt-12"
              >
                <template #icon>
                  <span class="text-lg"> 🤝 </span>
                </template>
              </BittsAlert>
              <BittsAlert
                v-if="showCantFindPartnerWarningText"
                :message="getCantFindPartnerWarningText.title"
                :description="getCantFindPartnerWarningText.body"
                color="warning"
                class="mb-24"
              />
              <BittsAlert
                v-if="showSharedWithPartnersText"
                :message="getSharedWithPartnersText.title"
                :description="getSharedWithPartnersText.body"
                color="warning"
                class="mb-24"
              />
            </div>
            <ConnectSlackButton v-else :next-url="nextUrl" />
          </div>
        </div>
        <span v-if="error" class="c-notification-settings__error">
          {{ error }}
        </span>
      </div>
    </template>
  </BittsModal>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsModal,
  BittsSelect,
  BittsSelectTags,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import { sortBy, uniqBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ConnectSlackButton from '@/components/ConnectSlackButton.vue';

import { crossbeamApi } from '@/api';
import { ACCOUNT_OWNER_DISPLAY } from '@/constants/mdm';
import { PARTNER_GREENFIELD } from '@/constants/reports';
import { captureException } from '@/errors';
import {
  ReportNotificationConfig,
  ReportNotificationConfigEmail,
  ReportNotificationConfigPayload,
} from '@/interfaces/reports';
import {
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
} from '@/stores';
import { Nullable } from '@/types/common';
import { ReportSlackChannel } from '@/types/reports';

type Props = {
  modelValue?: ReportNotificationConfig[];
  partnerPopulationIds?: number[];
  reportModalName?: string;
  reportName?: string;
  showReportNotificationsModal?: boolean;
};
const {
  modelValue = [],
  partnerPopulationIds = [],
  reportModalName = '',
  reportName = '',
  showReportNotificationsModal = false,
} = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', response: ReportNotificationConfig[]): void;
  (event: 'notifications-modal-hidden'): void;
}>();

const VALID_SLACK_CHANNEL = /^[a-z\d-_]+$/;
const BEGINS_WITH_HASHTAG = /^#/;
const VALID_EMAIL = /\S+@\S+\.\S+/;

const error = ref(null);
const initiallyLoading = ref(false);
const saving = ref(false);

const val = computed(() => modelValue);
const rules = {
  modelValue: {
    slack: {
      channel: {
        id: {
          maxLength: maxLength(80),
          validSlackChannel: (id: string) => {
            return (
              !id ||
              (BEGINS_WITH_HASHTAG.test(id) &&
                VALID_SLACK_CHANNEL.test(id.replace('#', ''))) ||
              VALID_SLACK_CHANNEL.test(id)
            );
          },
        },
      },
    },
  },
};
const v$ = useVuelidate(rules, { modelValue: val });

const route = useRoute();
const router = useRouter();

const flashesStore = useFlashesStore();

const { getPartnerOrgById, readySync: partnersReadySync } = usePartnersStore();

const { getPartnerPopulationById } = usePopulationsStore();

const reportsStore = useReportsStore();
const { slackApp } = storeToRefs(reportsStore);

const reportId = computed<string>(() => {
  return (
    (route?.params?.report_id as string) || (route?.query?.id as string) || ''
  );
});
const report = computed(() => {
  return reportId.value ? reportsStore.getByReportId(reportId.value) : null;
});
const reportPartnerIdList = computed(() => {
  let partners: number[] = [];
  if (partnerPopulationIds) {
    partners = uniqBy(
      partnerPopulationIds.map(
        (id: number) => getPartnerPopulationById(id)?.organization_id as number,
      ),
      'id',
    );
  }
  return partners;
});

const emails = ref<string[]>([]);
const areAllEmailsValid = computed(() =>
  emails.value.every((email) => isEmailValid(email)),
);
function isEmailValid(email: string) {
  return VALID_EMAIL.test(email.toLowerCase());
}

type SlackChannel = ReportSlackChannel & {
  label: string;
  value: string;
  svg: string;
};
const slackChannels = computed<SlackChannel[]>(() => {
  if (!slackApp.value || !slackApp.value.channel_list) return [];
  const channels = slackApp.value.channel_list.map(
    (channel: ReportSlackChannel) => {
      return {
        ...channel,
        label: channel.name,
        value: channel.name,
        svg: getSvgName(channel),
      } as SlackChannel;
    },
  );
  return sortBy(channels, 'name');
});
function getSvgName(channel: ReportSlackChannel) {
  switch (channel.channel_type) {
    case 'private-channel':
      return 'slackPrivate';
    case 'public-channel':
      return 'slackChannel';
    default:
      return 'slackShared';
  }
}

const selectedChannelName = ref<Nullable<string>>(null);
const selectedChannel = computed(() => {
  return slackChannels.value.find(
    (channel) => channel.name === selectedChannelName.value,
  );
});

const allPartnersInChannel = ref<Nullable<boolean>>(null);
const sharedChannelInfo = ref<Nullable<ReportSlackChannel>>(null);
const hasUnknownPartners = ref<Nullable<boolean>>(null);
const channelWasDeleted = ref<Nullable<boolean>>(null);

const isSelectedChannelShared = computed(
  () => selectedChannel.value?.channel_type === 'shared-channel',
);
const isPartnerDataOnly = computed(
  () => report.value?.consolidated_report_type === PARTNER_GREENFIELD,
);
const needsReauth = computed(() => slackApp.value?.needs_reauth);
const noSharedChannels = computed(() => {
  return slackChannels.value?.every(
    (channel) => channel.channel_type !== 'shared-channel',
  );
});

function rehydrate() {
  const slackNotification = modelValue.find(
    (notification) => notification.notification_type === 'slack',
  );
  if (!slackNotification) selectedChannelName.value = null;
  if (slackNotification && slackChannels.value) {
    if (slackNotification.channel_id || slackNotification.properties) {
      const slackId =
        slackNotification.channel_id || slackNotification.properties.channel_id;
      selectedChannelName.value =
        slackChannels.value.find((channel) => channel.id === slackId)?.name ||
        '';
    } else if (slackNotification.slack_channel) {
      selectedChannelName.value =
        slackChannels.value.find(
          (channel) => channel.name === slackNotification.slack_channel,
        )?.name || '';
    }
    channelWasDeleted.value = !selectedChannelName.value;
  }

  emails.value = modelValue
    .filter((notification) => notification.notification_type === 'email')
    .reduce(
      (notifications: string[], notification: ReportNotificationConfigEmail) =>
        notifications.concat(notification.emails),
      [],
    );
}

onMounted(async () => {
  initiallyLoading.value = true;
  await partnersReadySync;
  await reportsStore.loadSlack();
  rehydrate();
  if (isSelectedChannelShared.value) {
    try {
      await checkChannelStatus(selectedChannel.value?.name || '');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      initiallyLoading.value = false;
      error.value = err.response.data.errors
        ? parseErrors(err.response.data.errors)
        : err;
      captureException(err);
    } finally {
      initiallyLoading.value = false;
    }
  }
  initiallyLoading.value = false;
});
async function checkChannelStatus(channel: string) {
  if ((Array.isArray(channel) && !channel.length) || !channel) {
    selectedChannelName.value = null;
    return;
  }
  selectedChannelName.value = channel;
  if (
    selectedChannel.value &&
    selectedChannel.value.channel_type === 'shared-channel'
  ) {
    saving.value = true;
    try {
      const path = { id: selectedChannel.value.id };
      const response = await crossbeamApi.GET('/v0.1/slack-app/channels/{id}', {
        params: { path },
      });
      sharedChannelInfo.value = response.data || null;
      hasUnknownPartners.value = response.data?.has_unknown_partners || null;
      allPartnersInChannel.value = reportPartnerIdList.value.every((id) =>
        response.data?.known_partner_org_ids?.includes(id),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      sharedChannelInfo.value = null;
      hasUnknownPartners.value = null;
      allPartnersInChannel.value = null;
      saving.value = false;
      error.value = err.response.data.errors
        ? parseErrors(err.response.data.errors)
        : err;
      captureException(err);
    } finally {
      saving.value = false;
    }
  } else {
    hasUnknownPartners.value = null;
    allPartnersInChannel.value = null;
    sharedChannelInfo.value = null;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseErrors(errors: any[]) {
  return errors
    .map((errorCode) => {
      if (errorCode === 'channel_not_found') {
        return `#${selectedChannel.value?.name || 'Selected channel'} is not a channel in your Slack organization.
          Please specify a valid channel and try again.`;
      }
      return errorCode;
    })
    .join(' ');
}

const titleText = computed(() => {
  let result = 'Notification Settings';
  if (reportName) result += ` for ${reportName}`;
  return result;
});
const itemLabel = computed(() => {
  if (isPartnerDataOnly.value) return 'partner record';
  return 'overlap';
});

const showCantFindPartnerWarningText = computed(() => {
  return (
    selectedChannelName.value &&
    isSelectedChannelShared.value &&
    sharedChannelInfo.value &&
    !saving.value &&
    isPartnerDataOnly.value &&
    !allPartnersInChannel.value
  );
});
const getCantFindPartnerWarningText = {
  title: "We can't find your report partners in this Slack Connect channel",
  body: `Ensure your partners are in this Slack Connect channel and have the Crossbeam Slack integration installed for us to include their ${itemLabel.value} information in these notifications. Make sure this channel only includes people you want to have this partner record notification`,
};

const showSharedWithPartnersText = computed(() => {
  return (
    selectedChannelName.value &&
    isSelectedChannelShared.value &&
    sharedChannelInfo.value &&
    !saving.value &&
    !showCantFindPartnerWarningText.value
  );
});
const getSharedWithPartnersText = computed(() => {
  return {
    title: sharedChannelInfo.value?.known_partner_org_ids?.length
      ? `This report is sending notifications to a Slack Connect Channel with ${getOrgNames()}`
      : 'This report is sending notifications to a Slack Connect Channel',
    body: hasUnknownPartners.value
      ? `Note, the Crossbeam Slack integration hasn't been added by all organizations in this channel so we don't know who they are. Make sure this channel only includes people you want to have this ${itemLabel.value} information.`
      : '',
  };
});
function getOrgNames() {
  let orgNamesString = '';
  if (!sharedChannelInfo.value?.known_partner_org_ids) return orgNamesString;
  const orgNames = sharedChannelInfo.value.known_partner_org_ids
    .map((id) => getPartnerOrgById(id)?.name)
    .filter((name) => !!name);
  if (!orgNames) return orgNamesString;
  if (orgNames.length > 1) {
    const commaSeparatedOrgs = orgNames.slice(0, orgNames.length - 1);
    orgNamesString += commaSeparatedOrgs.join(', ');
    orgNamesString += ` and ${orgNames[orgNames.length - 1]}`;
  } else {
    orgNamesString = orgNames[0] as string;
  }
  return orgNamesString;
}

const deletedChannelDescription =
  "We don't have access to this channel anymore, Crossbeam was either removed or the channel was deleted.";
const reauthDescription =
  'This application has new and exciting features and needs to be updated to work properly. Visit the Integrations Page to update this.';
const trySlackConnectDescription =
  'Try using a Slack Connect channel with your partner to send shared alerts and collaborate instantly.';

const toServer = computed(() => {
  const notifications = [];
  if (emails.value.length) {
    const serverEmails = emails.value;
    notifications.push({
      notification_type: 'email',
      emails: serverEmails,
    });
  }
  if (selectedChannelName.value) {
    notifications.push({
      notification_type: 'slack',
      channel_id: selectedChannel.value?.id || '',
    });
  }
  return notifications;
});
async function onSave() {
  v$.value.$touch();
  if (v$.value.$invalid) return;
  saving.value = true;
  try {
    const response = await reportsStore.saveNotificationConfigs({
      reportId: reportId.value,
      notifications: toServer.value as ReportNotificationConfigPayload[],
    });
    emit('update:modelValue', response);
    hide();
    flashesStore.addSuccessFlash({ message: 'Notification Settings Saved' });
    await reportsStore.refreshReportsStore();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    saving.value = false;
    error.value = err.response.data.errors
      ? parseErrors(err.response.data.errors)
      : err;
    captureException(err);
  } finally {
    saving.value = false;
  }
}

function hide() {
  error.value = null;
  emit('notifications-modal-hidden');
  rehydrate();
}

const nextUrl = computed(() => {
  const { name, params } = route;
  const url = router.resolve({
    name,
    params,
    query: {
      show_notification_configs: 'true',
    },
  }).href;
  return url;
});

watch([() => modelValue, () => report.value], () => {
  if (!initiallyLoading.value) rehydrate();
});
</script>
<style lang="pcss" scoped>
.c-notification-settings {
  min-height: 100px;
}

.c-notification-settings__header {
  @apply flex items-center justify-between p-24;
}

.c-notification-settings__content {
  @apply flex flex-col;
}

.c-notification-settings__footer {
  @apply flex p-24 pt-0 w-full justify-end;
}

.c-notification-settings__selector {
  @apply border-solid border-2 border-neutral-200 mb-24;
  border-radius: 4px !important;
}

.c-notification-settings__error {
  @apply text-brand-red pb-24;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
}

.c-notification-settings__email-invites {
  padding-bottom: 12px;
}

.c-notification-settings__loading {
  height: 500px;
}

.c-notification-settings__add-email {
  position: absolute;
  font-size: 24px;
  margin-top: -42px;
  right: 0px;
  padding-right: 40px;
}

.c-notification-settings__text-input {
  font-size: 12px;
}

.c-notification-settings__slack-button {
  width: 160px;
}
</style>

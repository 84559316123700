<template>
  <BittsCallout
    v-if="typeof uniqueRecordCount === 'number'"
    :icon="['fad', 'file-export']"
    :title="title"
    type="neutral"
    size="x-small"
    class="unique-records-callout"
  >
    <template #right-item>
      <BittsButton
        class="bg-transparent ml-auto"
        @click="isRecordExportModalVisible = !isRecordExportModalVisible"
        type="primary"
        variant="outline"
        size="x-small"
        text="Learn more"
      />
    </template>
  </BittsCallout>
</template>

<script setup lang="ts">
import { BittsButton, BittsCallout } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { usePopulationsStore } from '@/stores';

const isRecordExportModalVisible = defineModel<boolean>();

const populationsStore = usePopulationsStore();
const { uniqueRecordCount } = storeToRefs(populationsStore);
const title = computed(
  () => `Your populations include ${uniqueRecordCount.value} unique records`,
);
</script>

<template>
  <BittsLoading :is-loading="loading">
    <BittsLayout variant="equal" :min-col-width="240" :gap="16">
      <template
        v-for="option in dataSources"
        :key="'opt_' + option.type"
        #[option.type]
      >
        <component
          :is="tooltipOrDiv(option)"
          v-bind="tooltipProps"
          class="h-full"
          @click.prevent="() => optionClick(option)"
        >
          <BittsCard class="c-datasource-grid__body">
            <div
              :class="{
                'c-datasource-grid__option--disabled': isDisabled(option),
              }"
              class="c-datasource-grid__option"
            >
              <div class="flex justify-end gap-6 absolute top-16 right-16">
                <BittsTag
                  v-if="showBeta(option)"
                  variant="rounded"
                  size="tiny"
                  text="Beta"
                  color="beta"
                />
                <div
                  v-if="showCheck(option)"
                  class="c-datasource-grid__option__status"
                >
                  <FontAwesomeIcon
                    :icon="['fas', 'check-circle']"
                    :style="{ height: '24px', width: '24px' }"
                  />
                </div>
                <div
                  v-else-if="showWarning(option)"
                  class="c-datasource-grid__option__status c-datasource-grid__option__status--warning"
                >
                  <FontAwesomeIcon
                    :icon="['fas', 'exclamation-triangle']"
                    :style="{ height: '24px', width: '24px' }"
                  />
                </div>
              </div>
              <BittsSvg :svg="option.type" class="max-w-[204px]" />
            </div>
            <div v-if="!option.is_supported" class="c-datasources-grid__voter">
              <BittsTooltip
                v-if="option.friendly_name.length > 20"
                placement="top"
                class="flex items-center"
              >
                <div class="c-datasources-grid__voter__title">
                  {{ shortenName(option.friendly_name) }}
                </div>
                <template #title>
                  {{ option.friendly_name }}
                </template>
              </BittsTooltip>
              <div v-else class="c-datasources-grid__voter__title">
                {{ option.friendly_name }}
              </div>
            </div>
          </BittsCard>
          <template #title>
            You can only have {{ maxConnections }} Salesforce connections
          </template>
        </component>
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script>
import {
  BittsButton,
  BittsCard,
  BittsLayout,
  BittsLoading,
  BittsSvg,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';

import { mapActions, mapState } from 'pinia';

import {
  COMPLETED_STATUSES,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  MAX_SALESFORCE_CONNECTIONS,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import {
  MULTI_SELECT_SF,
  TEMP_PIPEDRIVE_BETA,
} from '@/constants/feature_flags';
import { useFeatureFlagStore, useFeedsStore } from '@/stores';

export default {
  name: 'DataSourceGrid',
  components: {
    BittsTooltip,
    BittsCard,
    BittsButton,
    BittsSvg,
    BittsLayout,
    BittsLoading,
    BittsTag,
  },
  props: {
    dataSources: {
      type: Array,
      default: () => [],
    },
    enableRetry: {
      type: Boolean,
      default: false,
    },
    tables: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      loading: true,
      buttonHover: false,
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useFeedsStore, ['feeds']),
    hasMulti() {
      return this.hasFeatureFlag(MULTI_SELECT_SF);
    },
    hasPipedriveBeta() {
      return this.hasFeatureFlag(TEMP_PIPEDRIVE_BETA);
    },
    maxConnections() {
      return MAX_SALESFORCE_CONNECTIONS;
    },
    typesConnected() {
      return this.feeds.reduce((acc, f) => {
        let status;
        if (f.integration.type !== FILE_UPLOAD_DATA_SOURCE_TYPE) {
          status = f.status;
        } else {
          status = this.tables.length > 0 ? 'CONFIGURED' : null;
        }
        acc[f.integration.type] = status;
        return acc;
      }, {});
    },
    tooltipProps() {
      return {
        trigger: 'hover',
        placement: 'top',
        hideArrow: false,
      };
    },
  },
  async created() {
    await this.refreshFeedsStore();
    this.loading = false;
  },
  methods: {
    ...mapActions(useFeedsStore, [
      'refreshFeedsStore',
      'getFeedsByDataSourceType',
    ]),
    shortenName(name) {
      return name.slice(0, 20).concat('...');
    },
    optionClick(option) {
      if (!this.buttonHover && !this.isDisabled(option)) {
        this.$emit('input', option);
      }
    },
    isComplete(option) {
      return (
        this.typesConnected[option.type] &&
        COMPLETED_STATUSES.includes(this.typesConnected[option.type])
      );
    },
    isDisabled(option) {
      if (this.hasMulti && option.type === SALESFORCE_DATA_SOURCE_TYPE) {
        return (
          this.getFeedsByDataSourceType('salesforce').length ===
          MAX_SALESFORCE_CONNECTIONS
        );
      }
      return (
        (option.type !== FILE_UPLOAD_DATA_SOURCE_TYPE &&
          this.isComplete(option)) ||
        (this.typesConnected[option.type] && !this.enableRetry)
      );
    },
    tooltipOrDiv(option) {
      return this.hasMulti &&
        option.type === SALESFORCE_DATA_SOURCE_TYPE &&
        this.isDisabled(option)
        ? 'BittsTooltip'
        : 'div';
    },
    leftIconClasses(option) {
      return `h-12 w-12${option.voted_by_org ? ' text-success-accent' : ''}`;
    },
    showCheck(option) {
      return (
        this.isComplete(option) &&
        !(this.hasMulti && option.type === 'salesforce')
      );
    },
    showWarning(option) {
      return (
        this.typesConnected[option.type] &&
        !(this.hasMulti && option.type === 'salesforce') &&
        this.typesConnected[option.type] !== 'DELETING'
      );
    },
    showBeta(option) {
      return (
        option.type === PIPEDRIVE_DATA_SOURCE_TYPE && this.hasPipedriveBeta
      );
    },
  },
};
</script>
<style lang="pcss">
.c-datasource-grid__option.c-datasource-grid__option--upsell {
  background: #fffc;
  &:hover {
    cursor: not-allowed;
    box-shadow: none;
  }
  .c-datasources-grid-option__image {
    opacity: 0.5;
  }
}

.c-datasource-grid__body {
  @apply flex flex-col justify-center items-center relative h-full;
  height: 168px;

  &:hover {
    @apply cursor-pointer;
  }

  a {
    text-decoration: none;
  }
}

.c-datasource-grid__option__status {
  @apply flex flex-row-reverse text-success-accent;
  top: 16px;
  right: 16px;
}

.c-datasource-grid__option__status--warning {
  @apply text-warning-accent;
}

.c-datasource-grid__option {
  padding: 16px;
}

.c-datasource-grid__option--disabled {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
    box-shadow: none;
  }
}

.c-datasources-grid__voter {
  @apply flex justify-between content-center py-8 px-12 w-full border border-solid border-neutral-border;
}

.c-datasources-grid__voter__title {
  @apply text-neutral-text-strong text-m;
}

.c-datasources-grid-option__image {
  width: 196px;
  height: 96px;
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  margin: 0 auto;
  flex-grow: 1;
}
</style>

<template>
  <div class="export-summary-populations-panel">
    <div class="breakdown-title">
      <BittsTooltip placement="bottomLeft">
        <h3 class="border-b border-dashed">
          Current population unique record count
        </h3>
        <template #title>
          The maximum amount of records that could match and be exported
        </template>
      </BittsTooltip>
      <h3>{{ uniqueRecordCount.toLocaleString() }}</h3>
    </div>
    <SummaryPanelRow
      v-for="(popStat, idx) in mappedStats"
      :key="`${popStat.id}__${idx}__stat`"
      v-bind="popStat"
      :use-distinct-as-total="false"
    >
      <template #row-avatar>
        <FontAwesomeIcon
          class="mr-8 text-neutral-accent"
          :icon="['far', 'chart-pie-simple']"
          data-testid="bucket-avatar"
        />
      </template>
    </SummaryPanelRow>
    <BittsCallout
      v-if="hasCSVPopulations"
      title="Your populations are made with CSVs"
      subtitle="Re-uploading the same CSV data can result in unique records being counted twice"
      :icon="['fad', 'file-csv']"
      size="x-small"
      type="neutral"
      class="mb-8"
      data-testid="csv-populations-callout"
    />
    <p class="text-neutral-text-weak text-sm">
      Total records includes records that may be in multiple populations
    </p>
  </div>
</template>

<script setup lang="ts">
import { BittsCallout, BittsTooltip } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { usePopulationsStore } from '@/stores';

import SummaryPanelRow from './SummaryPanelRow.vue';

const populationsStore = usePopulationsStore();
const { populationStats, uniqueRecordCount, fileUploadPopulations } =
  storeToRefs(populationsStore);
const hasCSVPopulations = computed(() => !isEmpty(fileUploadPopulations.value));

const mappedStats = computed(() =>
  populationStats.value
    .map((stat) => {
      const population = populationsStore.getPopulationById(stat.population_id);
      return population
        ? {
            title: population.name,
            denominator: uniqueRecordCount.value ?? 0,
            numerator: stat.total_size ?? 0,
            id: population.id,
            to: `/populations/${population.id}/edit`,
          }
        : null;
    })
    .filter((item) => item !== null)
    .sort((a, b) => b.numerator - a.numerator),
);
</script>

<style scoped lang="pcss">
h3 {
  @apply text-neutral-text-strong font-bold text-base mb-8;
}

p {
  @apply text-neutral-text-weak text-sm;
}

.breakdown-title {
  @apply flex justify-between mb-16;
}
</style>

<template>
  <div class="c-verify-company">
    <h1>
      {{ titleText }}
    </h1>
    <p v-if="failedVerification">
      Crossbeam performs a verification process to ensure that companies on our
      platform are who they say they are. We were unable to verify your company
      information. If this was a mistake, please reach out to us at
      <Support subject="Company Verification" />.
    </p>
    <p v-else>
      Crossbeam performs a verification process to ensure that companies on our
      platform are who they say they are. As soon as you are verified, this page
      will auto-refresh and take you into Crossbeam. This should take just a few
      minutes during normal business hours. If you encounter any issues, you can
      also reach out to
      <Support subject="Company Verification" />
      for additional support.
    </p>
    <router-link
      v-if="failedVerification"
      :to="{ name: 'company_setup' }"
      class="c-verify-company__button"
    >
      <BittsButton
        text="Register a New Company"
        class="c-verify-company__button"
      />
    </router-link>
    <BittsButton
      v-else
      text="Back to Login"
      variant="outline"
      type="neutral"
      class="c-verify-company__button"
      @click="onLogout"
    />
  </div>
</template>

<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Support from '@/components/Support.vue';

import useAuth from '@/composables/useAuth';
import usePolling from '@/composables/usePolling';
import {
  FAILED,
  PENDING,
  VERIFIED,
  isVerified,
} from '@/constants/verification_status';
import { useRootStore } from '@/stores/RootStore';
import { switchOrganization } from '@/utils';

useHead({ title: 'Company Verification - Crossbeam' });

const rootStore = useRootStore();
const route = useRoute();
const router = useRouter();
const next = computed(() => route.query.next);

const { currentOrg, authorizations } = useAuth();
const firstVerifiedOrg = computed(() =>
  authorizations.value.find((auth) => isVerified(auth.organization)),
);
const verificationStatus = computed(() => currentOrg.value.verification_status);

onMounted(async () => {
  if (verificationStatus.value === FAILED && firstVerifiedOrg.value) {
    await switchOrganization(router, firstVerifiedOrg.value.organization.id);
  }
});

/* Reload the user profile until the company is no longer pending.
Only push to the main route if the status is now VERIFIED. */
const poll = usePolling({
  interval: 10000,
  async poller() {
    await rootStore.loadUserProfile();
  },
  shouldComplete() {
    return verificationStatus.value !== PENDING;
  },
  async onComplete() {
    if (verificationStatus.value === VERIFIED) {
      await router.push(next.value || { name: 'main' });
    }
  },
});

poll();

const failedVerification = computed(() => verificationStatus.value === FAILED);
const titleText = computed(() =>
  failedVerification.value
    ? 'Company Identity Verification Failed'
    : "We're Verifying Your Company",
);

async function onLogout() {
  await router.push({ name: 'logout' });
}
</script>
<style lang="pcss" scoped>
.c-verify-company {
  @apply text-center flex flex-col items-center justify-center mx-24;
}

h1 {
  @apply text-brand-navy text-xl md:text-xxl mb-8 md:mb-16 font-bold;
}

p {
  @apply text-base md:text-m leading-6 text-neutral-600 mb-40 md:mb-24 max-w-[800px];
}

.c-verify-company__button {
  @apply w-full md:w-auto;
}
</style>

<template>
  <div class="flex flex-col gap-8 items-start">
    <div
      v-for="(item, index) in featureItems"
      :class="{
        'pb-16': index === featureItems.length - 1,
      }"
      :key="item.text"
    >
      <div class="flex items-center text-base">
        <FontAwesomeIcon
          :icon="comparisonModal ? ['far', 'check'] : ['far', 'add']"
          class="text-secondary-accent h-12 w-12 mr-8"
        />
        <component :is="item.tooltip ? BittsTooltip : 'span'">
          <template #title>
            <p>
              {{ item.tooltip }}
            </p>
          </template>
          <p class="text-neutral-text">
            <span
              v-html="
                item.tooltip
                  ? injectStrings(item.text, {
                      values: [item.injection],
                      bold: false,
                      italic: false,
                      underline: true,
                    })
                  : item.text
              "
            />
          </p>
        </component>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { injectStrings } from '@/utils';

interface Props {
  tier: string;
  comparisonModal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  comparisonModal: false,
});

const featureItems = computed(() =>
  props.tier === 'supernode' ? supernodeItems : connectorItems,
);

const supernodeItems = [
  {
    text: 'Get customized support & enablement with dedicated CSM',
  },
  {
    injection: 'Custom record exports',
    text: '%s annually',
    tooltip: '[COPY TBD]',
  },
  {
    injection: 'ecosystem sales reporting',
    text: 'Access %s',
    tooltip: '[COPY TBD]',
  },
  {
    injection: 'custom roles & permissions',
    text: 'Unlock enterprise-grade %s',
    tooltip: '[COPY TBD]',
  },
  {
    text: 'Segment and compare ecosystem data with unlimited Standard and Custom Populations',
  },
  {
    injection: 'deal attribution',
    text: 'Attribute partner sourced and influenced revenue with %s',
    tooltip: '[COPY TBD]',
  },
  {
    text: 'Unlimited integrations including Salesforce and HubSpot Custom Object',
  },
];

const connectorItems = [
  {
    text: '5,000 unique record exports',
  },
  {
    injection: 'advanced account mapping',
    text: 'Access to %s',
    tooltip: '[COPY TBD]',
  },
  {
    injection: 'Copilot',
    text: 'Use %s to unlock ecosystem intel',
    tooltip: '[COPY TBD]',
  },
  {
    text: '3 Custom Populations',
  },
  {
    injection: 'account lists',
    text: 'Accelerate co-selling using %s',
    tooltip: '[COPY TBD]',
  },
  {
    text: 'Unlimited offline partners',
  },
];
</script>

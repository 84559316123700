<template>
  <BittsDrawer
    :visible="showDrawer"
    :title="title"
    :primary-button-text="saveButtonText"
    :is-saving="isSaving || isDeleting"
    :is-saving-disabled="isReauthorizing || isDeleting"
    @action:primary="saveSettings"
    @closed="onDrawerClosed"
  >
    <template #headerAvatar>
      <BittsAvatar
        :org="avatarLogo"
        shape="square"
        size="small"
        data-testid="copilot-settings-avatar"
        :is-entity="true"
      />
    </template>

    <template #content>
      <section class="p-16 border-b border-neutral-border">
        <IntegrationCardHeader
          v-if="showStatus"
          :statuses="realOrFakeStatuses"
          :status-type="statusType"
          :callout-text="integrationType === 'gong' ? calloutText : undefined"
          :version
          :created
          :auth-by
          :disabled="!hasManageIntegrations"
          :loading="isReauthorizing"
          :hide-reauth="integrationType === 'hubspot'"
          :hide-advanced-settings="integrationType === 'salesforce'"
          @reauthorize="onReauthorize"
        />
        <BittsCallout
          v-else
          action-text="Install"
          type="info"
          :icon="['fas', 'rocket-launch']"
          title="Make sure this integration is installed"
          subtitle="This integration uses a different system, check the status here"
          @bitts-callout-action="onCalloutClick"
          size="small"
        />
      </section>
      <div v-if="hasManageIntegrations" class="copilot_drawer">
        <BittsLoading :is-loading="loading">
          <div class="px-16">
            <div class="flex items-center gap-8">
              <h4 class="text-neutral-text-strong font-bold text-base">
                Control what data shows in Copilot
              </h4>
              <BillingCTA
                v-if="isFreeTier"
                size="x-small"
                data-testid="crossbeam-copilot-settings-cta"
                :billing-interaction="billingCta"
              />
            </div>
            <p class="text-sm text-neutral-text">
              This will affect all instances of Copilot and not count towards
              your record export limit
            </p>
            <IntegrationSettings
              :population-settings="newPopulationSettings"
              setting-type="crossbeam_copilot"
              @save:changes="onSettingsUpdated"
              @update:exclude-partnerships-created-since="
                onUpdateExcludePartnershipsCreatedSince
              "
              :is-disabled="isFreeTier"
            />
          </div>
        </BittsLoading>
      </div>
    </template>
    <template #secondaryButton v-if="gongInfo">
      <BittsButton
        text="Delete integration"
        :left-icon="['fas', 'trash']"
        variant="ghost"
        class="w-1/2"
        type="danger"
        :loading="isDeleting || isReauthorizing || isSaving"
        @click="showDeleteModal = true"
        :disabled="isSaving"
      />
    </template>
  </BittsDrawer>
  <BittsModalTwo
    v-if="showDeleteModal && !!gongInfo"
    variant="confirm"
    title="Delete Integration"
    primary-button-type="danger"
    primary-button-text="Delete"
    description="Are you sure you want to delete the Gong integration?"
    @closed="showDeleteModal = false"
    @action="deleteIntegration(gongInfo)"
  />
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsCallout,
  BittsDrawer,
  BittsLoading,
  BittsModalTwo,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';
import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { isEqual, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useAuth from '@/composables/useAuth';
import useIntegrations from '@/composables/useIntegrations';
import useIteratively from '@/composables/useIteratively';
import { useRouterWrapper } from '@/composables/useRouterWrapper';
import useTrayStatus from '@/composables/useTrayStatus';
import { PCLOUD_INTEGRATIONS } from '@/constants/integrations';
import {
  useBillingStore,
  useConnectionsStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePartnerCloudStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import { MinimalIntegrationProfile } from '@/types/integration_profiles';
import { EnabledTray, TrayIntegrationHeaderStatus } from '@/types/integrations';
import { STANDARD_POPULATIONS } from '@/types/populations';

import IntegrationCardHeader from './IntegrationCardHeader.vue';
import IntegrationSettings from './IntegrationSettings.vue';

const showDrawer = ref(true);
const loading = ref(true);
const isSaving = ref(false);
const isDeleting = ref(false);
const isReauthorizing = ref(false);
const showDeleteModal = ref(false);
const { pushWithExistingQuery, route } = useRouterWrapper();

const integrationProfileStore = useIntegrationProfileStore();
const partnerCloudStore = usePartnerCloudStore();
const flashesStore = useFlashesStore();
const billingStore = useBillingStore();
const populationsStore = usePopulationsStore();
const connectionStore = useConnectionsStore();
const excludePartnershipsCreatedSince = ref<string | undefined>(undefined);

const { populations } = storeToRefs(populationsStore);
const partnersStore = usePartnersStore();
const { iteratively } = useIteratively();
const { isFreeTier } = storeToRefs(billingStore);

const defaultStandardPopSettings = (
  [...STANDARD_POPULATIONS, 'custom'] as const
).map((pop) => ({
  standard_type: pop,
  is_included: ['customers', 'open_opportunities'].includes(pop),
}));

const newPopulationSettings = ref<MinimalIntegrationProfile | undefined>(
  undefined,
);

type Integration = 'salesforce' | 'gong' | 'hubspot' | 'outreach' | 'chrome';
const integrationType = computed<Integration>(
  () => route.params.integration as Integration,
);

const showStatus = computed(() =>
  ['gong', 'hubspot', 'salesforce'].includes(integrationType.value),
);
const isSalesforceCopilot = computed(
  () => integrationType.value === 'salesforce',
);

const salesforceConnection = computed(() =>
  connectionStore.getConnectionByType('salesforce'),
);
const version = computed(() => {
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.config?.app_version
    : undefined;
});

const created = computed(() => {
  if (hubspotInfo.value) {
    return (
      DateTime.fromMillis(hubspotInfo.value.installDate).toISO() ?? undefined
    );
  }
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.connected_at
    : undefined;
});

const authBy = computed(() => {
  if (hubspotInfo.value) {
    return hubspotInfo.value?.installUser.email;
  }
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.connected_by_user_email
    : undefined;
});

const { allEnabledIntegrations } = useIntegrations();

const gongInfo = computed<EnabledTray | undefined>(() =>
  integrationType.value === 'gong'
    ? allEnabledIntegrations.value.find((i) => i.type === 'tray_gong')
    : undefined,
);

const hubspotInfo = computed(() =>
  integrationType.value === 'hubspot'
    ? partnerCloudStore.enabledSalesEdge.find(
        ({ type }) => type === 'salesedge-hubspot',
      )
    : null,
);

const { statusType, calloutText, reauthorizeTray, statuses } =
  useTrayStatus(gongInfo);

const realOrFakeStatuses = computed<TrayIntegrationHeaderStatus[]>(() => {
  if (integrationType.value === 'hubspot') {
    return [
      {
        type: 'success',
        text: 'Active',
        name: 'HubSpot',
      },
    ];
  }
  return integrationType.value === 'gong' ? statuses.value : [];
});

const integration = computed(() => route.params.integration);
const billingCta = computed(() => {
  if (integration.value === 'salesforce') {
    return {
      cta: BILLING_CTAS.SALESFORCE_WIDGET,
      event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_SALESFORCE,
    };
  }
  return {
    cta: BILLING_CTAS.BILLING,
    event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_CHROME,
  };
});

const { hasPermission } = useAuth();
const hasManageIntegrations = hasPermission('manage:integrations');

const basicInfo = computed(() =>
  PCLOUD_INTEGRATIONS.find(
    (i) =>
      i.type ===
      (integrationType.value === 'gong' ? 'tray_gong' : integrationType.value),
  ),
);
const title = computed(() => `Crossbeam Copilot for ${friendlyName.value}`);

const friendlyNameMap = {
  salesforce: 'Salesforce',
  gong: 'Gong',
  hubspot: 'HubSpot',
  outreach: 'Outreach',
  chrome: 'Chrome',
} as const;
type FriendlyName = (typeof friendlyNameMap)[typeof integrationType.value];
const friendlyName = computed<FriendlyName | undefined>(() =>
  integrationType.value ? friendlyNameMap[integrationType.value] : undefined,
);
const avatar = computed(() => basicInfo.value?.avatar);
const avatarLogo = computed(() => {
  return avatar.value
    ? {
        logo_url: new URL(
          `../../assets/pngs/partner-cloud/${avatar.value}`,
          import.meta.url,
        ).href,
      }
    : null;
});

const existingIntegrationProfile = computed(() =>
  integrationProfileStore.getIntegrationProfileByType('crossbeam_copilot'),
);

async function fetchSettings() {
  loading.value = true;
  await Promise.all([
    integrationProfileStore.readySync,
    partnersStore.readySync,
  ]);
  // on initial fetch, we set the date to now
  excludePartnershipsCreatedSince.value = DateTime.now().toISO();
  if (existingIntegrationProfile.value) {
    newPopulationSettings.value = {
      exclude_partnerships_created_since:
        existingIntegrationProfile.value.exclude_partnerships_created_since,
      standard_population_settings: [
        ...existingIntegrationProfile.value.standard_population_settings,
        // add any missing standard populations
        ...defaultStandardPopSettings.filter(
          (pop) =>
            !existingIntegrationProfile.value?.standard_population_settings.some(
              (p) => p.standard_type === pop.standard_type,
            ),
        ),
      ],
      population_settings: existingIntegrationProfile.value.population_settings,
    };
    loading.value = false;
  } else {
    initProfileSettings();
  }
}

const saveButtonText = computed(() => {
  if (isFreeTier.value) return 'Close';
  return isSaving.value ? 'Saving changes' : 'Save changes';
});

function onSettingsUpdated(payload: MinimalIntegrationProfile) {
  newPopulationSettings.value = payload;
}
function onUpdateExcludePartnershipsCreatedSince(isChecked: boolean) {
  newPopulationSettings.value = {
    population_settings: newPopulationSettings.value?.population_settings ?? [],
    standard_population_settings:
      newPopulationSettings.value?.standard_population_settings ?? [],
    exclude_partnerships_created_since: isChecked
      ? null
      : (excludePartnershipsCreatedSince.value ?? null),
  };
}

function onCalloutClick() {
  window.open(basicInfo.value?.learnMore, '_blank');
}

async function onReauthorize() {
  isReauthorizing.value = true;
  if (isSalesforceCopilot.value) {
    connectionStore.reauthSalesforce();
  }
  if (reauthorizeTray) await reauthorizeTray();
  isReauthorizing.value = false;
}

async function saveSettings() {
  if (isFreeTier.value) {
    onDrawerClosed();
    return;
  }

  isSaving.value = true;
  const initialSettings = existingIntegrationProfile.value;

  const outPayload = {
    exclude_partnerships_created_since:
      newPopulationSettings.value?.exclude_partnerships_created_since ?? null,
    population_settings:
      newPopulationSettings.value?.population_settings.map((p) => ({
        population_id: p.population_id,
        is_partner_population: p.is_partner_population,
        is_included: p.is_included,
      })) || [],
    standard_population_settings:
      newPopulationSettings.value?.standard_population_settings.map((p) => ({
        standard_type: p.standard_type,
        is_included: p.is_included,
      })) || [],
  };

  const initialPopulationSettingsMatch = isEqual(
    sortBy(initialSettings?.population_settings, 'population_id'),
    sortBy(outPayload.population_settings, 'population_id'),
  );

  const initialStandardPopulationSettingsMatch = isEqual(
    sortBy(initialSettings?.standard_population_settings, 'standard_type'),
    sortBy(outPayload.standard_population_settings, 'standard_type'),
  );

  const isExcludePartnershipsCreatedSinceChanged =
    !!newPopulationSettings.value?.exclude_partnerships_created_since !==
    !!existingIntegrationProfile.value?.exclude_partnerships_created_since;

  if (
    (initialPopulationSettingsMatch &&
      initialStandardPopulationSettingsMatch &&
      !isExcludePartnershipsCreatedSinceChanged) ||
    !existingIntegrationProfile.value
  ) {
    onDrawerClosed();
    isSaving.value = false;
    return;
  }

  try {
    await integrationProfileStore.putIntegrationProfileSettings(
      existingIntegrationProfile.value.profile_id,
      outPayload,
    );
    flashesStore.addSuccessFlash({
      message: 'Crossbeam Copilot settings saved',
      ...(isExcludePartnershipsCreatedSinceChanged && {
        description: newPopulationSettings.value
          ?.exclude_partnerships_created_since
          ? 'Partners will no longer be added automatically'
          : 'New partners will be automatically added',
      }),
    });
    integrationProfileStore.refreshIntegrationProfileStore();
    // @ts-expect-error - missing values are inserted via setTrackingOptions() in app.js
    iteratively.userSavesCopilotIntegrationSetting({
      integration_name: integration.value as string,
      event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_DRAWER,
    });
    onDrawerClosed();
  } catch (_err) {
    onDrawerClosed();
    flashesStore.addErrorFlash({
      message: 'Your Crossbeam Copilot settings could not be saved',
    });
  } finally {
    isSaving.value = false;
  }
}

async function onDrawerClosed() {
  showDrawer.value = false;
  await pushWithExistingQuery({ name: 'integrations' });
}

async function initProfileSettings() {
  loading.value = true;
  // If there isn't an appropriate integration profile, make a new one and post it.
  const myPopulations = populations.value.map((pop) => ({
    population_id: pop.id,
    is_partner_population: false,
    is_included: true,
  }));

  const payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'> = {
    integration_type: 'crossbeam_copilot',
    population_settings: myPopulations,
    standard_population_settings: defaultStandardPopSettings,
  };

  try {
    newPopulationSettings.value = {
      standard_population_settings: defaultStandardPopSettings,
      population_settings: myPopulations,
    };
    await integrationProfileStore.postIntegrationProfileSettings(payload);
    await integrationProfileStore.refreshIntegrationProfileStore();
  } catch (err) {
    flashesStore.addErrorFlash({ message: (err as Error).message });
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await fetchSettings();
});

const deleteIntegration = async (info: EnabledTray) => {
  try {
    isDeleting.value = true;
    showDeleteModal.value = false;
    await partnerCloudStore.deleteIntegration(info);
  } catch (err) {
    flashesStore.addErrorFlash({
      message: 'Failed to delete integration',
    });
  } finally {
    isDeleting.value = false;
  }
  await pushWithExistingQuery({ name: 'integrations' });
};
</script>

<style lang="pcss">
.copilot_drawer {
  @apply py-16;
}
</style>

<template>
  <div class="c-dont-worry-box">
    <div class="c-dont-worry-box__title">
      <FontAwesomeIcon
        :icon="['fas', icon]"
        :class="`text-${props.color}-accent`"
      />
      <h2>{{ props.title }}</h2>
    </div>
    <ul>
      <li v-for="note in notes" :key="note" class="text-neutral-text text-base">
        {{ note }}
      </li>
    </ul>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  notes: {
    type: Array,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
});

const icon = computed(() => {
  if (props.color === 'success') return 'circle-check';
  if (props.color === 'danger') return 'xmark-circle';
  return 'clock';
});
</script>
<style lang="pcss" scoped>
.c-dont-worry-box {
  @apply text-m px-16 py-12 mb-8 bg-neutral-background-weak rounded-8;
}

ul {
  @apply text-left pl-24 text-neutral-text;
}

li {
  list-style-type: disc;
}

/* Used to make smaller bullets */
li::marker {
  content: '•  ';
}

.c-dont-worry-box__title {
  @apply flex items-center justify-start gap-4;

  h2 {
    @apply text-neutral-text-strong font-bold;
  }
}
</style>

<template>
  <div class="c-data-sources-onboarding">
    <slot name="title" />
    <h3>{{ onboardingTitle }}</h3>
    <p>{{ onboardingDescription }}</p>
    <!-- We show the "add data" state for both pops and data sources if they are
    missing feeds entirely -->
    <div v-if="missingFeeds">
      <BittsLayout :min-col-width="300" :gap="24" class="mt-16" variant="equal">
        <template #crm>
          <OnboardingCard
            :svg="supportedCrm ? `${supportedCrm.type}Icon` : 'database'"
            :feature-list="makeFeatureList(features, [true, true, true, true])"
            :tag="{
              text: 'Recommended',
              color: 'info',
            }"
          >
            <BittsDropdown
              v-if="!supportedCrm"
              :options="crmOptions"
              @selection="({ value }) => connectCrm(value)"
            >
              <template #prompt>
                <PermissionIndicator resource="data-sources">
                  <BittsButton
                    :disabled="viewOnly"
                    text="Connect a CRM"
                    :right-icon="['fas', 'chevron-down']"
                  />
                </PermissionIndicator>
              </template>
            </BittsDropdown>
            <BittsButton
              v-else
              :disabled="viewOnly"
              :text="`Connect ${supportedCrm.friendly_name}`"
              @click="connectCrm(supportedCrm.type)"
            />
            <template #needs>
              <div class="ml-24">
                <li
                  ><span class="need-highlight">Admin</span> permissions in
                  Crossbeam</li
                >
                <li
                  >The field names you will need to filter and segment your
                  data</li
                >
                <li>Credentials for the authenticating user in your CRM</li>
              </div>
              <BittsButton
                text="View Help Article"
                variant="outline"
                type="neutral"
                size="x-small"
                class="w-full mt-auto mb-16"
                @click="viewCrmHelpArticle"
              />
            </template>
          </OnboardingCard>
        </template>
        <template #google_sheets>
          <OnboardingCard
            svg="googleSheetsIcon"
            :feature-list="
              makeFeatureList(features, [true, true, false, false])
            "
          >
            <PermissionIndicator resource="data-sources">
              <BittsButton
                :disabled="viewOnly"
                text="Connect Google Sheets"
                @click="connectCrm(GOOGLE_SHEETS_DATA_SOURCE_TYPE)"
              />
            </PermissionIndicator>
            <template #needs>
              <div class="ml-24">
                <li
                  >An Google Sheets URL not in
                  <span class="need-highlight">XLSX</span> format</li
                >
                <li>An Account & Website column</li>
              </div>
              <a
                href="https://help.crossbeam.com/en/articles/5613345-how-to-use-google-sheets-as-a-data-source"
                class="w-full mt-auto mb-16"
                target="_blank"
              >
                <BittsButton
                  text="View Help Article"
                  variant="outline"
                  type="neutral"
                  size="x-small"
                  class="w-full"
                />
              </a>
            </template>
          </OnboardingCard>
        </template>
        <template #csv>
          <OnboardingCard
            svg="csv"
            :feature-list="
              makeFeatureList(features, [true, false, false, false])
            "
            :tag="{
              text: 'Limited Functionality',
              color: 'warning',
            }"
          >
            <PermissionIndicator resource="data-sources">
              <BittsButton
                :disabled="viewOnly"
                text="Upload a CSV"
                @click="connectCsv"
              />
            </PermissionIndicator>
            <template #needs>
              <div class="ml-24">
                <li>A <span class="need-highlight">CSV</span> file</li>
                <li>An Account & Website column</li>
              </div>
              <a
                href="https://s3.amazonaws.com/assets.getcrossbeam.com/template.csv"
                class="w-full mt-auto mb-16"
              >
                <BittsButton
                  text="Download Template"
                  variant="outline"
                  type="neutral"
                  size="x-small"
                  class="w-full"
                  :right-icon="['fas', 'arrow-down-to-square']"
                />
              </a>
            </template>
          </OnboardingCard>
        </template>
      </BittsLayout>
    </div>
    <!-- Empty state for populations -->
    <StandardPopulations
      v-else-if="!isDataSources && importDataStepStatus === COMPLETE"
      :show-title="false"
    />
    <!-- Empty state for data sources -->
    <div v-else>
      <FeedList v-if="isDataSources" class="mt-16" />
      <BittsCard>
        <div class="empty-state-card">
          <div class="icon-wrapper">
            <FontAwesomeIcon :icon="['fas', 'database']" class="icon" />
          </div>
          <h3>{{ emptyState.title }}</h3>
          <p class="description">
            {{ emptyState.description }}
          </p>
          <BittsButton
            v-if="emptyState.action"
            text="View Data Sources"
            size="small"
            @click="emptyState.action"
          />
        </div>
      </BittsCard>
    </div>
    <LearnMoreCards :type="type" :cards="learnMoreCards" />
  </div>
</template>

<script setup>
import {
  BittsButton,
  BittsCard,
  BittsDropdown,
  BittsLayout,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import FeedList from '@/components/data-sources/FeedList.vue';
import LearnMoreCards from '@/components/onboarding/LearnMoreCards.vue';
import OnboardingCard, {
  makeFeatureList,
} from '@/components/onboarding/OnboardingCard.vue';
import PermissionIndicator from '@/components/PermissionIndicator.vue';
import StandardPopulations from '@/components/populations/StandardPopulations.vue';

import useAuth from '@/composables/useAuth';
import useOnboardingSteps from '@/composables/useOnboardingSteps';
import {
  DATA_TEMPLATES_CRMS,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { COMPLETE } from '@/constants/onboarding';
import { useDataSourcesStore, useFeedsStore } from '@/stores';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const dataSourcesStore = useDataSourcesStore();
const feedsStore = useFeedsStore();
const { currentOrg, hasPermission } = useAuth();
const viewOnly = computed(() => !hasPermission('manage:data-sources'));
const { feeds } = storeToRefs(feedsStore);
const { supportedDataSources } = storeToRefs(dataSourcesStore);

const { importDataStepStatus } = useOnboardingSteps();

const isDataSources = computed(() => props.type === 'data sources');

/* Title, subtitle */
const onboardingTitle = computed(() => {
  if (isDataSources.value || missingFeeds.value) return 'Import Data';
  return 'Standard Populations';
});
const onboardingDescription = computed(() => {
  if (isDataSources.value || missingFeeds.value)
    return 'Replicate fields from your CRM for filtering your data and (eventually) sharing with partners';
  return 'These are core segments we recommend for everyone';
});

/* Data connection logic */
const features = [
  'Completely free to use',
  "Sync as often as you'd like",
  'Select what fields to sync',
  'Import opportunity data',
];

const missingFeeds = computed(() => feeds.value.length === 0);
const preferredCrm = computed(() => {
  if (HUBSPOT_DATA_SOURCE_TYPE === currentOrg.value.preferred_crm) {
    return HS3_DATA_SOURCE_TYPE;
  }
  return currentOrg.value.preferred_crm;
});
const supportedCrm = computed(() => {
  return supportedDataSources.value.find(
    (ds) => ds.type === preferredCrm.value,
  );
});

const crmOptions = computed(() =>
  supportedDataSources.value
    .filter(({ type }) =>
      [...DATA_TEMPLATES_CRMS, SNOWFLAKE_DATA_SOURCE_TYPE].includes(type),
    )
    .map(({ type, friendly_name: name }) => ({
      display: name,
      value: type,
      svg: `${type}Icon`,
    }))
    .sort((a, b) =>
      a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
    ),
);

const currentConnection = computed(() => {
  if (feeds.value.length === 0) return null;
  return feeds.value.at(0).integration.friendly_name;
});
async function connectCrm(type) {
  await router.push({ name: 'data-sources-connect', params: { type } });
}
async function connectCsv() {
  await router.push({ name: 'file-uploads' });
}

const HELP_ARTICLE_MAP = {
  [HUBSPOT_DATA_SOURCE_TYPE]:
    'https://help.crossbeam.com/en/articles/3160183-connect-hubspot-data',
  [SALESFORCE_DATA_SOURCE_TYPE]:
    'https://help.crossbeam.com/en/articles/3160182-connecting-salesforce-data',
  [SNOWFLAKE_DATA_SOURCE_TYPE]:
    'https://help.crossbeam.com/en/articles/4470887-connect-snowflake-data',
  [MD_DATA_SOURCE_TYPE]:
    'https://help.crossbeam.com/en/articles/6952388-connect-microsoft-dynamics',
};

function viewCrmHelpArticle() {
  const article =
    HELP_ARTICLE_MAP[preferredCrm.value] ||
    'https://help.crossbeam.com/en/articles/3162178-video-tutorial-connect-your-crm-create-populations';
  window.open(article, '_blank');
}

/* Informational Cards at Bottom */
const learnMoreCards = computed(() => {
  return {
    'data sources': [
      {
        resource: 'Video',
        caption: '2 minutes',
        title: 'What are Data Sources?',
        description:
          'A rundown of Data Sources and the role they play in the Crossbeam platform',
        link: 'https://help.crossbeam.com/en/articles/6805089-what-are-data-sources',
      },
      {
        resource: 'Article',
        caption: '2 minutes',
        title: 'Data Sources Overview',
        description:
          'A rundown of Data Sources and the role they play in the Crossbeam platform',
        link: 'https://help.crossbeam.com/en/articles/5391508-data-sources-overview-csvs-crms-and-data-warehouses',
      },
      {
        resource: 'Academy',
        caption: '6 courses',
        title: 'Data Sources',
        description:
          'Learn more about this feature through a series of lessons by our team',
        link: 'https://academy.crossbeam.com/',
      },
    ],
    Populations: [
      {
        resource: 'Article',
        caption: '2 minutes',
        title: 'What are Populations?',
        description:
          'A rundown of Populations and the role they play in the Crossbeam platform',
        link: 'https://help.crossbeam.com/en/articles/3160813-what-are-populations',
      },
      {
        resource: 'Video',
        caption: '6 minutes',
        title: 'Connect your CRM & Create Populations',
        description: 'Follow along and get set up quickly',
        link: 'https://help.crossbeam.com/en/articles/3162178-video-tutorial-connect-your-crm-create-populations',
      },
      {
        resource: 'Academy',
        caption: '2 courses',
        title: 'Populations',
        description:
          'Learn more about this feature through a series of lessons by our team',
        link: 'https://academy.crossbeam.com/',
      },
    ],
  }[props.type];
});

/* Empty State */
const emptyState = computed(() =>
  isDataSources.value
    ? {
        title: `Delete your ${currentConnection.value} connection to add a new data source`,
        description: `During onboarding, you can only have 1 data source at a time. ${viewOnly.value ? '' : 'Use the ... on the connection to delete it and try adding something else. After you have your first Population set up you can add as many data sources as you like for free.'}`,
      }
    : {
        title: "Your data isn't available to segment yet",
        description:
          'You’ll be able to see if your data is syncing, if there’s an error, or if something else is going on from the Data Sources page.',
        async action() {
          await router.push({ name: 'data-sources' });
        },
      },
);
</script>

<style lang="pcss" scoped>
.c-data-sources-onboarding {
  h3 {
    @apply text-lg font-bold text-neutral-text-strong;
  }

  p {
    @apply text-neutral-text mt-4 mb-16;
  }

  .need-highlight {
    @apply bg-neutral-background-disabled p-2 rounded-4;
  }

  .empty-state-card {
    @apply flex flex-col items-center p-40;
    .icon {
      @apply text-neutral-accent w-[52px] h-[52px] mb-16;
    }
    .icon-wrapper {
      @apply rounded-full p-20 flex items-center justify-center bg-white bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
    }
    .description {
      @apply text-center max-w-[900px] mt-12;
    }
  }
}
</style>

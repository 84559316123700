<template>
  <div class="c-activity-timeline">
    <div class="c-activity-timeline__wrapper fullstory-hide dd-privacy-mask">
      <h1 class="c-activity-timeline__title"> Activity Timeline </h1>
      <template v-if="!pageLoading">
        <BittsEmptyState
          v-if="showEmptyState"
          title="There's nothing to see here, yet"
          class="c-activity-timeline__empty-state lg:px-120"
          svg-name="timelineNoEventsYet"
        >
          <template #subtitle>
            <p class="text-center text-neutral-500 leading-6">
              {{ flaggedEmptyStateText }}

              <BittsLink
                v-if="!hasEvents"
                class="mt-4"
                text="View our help documentation"
                url="https://help.crossbeam.com/en/articles/3519493-create-reports"
              />
            </p>
            <router-link :to="{ name: 'account_mapping.create' }">
              <BittsButton
                v-if="hasScope('write:reports') && !kafkaEvents.length"
                text="Create report"
                class="mt-24"
              />
            </router-link>
          </template>
        </BittsEmptyState>
        <ActivityTimeline
          v-else
          :events="filteredEvents"
          :account-name="accountName"
          class="mt-16"
        />
      </template>
      <div v-else class="pl-8 mt-24">
        <TimelineEventSkeleton v-for="skeleton in 4" :key="skeleton" />
      </div>
    </div>
    <TimelineSidebar
      v-if="!pageLoading"
      :selected-filters="selectedFilters"
      :partner-orgs-in-record="partnerOrgsInRecord"
      :org-filters="orgFilters"
      :partner-stack-is-active="partnerStackIsActive"
      :gong-is-active="gongIsActive"
      :visible="hasVisiblePartnerEvents"
      @org-filter-checked="orgClickHandler"
      @filter-checked="onCheckedKeys"
    />
  </div>
</template>

<script>
import { BittsButton, BittsEmptyState, BittsLink } from '@crossbeam/bitts';

import { mapActions } from 'pinia';
import { defineComponent } from 'vue';

import ActivityTimeline from '@/components/ActivityTimeline.vue';
import TimelineEventSkeleton from '@/components/records/Timeline/TimelineEventSkeleton.vue';
import TimelineSidebar from '@/components/records/Timeline/TimelineSidebar.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIntegrations from '@/composables/useIntegrations';
import {
  ALL_EVENT_FILTERS,
  ATTRIBUTION_ACTIVITY,
  GONG,
  INTEGRATIONS,
  OVERLAP_MOVEMENT,
  PARTNERSTACK,
  POPULATION_MOVEMENT,
  SALES_EDGE_ACTIVITY,
} from '@/constants/timeline';
import { captureException } from '@/errors';
import { usePartnersStore } from '@/stores';

const EVENT_TYPE_LOOKUP = {
  partner_mention: GONG,
  partnerstack_lead_created: PARTNERSTACK,
  attribution_created: ATTRIBUTION_ACTIVITY,
  attribution_updated: ATTRIBUTION_ACTIVITY,
  attribution_deleted: ATTRIBUTION_ACTIVITY,
  overlap_entry: OVERLAP_MOVEMENT,
  overlap_exit: OVERLAP_MOVEMENT,
  population_entry: POPULATION_MOVEMENT,
  population_exit: POPULATION_MOVEMENT,
  request_received: SALES_EDGE_ACTIVITY,
  request_sent: SALES_EDGE_ACTIVITY,
};

const EVENTS_WITHOUT_PARTNER = [
  'population_entry',
  'population_exit',
  'partnerstack_lead_created',
];

const NUM_EVENTS_OFFSET = 20;

export default defineComponent({
  name: 'IRPActivityTimeline',
  components: {
    ActivityTimeline,
    BittsButton,
    BittsEmptyState,
    BittsLink,
    TimelineEventSkeleton,
    TimelineSidebar,
  },
  props: {
    sourceId: {
      type: Number,
      required: true,
    },
    sourcePrimaryKey: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { currentOrg, hasScope } = useAuth();

    const { allEnabledIntegrations, partnerStackIsActive } = useIntegrations();

    return {
      allEnabledIntegrations,
      partnerStackIsActive,
      currentOrg,
      hasScope,
    };
  },
  data() {
    return {
      checkedKeys: [],
      filteredEvents: [],
      kafkaEvents: [],
      loadUntil: NUM_EVENTS_OFFSET,
      pageLoading: true,
      pageError: null,
      polling: null,
      orgFilters: {},
      selectedFilters: [],
    };
  },
  computed: {
    eventFilterTypes() {
      return [
        {
          type: INTEGRATIONS,
          key: ALL_EVENT_FILTERS[INTEGRATIONS],
        },
        {
          type: ATTRIBUTION_ACTIVITY,
          key: ALL_EVENT_FILTERS[ATTRIBUTION_ACTIVITY],
        },
        {
          type: POPULATION_MOVEMENT,
          key: ALL_EVENT_FILTERS[POPULATION_MOVEMENT],
        },
        {
          type: OVERLAP_MOVEMENT,
          key: ALL_EVENT_FILTERS[OVERLAP_MOVEMENT],
        },
        {
          type: SALES_EDGE_ACTIVITY,
          key: ALL_EVENT_FILTERS[SALES_EDGE_ACTIVITY],
        },
      ];
    },
    hasEvents() {
      return this.kafkaEvents.length > 0;
    },
    flaggedEmptyStateText() {
      if (!this.hasEvents) {
        return `Activities between you and your partners on this account will appear here.
Try creating a report with your partner to see your overlaps.`;
      }
      if (this.hasEvents && !this.filteredEvents.length) {
        return `There are currently no activities that match these filters.
         Try expanding your search by removing filters.`;
      }
      return '';
    },
    showEmptyState() {
      return !this.pageLoading && !this.filteredEvents.length;
    },
    hasSomeOrgFiltersSelected() {
      return Object.values(this.orgFilters).some((val) => val);
    },
    hasSomeFiltersSelected() {
      return this.selectedFilters.length > 0;
    },
    filterFunctions() {
      return {
        [PARTNERSTACK]: this.filterIntegrations,
        [GONG]: this.filterIntegrations,
        [ATTRIBUTION_ACTIVITY]: this.filterCrossbeamActivities,
        [OVERLAP_MOVEMENT]: this.filterCrossbeamActivities,
        [POPULATION_MOVEMENT]: this.filterCrossbeamActivities,
        [SALES_EDGE_ACTIVITY]: this.filterCrossbeamActivities,
      };
    },
    partnerOrgsInRecord() {
      const partnerUuids = this.kafkaEvents.map(
        (event) => event.partner_organization_uuid,
      );
      return [...new Set(partnerUuids)].reduce((finalPartners, currentId) => {
        const partnerInStore = this.getPartnerOrgByUuid(currentId, false);
        if (partnerInStore) finalPartners.push(partnerInStore);
        return finalPartners;
      }, []);
    },
    hasVisiblePartnerEvents() {
      const partnerEvents = this.kafkaEvents.some(
        (event) => event?.partner_organization_uuid,
      );
      return !!partnerEvents;
    },
    gongIsActive() {
      return !!this.allEnabledIntegrations.find(
        (integration) => integration.type === 'tray_gong',
      );
    },
  },
  watch: {
    filteredEvents() {
      if (
        this.loadUntil > this.filteredEvents.length &&
        this.loadUntil > NUM_EVENTS_OFFSET &&
        this.hasSomeFiltersSelected &&
        this.hasSomeOrgFiltersSelected
      ) {
        this.loadUntil = this.filteredEvents.length;
      }
    },
  },
  async created() {
    await this.initialize();
    this.polling = setInterval(this.onPoll, 10000);
    // set BittsTree to main/root filter which triggers all sub-filters to activate
    this.selectedFilters = [
      ALL_EVENT_FILTERS[ATTRIBUTION_ACTIVITY],
      ALL_EVENT_FILTERS[POPULATION_MOVEMENT],
      ALL_EVENT_FILTERS[OVERLAP_MOVEMENT],
      ALL_EVENT_FILTERS[SALES_EDGE_ACTIVITY],
      ALL_EVENT_FILTERS[INTEGRATIONS],
    ];
    this.partnerOrgsInRecord.forEach((partnerOrg) => {
      this.orgFilters[partnerOrg.uuid] = true;
    });
    this.filterEvents();
  },
  unmounted() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgByUuid']),
    async onPoll() {
      await this.updateTimeline();
    },
    orgClickHandler({ id }) {
      this.orgFilters[id] = !this.orgFilters[id];
      this.filterEvents();
    },
    async updateTimeline() {
      await this.fetchEvents();
    },
    async initialize() {
      this.pageLoading = true;
      try {
        const partnersStore = usePartnersStore();
        const promises = [this.updateTimeline(), partnersStore.readySync];
        await Promise.all(promises);
      } catch (err) {
        this.pageError = err;
        captureException(err);
      } finally {
        this.pageLoading = false;
      }
    },
    async fetchEvents() {
      const allEvents = [];
      const limit = 1000;
      let page = 1;
      let nextHref = null;
      const response = await crossbeamApi.GET(
        '/v0.1/activity-timeline/{source_id}/{master_id}',
        {
          params: {
            query: {
              limit,
              page,
            },
            path: {
              source_id: this.sourceId,
              master_id: this.sourcePrimaryKey,
            },
          },
        },
      );
      if (response) allEvents.push(...response.data.items);
      nextHref = response?.data?.pagination.next_href;
      page = response?.data?.pagination.page + 1;
      while (nextHref) {
        const { data } = await crossbeamApi.GET(
          '/v0.1/activity-timeline/{source_id}/{master_id}',
          {
            params: {
              query: {
                page,
                limit,
              },
              path: {
                source_id: this.sourceId,
                master_id: this.sourcePrimaryKey,
              },
            },
          },
        );
        allEvents.push(...data.items);
        nextHref = data.pagination.next_href;
        page = data.pagination.page + 1;
      }
      this.kafkaEvents = allEvents
        .map(this.addEventType)
        .filter((event) =>
          Object.values(EVENT_TYPE_LOOKUP).includes(event.eventType),
        );
      this.filterEvents();
    },
    addEventType(event) {
      return {
        ...event,
        eventType: EVENT_TYPE_LOOKUP[event.type],
      };
    },
    onCheckedKeys({ keys }) {
      this.selectedFilters = keys;
      this.filterEvents();
    },
    filterEvents() {
      this.filteredEvents = this.kafkaEvents
        .filter((event) => {
          const defaultFilterFunction = () => false;
          const filterFunction =
            this.filterFunctions[event.eventType] || defaultFilterFunction;
          return filterFunction(event);
        })
        .filter(this.filterByOrg);
    },
    filterIntegrations(event) {
      if (this.selectedFilters?.includes(ALL_EVENT_FILTERS[INTEGRATIONS])) {
        return true;
      }
      return this.selectedFilters.includes(event.eventType);
    },
    filterCrossbeamActivities(event) {
      const eventParentType = event.eventType;
      const allSelector = this.eventFilterTypes.find(
        (eventFilterType) => eventFilterType.type === eventParentType,
      )?.key;
      if (this.selectedFilters?.includes(allSelector)) {
        return true;
      }
      return this.selectedFilters?.includes(event.type);
    },
    filterByOrg(event) {
      // don't filter out events that never have a partner on them
      if (EVENTS_WITHOUT_PARTNER.includes(event.type)) return event;
      return this.orgFilters[event.partner_organization_uuid];
    },
  },
});
</script>

<style lang="pcss">
.c-activity-timeline {
  @apply grid grid-cols-10 gap-24;

  .c-activity-timeline__wrapper {
    @apply col-span-7;
  }

  .c-activity-timeline__title {
    @apply text-lg font-bold text-neutral-text-strong mb-8;
  }

  .c-activity-timeline__sidebar {
    @apply col-span-3 self-start sticky flex-1;
    --offset: 120px;
    top: 120px;
  }

  .c-activity-timeline__org-selector {
    @apply flex items-center justify-between py-12 px-16 border-b border-neutral-border;

    &:last-child {
      @apply border-0;
    }
  }
  .c-activity-timeline__empty-state {
    &.c-bitts-empty-state-large-border {
      @apply bg-white;
    }
    @apply px-24 py-72;
    img {
      @apply w-260;
    }
  }
}
</style>

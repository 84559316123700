<template>
  <BittsModalTwo
    variant="data"
    primary-button-text="Upgrade to Connector"
    :show-close-button="false"
    container-class="c-billing-plan-comparison-modal"
    :width="1200"
    @closed="closeModal"
    @action="onUpgrade"
  >
    <template #header>
      <p class="text-neutral-text-strong font-bold text-xxl pb-8">
        Unlock more with
        {{
          infoMappedToCTA[billingInteraction?.cta_2 || billingInteraction?.cta]
            ?.title
        }}
      </p>
      <p class="text-neutral-text-weak text-base">
        {{
          infoMappedToCTA[billingInteraction?.cta_2 || billingInteraction?.cta]
            ?.description
        }}
      </p>
    </template>
    <BittsSegment
      class="mb-24"
      :tabs
      variant="segmented_control"
      @change:segment="pickPeriod"
    >
      <template #post-title="{ id }">
        <span v-if="id === 'year'" class="text-success-text font-normal">
          (Save 10%)
        </span>
        <span v-else class="text-neutral-text-weak font-normal">
          (Requires annual commitment)
        </span>
      </template>
    </BittsSegment>
    <div class="flex gap-24 mb-40">
      <BillingPlanComparisonCard
        tier="connector"
        @action="onUpgrade"
        :period="pickedPeriod"
      />
      <BillingPlanComparisonCard
        tier="supernode"
        @action="onTalkToSales"
        :period="pickedPeriod"
      />
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo, BittsSegment } from '@crossbeam/bitts';
import { BILLING_CTAS, CTA_2, EVENT_SITES } from '@crossbeam/itly';

import { computed, ref } from 'vue';

import BillingPlanComparisonCard from '@/components/billing/BillingPlanComparisonCard.vue';

import useBilling from '@/composables/useBilling';
import { BillingInteraction, BillingQuery } from '@/types/billing';

const props = withDefaults(
  defineProps<{
    billingInteraction: BillingInteraction;
    billingQuery?: BillingQuery | null;
  }>(),
  {
    billingQuery: null,
  },
);

const emit = defineEmits<(e: 'close-modal') => void>();
const { handleBillingInteraction } = useBilling();

const tabs = computed(() => [
  {
    title: '1 payment',
    'post-title': '(Save 10%)',
    id: 'year',
  },
  {
    title: '12 payments',
    'post-title': '(Requires annual commitment)',
    id: 'month',
  },
]);

const pickedPeriod = ref<'year' | 'month'>('year');
function pickPeriod(value: 'year' | 'month') {
  pickedPeriod.value = value;
}

function closeModal() {
  emit('close-modal');
}

function onUpgrade() {
  const interactionData = {
    ...props.billingInteraction,
    period: pickedPeriod.value,
  };

  if (props.billingQuery) {
    handleBillingInteraction(interactionData, props.billingQuery);
  } else {
    handleBillingInteraction(interactionData);
  }
}

function onTalkToSales() {
  closeModal();
  handleBillingInteraction({
    talkToSalesReason: 'Upgrade to Supernode',
    cta: BILLING_CTAS.BILLING,
    event_site: EVENT_SITES.SELF_SERVE_FLOW_COMPARISON_MODAL,
  });
}

const infoMappedToCTA = computed(() => {
  return {
    [CTA_2['single-partner-oppty']]: {
      title: 'Pipeline Reporting',
      description: `Uncover your highest ROI opportunities with one of our most
    comprehensive reports`,
    },
    [CTA_2['partner-tags']]: {
      title: '[FEATURE]',
      description: `[COPY TBD]`,
    },
    [CTA_2['single-partner-greenfield']]: {
      title: '[FEATURE]',
      description: `[COPY TBD]`,
    },
    [CTA_2.custom]: {
      title: '[FEATURE]',
      description: `[COPY TBD]`,
    },
    [CTA_2.standard]: {
      title: '[FEATURE]',
      description: `[COPY TBD]`,
    },
  };
});
</script>

<style lang="pcss">
.c-billing-plan-comparison-modal {
  .o-bitts-modal__header {
    @apply border-none;
  }
}
</style>

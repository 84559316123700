<template>
  <div class="c-header">
    <template v-for="status in statuses" :key="status.type + status.text">
      <div class="c-row">
        <span class="c-header-title">{{ status.name || 'Status' }}</span>
        <BittsBadge
          :status="status.type"
          class="c-header-badge"
          :text="status.text"
          data-testid="status-badge"
        />
      </div>
      <div
        v-if="status.lastPushed"
        class="c-row"
        data-testid="last-pushed-date"
      >
        <span class="c-header-title">Last pushed</span>
        <span class="text-neutral-text-strong">
          {{ formatDateTime(status.lastPushed) }}
        </span>
      </div>
    </template>
    <div v-if="version" class="c-row">
      <span class="c-header-title">Version</span>
      <BittsBadge class="c-header-badge no-icon" :text="`v${version}`" />
    </div>
    <div class="c-row" v-if="createdAt">
      <span class="c-header-title">Last authorized</span>
      <span class="text-neutral-text-strong">{{ createdAt }}</span>
    </div>
    <div v-if="authBy" class="c-row">
      <span class="c-header-title">Authorized by</span>
      <span class="text-neutral-text-strong">{{ authBy }}</span>
    </div>
    <BittsButton
      v-if="!hideReauth"
      v-bind="buttonOptions"
      data-testid="integration-reauth-button"
      text="Re-authorize"
      :left-icon="['fas', 'sync']"
      @click="() => emits('reauthorize')"
    />
    <BittsButton
      v-if="!hideReauth && !hideAdvancedSettings"
      v-bind="buttonOptions"
      data-testid="integration-reauth-button"
      text="Advanced settings"
      :right-icon="['fas', 'arrow-up-right-from-square']"
      @click="() => emits('reauthorize')"
      variant="ghost"
    />
  </div>
  <BittsAlert
    v-if="calloutText"
    :description="calloutText"
    :color="statusType"
    class="mt-16"
  />
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsBadge,
  BittsButton,
  BittsButtonProps,
} from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed } from 'vue';

import { IntegrationProfileStatus } from '@/types/integration_profiles';
import { TrayIntegrationHeaderStatus } from '@/types/integrations';

const props = defineProps<{
  statusType?: IntegrationProfileStatus;
  statuses: TrayIntegrationHeaderStatus[] | undefined;
  version?: string | undefined;
  created?: string | undefined;
  authBy?: string | undefined;
  loading?: boolean;
  disabled?: boolean;
  calloutText?: string;
  hideReauth?: boolean;
  hideAdvancedSettings?: boolean;
}>();

const emits = defineEmits(['reauthorize']);

const formatDateTime = (isoString?: string) =>
  isoString
    ? DateTime.fromISO(isoString).toFormat("LLL dd, yyyy 'at' h:mm a")
    : undefined;
const createdAt = computed(() => formatDateTime(props.created));
const buttonOptions = computed<BittsButtonProps>(() => ({
  loading: props.loading,
  disabled: props.disabled,
  variant: 'outline',
  type: 'neutral',
  size: 'small',
}));
</script>

<style scoped lang="pcss">
.c-header {
  @apply flex items-center justify-between flex-wrap gap-y-12;
  & .c-row {
    @apply flex items-center justify-between w-full;
    & .c-header-title {
      @apply text-base leading-6 text-neutral-text-weak;
    }
    & .c-header-badge {
      @apply text-brand-navy inline-block;

      &.no-icon {
        & :deep(.ant-badge-status-dot) {
          @apply hidden;
        }
      }
    }
  }
}
</style>

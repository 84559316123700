<template>
  <div class="c-upsell-tooltip">
    <BittsTooltip
      :cta-icon="['fad', 'magic-wand-sparkles']"
      :button-text="buttonText || 'Upgrade'"
      overlay-class="c-upsell-tooltip__overlay"
      :mount-to-body="true"
      :placement="placement"
      :include-cta="!hideUpsell"
      @cta-clicked="onCtaClicked"
    >
      <slot />
      <template #title>
        <slot name="title" />
      </template>
    </BittsTooltip>
    <BillingPlanComparisonModal
      v-if="comparisonModalVisible"
      :billing-interaction
      @close-modal="comparisonModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsTooltip, PlacementType } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import BillingPlanComparisonModal from '@/components/billing/BillingPlanComparisonModal.vue';

import useBilling from '@/composables/useBilling';
import { TEMP_SELF_SERVE_IMPROVEMENTS } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';
import { BillingInteraction, BillingQuery } from '@/types/billing';

const props = withDefaults(
  defineProps<{
    buttonText?: string | null;
    placement?: PlacementType;
    billingInteraction: BillingInteraction;
    billingQuery?: BillingQuery | null;
    hideUpsell?: boolean;
    shouldEmit?: boolean;
  }>(),
  {
    buttonText: null,
    placement: 'bottomLeft',
    billingQuery: null,
    hideUpsell: false,
    shouldEmit: false,
  },
);

const emit = defineEmits<(e: 'cta-clicked') => void>();

const ffStore = useFeatureFlagStore();
const { handleBillingInteraction } = useBilling();
const { isFreeTier } = storeToRefs(useBillingStore());

const comparisonModalVisible = ref(false);
const hasSelfServeImprovements = computed(() =>
  ffStore.hasFeatureFlag(TEMP_SELF_SERVE_IMPROVEMENTS),
);

function onCtaClicked() {
  if (hasSelfServeImprovements.value && isFreeTier.value) {
    if (props.shouldEmit) {
      emit('cta-clicked');
    } else {
      comparisonModalVisible.value = true;
    }
  } else {
    handleBillingInteraction(
      props.billingInteraction,
      props.billingQuery ?? null,
    );
  }
}
</script>

<style scoped lang="pcss">
.c-upsell-tooltip__overlay {
  .ant-tooltip-inner {
    @apply rounded-bts-md text-white p-8 flex flex-col items-stretch gap-8 bg-neutral-background-tooltip;
  }
  span {
    @apply gap-4 flex items-center;
  }
  .c-bitts-tooltip__button {
    @apply w-full;
  }
}
</style>

<template>
  <Notification v-if="feed?.integration" :item="item">
    <template #logo>
      <BittsSvg :svg="feed.integration.type + 'Icon'" />
    </template>
    <template #message> Data Ready </template>
    <template #description>
      Your {{ feed?.integration.friendly_name }} data has finished processing
    </template>
    <template #action>
      <BittsButton
        text="Create Population"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="createPopulation"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'building']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import useNotifications from '@/composables/useNotifications';
import { useFeedsStore, useNotificationsStore } from '@/stores';

export default {
  name: 'FeedProcessedNotification',
  components: {
    BittsButton,
    Notification,
    BittsSvg,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  setup() {
    const { parseFeedIdFromFeedProcessedEvent } = useNotifications();
    return {
      parseFeedIdFromFeedProcessedEvent,
    };
  },
  computed: {
    feed() {
      return this.getFeedById(
        this.parseFeedIdFromFeedProcessedEvent(this.item),
      );
    },
  },
  methods: {
    ...mapActions(useNotificationsStore, ['markRead']),
    ...mapActions(useFeedsStore, ['getFeedById']),
    async createPopulation() {
      this.$emit('action-clicked', 'Create Population');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'create_population',
      });
    },
  },
};
</script>

<template>
  <BittsLoading :is-loading="!ready">
    <div class="c-seat-summary-card">
      <h3>Seat Summary</h3>
      <EnterpriseSeatCallout v-if="isEnterpriseTier" />
      <DowngradeCallout
        v-if="isSubscriptionCancelled"
        :title="`These seats are changing on ${subscriptionRenewalDate}`"
        subtitle="You've downgraded your plan and some users are losing access, you can see the full details on the Teams page"
        button-text="View team"
        class="mb-24"
      />

      <BittsCallout
        v-else-if="hasRemovedSeats"
        data-testid="removed-seats-callout"
        size="small"
        :icon="['fad', 'arrow-down-from-arc']"
        title="You removed seats from your plan"
        :subtitle="`You can reclaim these seats if you need them and won't be charged again until ${subscriptionRenewalDate}`"
        class="mb-24"
      />
      <BittsCard>
        <div class="content">
          <div
            class="seat-totals all-seat-totals"
            :class="{ 'border-b border-neutral-border': !isFreeTier }"
          >
            <SeatSummaryCardCounter
              :occupied="totalSeatCount"
              :total="totalSeatLimit"
              label="Total Seats"
              data-testid="total-seat-counter"
            />
            <div class="flex items-center gap-8">
              <BittsUserStack v-if="isFreeTier" :users="allUsers" />
              <BittsButton
                v-if="action"
                data-testid="action-button"
                :text="action.text"
                :left-icon="action.icon"
                :variant="action.variant"
                :type="action.type"
                @click="action.onClick"
              />
            </div>
          </div>
          <div
            v-if="coreSeatLimit && !isFreeTier"
            class="flex flex-col lg:flex-row"
          >
            <div class="seat-totals core-seat-totals">
              <SeatSummaryCardCounter
                :occupied="coreSeatCount"
                :total="coreSeatLimit"
                label="Full Access"
                data-testid="core-seat-counter"
              />
              <BittsUserStack
                v-if="coreSeatCount"
                size="small"
                :users="coreUsers"
              />
            </div>
            <div class="seat-totals sales-seat-totals">
              <SeatSummaryCardCounter
                :occupied="salesSeatCount"
                :total="salesSeatLimit"
                label="Sales"
                data-testid="sales-seat-counter"
              />
              <BittsUserStack
                v-if="salesSeatCount"
                size="small"
                :users="salesUsers"
              />
            </div>
          </div>
        </div>
      </BittsCard>
      <BillingPlanComparisonModal
        v-if="comparisonModalVisible"
        :billing-interaction="{
          cta: BILLING_CTAS.AT_SEAT_LIMIT,
          event_site: EVENT_SITES.SEAT_SUMMARY_CARD_UPGRADE_BUTTON,
        }"
        @close-modal="comparisonModalVisible = false"
      />
    </div>
  </BittsLoading>
</template>

<script setup>
import {
  BittsButton,
  BittsCallout,
  BittsCard,
  BittsLoading,
  BittsUserStack,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import EnterpriseSeatCallout from '@/components/billing/EnterpriseSeatCallout.vue';
import SeatSummaryCardCounter from '@/components/billing/SeatSummaryCardCounter.vue';

import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { TEMP_SELF_SERVE_IMPROVEMENTS } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore, useTeamStore } from '@/stores';

import BillingPlanComparisonModal from './BillingPlanComparisonModal.vue';
import DowngradeCallout from './DowngradeCallout.vue';

const router = useRouter();
const teamStore = useTeamStore();
const billingStore = useBillingStore();
const ffStore = useFeatureFlagStore();

const { allSeats, coreSeats, ready } = storeToRefs(teamStore);

const {
  isFreeTier,
  isConnectorTier,
  hasSubscription,
  isEnterpriseTier,
  isSubscriptionCancelled,
  hasRemovedSeats,
  subscriptionRenewalDate,
} = storeToRefs(billingStore);

const { handleBillingInteraction } = useBilling();
const hasSelfServeImprovements = computed(() =>
  ffStore.hasFeatureFlag(TEMP_SELF_SERVE_IMPROVEMENTS),
);
const comparisonModalVisible = ref(false);

const {
  coreSeatCount,
  coreSeatLimit,
  salesSeatCount,
  salesSeatLimit,
  atSeatLimit,
  totalSeatLimit,
  totalSeatCount,
} = useSeats();

const ACTIONS = {
  fill: {
    text: 'Fill seats',
    icon: [],
    variant: 'outline',
    type: 'neutral',
    onClick: handleGoToTeamsPage,
  },
  upgrade: {
    text: 'Upgrade',
    icon: ['fad', 'wand-magic-sparkles'],
    variant: 'fill',
    type: 'primary',
    onClick: () => {
      if (hasSelfServeImprovements.value) {
        comparisonModalVisible.value = true;
        return;
      }
      handleBillingInteraction({
        cta: BILLING_CTAS.AT_SEAT_LIMIT,
        event_site: EVENT_SITES.SEAT_SUMMARY_CARD_UPGRADE_BUTTON,
      });
    },
  },
  add: {
    text: 'Add seats',
    icon: ['fad', 'wand-magic-sparkles'],
    variant: 'fill',
    type: 'primary',
    onClick: () =>
      handleBillingInteraction({
        cta: BILLING_CTAS.AT_SEAT_LIMIT,
        event_site: EVENT_SITES.SEAT_SUMMARY_CARD_ADD_SEATS_BUTTON,
      }),
  },
  sales: {
    text: 'Talk to Sales',
    icon: ['fad', 'wand-magic-sparkles'],
    variant: 'outline',
    type: 'neutral',
    onClick: () =>
      handleBillingInteraction({
        cta: BILLING_CTAS.AT_SEAT_LIMIT,
        event_site: EVENT_SITES.SEAT_SUMMARY_CARD_TALK_TO_SALES_BUTTON,
        talkToSalesReason: 'Handle Seat Change',
      }),
  },
};

const allUsers = computed(() => allSeats.value.map((v) => v.user || null));
const coreUsers = computed(() => coreSeats.value.map((v) => v.user || null));
const salesUsers = computed(() => {
  return allSeats.value.filter((v) => !v.role_id).map((v) => v.user);
});

async function handleGoToTeamsPage() {
  await router.push({ name: 'team' });
}

const action = computed(() => {
  if (isSubscriptionCancelled.value) return null;
  if (!atSeatLimit.value) return ACTIONS.fill;

  // Free Tier
  if (isFreeTier.value && atSeatLimit.value) return ACTIONS.upgrade;
  // Connector Tier
  if (isConnectorTier.value && hasSubscription.value && atSeatLimit.value)
    return ACTIONS.add;
  // Old Billing Connector Tiers
  if (isConnectorTier.value && !hasSubscription.value && atSeatLimit.value)
    return ACTIONS.sales;
  return null;
});
</script>

<style lang="pcss" scoped>
.c-seat-summary-card {
  @apply mb-40;

  h3 {
    @apply text-neutral-text-strong text-lg font-bold mb-16;
  }

  .content {
    .seat-totals {
      @apply flex justify-between items-center flex-1 flex-wrap gap-4 p-20;
    }
  }
}
</style>

<style lang="pcss">
.c-seat-summary-card {
  .bitts-layout__slot {
    @apply p-24;
  }
}

.c-seat-summary-card {
  .core-seat-totals {
    @apply border-neutral-border border-b lg:border-b-0 lg:border-r;
    .seat-count-info {
      @apply text-m;
    }
  }

  .sales-seat-totals .seat-count-info {
    @apply text-m;
  }
}
</style>

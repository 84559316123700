<template>
  <div
    v-if="notSyncingRequiredOppData && columnType === OPPORTUNITY_NAME"
    class="flex w-full justify-between"
  >
    <p class="text-neutral-text-placeholder" data-testid="no-opportunity-data">
      No data
    </p>
    <BittsButton
      text="Sync Data"
      size="x-small"
      type="neutral"
      @click="onSyncDataButtonClicked"
    />
  </div>
  <OpportunityTooltip
    v-else-if="
      (valueHasBeenSet || dealChosenButHasNoName) && !notSyncingRequiredOppData
    "
    :opt="props.params.data?.opportunity"
    :mount-to-body="true"
    overlay-class="c-collaborate-opportunities-renderer__tooltip"
    @tooltip-cta-clicked="onCtaClicked()"
  >
    <BittsChip
      :text="formattedText"
      :removable="false"
      :table-cell-style="true"
      class="c-collaborate-opportunities-cell__chip"
    >
      <template #icon>
        <FontAwesomeIcon
          :icon="iconByColumnType"
          :style="{ height: '12px', width: '12px', color: '393BAE' }"
          class="mr-4"
        />
      </template>
    </BittsChip>
  </OpportunityTooltip>
  <div v-if="noOpportunitiesAvailable" class="text-neutral-text-placeholder">
    {{ emptyStateMessage }}
  </div>
  <label
    v-else-if="opportunityNotChosen"
    class="c-collaborate-opportunities-cell__select"
  >
    <span class="text-neutral-text-placeholder">Select Opportunity</span>
    <FontAwesomeIcon
      :icon="['fas', 'angle-down']"
      :style="{ height: '12px', width: '12px' }"
      class="text-neutral-text-placeholder"
    />
  </label>
</template>
<script setup>
import { BittsButton, BittsChip } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import OpportunityTooltip from '@/components/shared-list/OpportunityTooltip.vue';

import useIteratively from '@/composables/useIteratively';
import useOpportunityData from '@/composables/useOpportunityData';
import {
  FILE_UPLOAD,
  GOOGLE_SHEETS,
  OPPORTUNITY_AMOUNT,
  OPPORTUNITY_NAME,
  OPPORTUNITY_STAGE,
} from '@/constants/shared_list';
import { formatNumberWithCommas } from '@/utils';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const { iteratively } = useIteratively();

const { missingFields, dealFeedId, isSnowflake } = useOpportunityData();
const router = useRouter();

const notSyncingRequiredOppData = computed(() => missingFields.value.length);

const columnType = computed(() => props.params.colDef.field);
const iconByColumnType = computed(() => {
  switch (columnType.value) {
    case OPPORTUNITY_STAGE:
      return ['fas', 'list-timeline'];
    case OPPORTUNITY_AMOUNT:
      return ['fas', 'dollar-sign'];
    default:
      return ['fas', 'briefcase'];
  }
});
const formattedText = computed(() => {
  if (columnType.value === OPPORTUNITY_NAME && dealChosenButHasNoName.value)
    return '--';
  if (columnType.value === OPPORTUNITY_AMOUNT) {
    return `${formatNumberWithCommas(props.params.value)}`;
  }
  return props.params.value;
});
function onCtaClicked() {
  window.open(props.params.data.opportunity.crm_record_link, '_blank');
}

async function onSyncDataButtonClicked() {
  let routeName = 'data-templates';
  if (isSnowflake.value) routeName = 'snowflake-settings';
  submitIterativelyEvent();
  await router.push({
    name: routeName,
    params: { id: dealFeedId.value },
    query: { customizing: true },
  });
}

function submitIterativelyEvent() {
  iteratively.userClickedSyncData({
    cta_location: 'Collaborate List',
    data_type: props.params.data?.integration_type,
  });
}

const unavailableTypes = [GOOGLE_SHEETS, FILE_UPLOAD];
const isOverlapsRecord = computed(() => !props.params.data?.is_greenfield);
const isOwnGreenfieldRecord = computed(
  () => !isOverlapsRecord.value && props.params.data.record_id,
);
const isEditable = computed(
  () =>
    !!props.params.data?.integration_type &&
    !unavailableTypes.includes(props.params.data?.integration_type) &&
    (isOwnGreenfieldRecord.value || isOverlapsRecord.value),
);
const noOpportunitiesAvailable = computed(
  () =>
    (!props.params.value &&
      columnType.value === OPPORTUNITY_NAME &&
      !isEditable.value) ||
    props.params?.value === 'No opportunity available',
);
const opportunityNotChosen = computed(
  () => !props.params.value && columnType.value === OPPORTUNITY_NAME,
);
const dealChosenButHasNoName = computed(
  () =>
    columnType.value === OPPORTUNITY_NAME &&
    !props.params.value &&
    props.params.data?.opportunity?.id &&
    !!props.params.data.opportunity,
);
const valueHasBeenSet = computed(
  () => props.params?.value && props.params?.value !== emptyStateMessage.value,
);
const emptyStateMessage = computed(() => 'No opportunity available');
</script>
<style lang="pcss">
.c-collaborate-opportunities-cell__chip {
  @apply bg-upsell-background-weak rounded-2xl truncate max-w-[180px];
  span {
    @apply truncate;
  }
}
.c-collaborate-opportunities-cell__select {
  @apply cursor-pointer w-full flex items-center justify-between p-2;
}
.c-collaborate-opportunities-renderer__tooltip {
  @apply max-w-[310px];
  .ant-tooltip-content {
    margin-bottom: 64px;
    @apply w-[240px];
  }
}
</style>

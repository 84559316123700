<template>
  <BittsFormLabel label="Upload CSV" />
  <div class="c-file-input">
    <div class="c-file-input__file">
      <FontAwesomeIcon
        v-if="fileName"
        :icon="['far', 'file-csv']"
        class="w-16 h-16 text-info-accent"
      />
      <span v-if="fileName" class="c-file-input__file-name">
        <span>{{ fileName }}</span>
      </span>
      <span v-else class="c-file-input__file-not-selected">
        Select a file from your computer
      </span>
    </div>
    <div class="c-file-input__control">
      Browse
      <input
        ref="file"
        data-testid="file-input"
        :accept="accept"
        :name="name"
        type="file"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import { BittsFormLabel } from '@crossbeam/bitts';

import { FILE_UPLOAD_DATA_SOURCE_TYPE } from '@/constants/data_sources';

export default {
  name: 'FileInput',
  components: {
    BittsFormLabel,
  },
  props: {
    name: {
      type: String,
      default: 'file_name',
    },
    accept: {
      type: String,
      default: '.csv',
    },
  },
  emits: ['file-chosen'],
  data() {
    return {
      fileName: null,
      dataSourceType: FILE_UPLOAD_DATA_SOURCE_TYPE,
    };
  },
  methods: {
    reset() {
      this.fileName = null;
      this.$refs.file.value = null;
    },
    onChange(event) {
      if (event.target.files.length > 0) {
        this.fileName = event.target.files[0].name;
        this.$emit('file-chosen', event);
      }
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-file-input {
  @apply flex flex-wrap items-center w-full rounded-6 border border-dashed border-neutral-border h-40;
}

.c-file-input__control {
  @apply text-neutral-text-strong font-bold text-sm px-8 mx-8 py-4 my-4 cursor-pointer overflow-hidden relative rounded-4;
  &:hover {
    @apply bg-neutral-background-weak;
  }
}

.c-file-input__control [type='file'] {
  cursor: pointer;
  display: block;
  font-size: 0px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.c-file-input__file {
  @apply flex items-center text-neutral-800;
  font-size: 14px;
  flex: 1;
  width: 1%;
  min-width: 0;
  padding-left: 12px;
}

.c-file-input__file-name,
.c-file-input__file-not-selected {
  @apply text-base;
}

.c-file-input__file-name {
  margin-left: 8px;
  display: inline-flex;
  max-width: 250px;
  @apply text-neutral-800;
}

.c-file-input__file-name > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-file-input__file-not-selected {
  @apply text-neutral-400;
}
</style>

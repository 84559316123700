import { LIMIT_REACHED, OVER_90 } from '@/constants/billing';

export const RECORD_EXPORT_ALERTS = {
  [OVER_90]: {
    type: OVER_90,
    buttonIcon: ['fad', 'wand-magic-sparkles'],
    buttonText: 'Talk to Sales',
    message:
      'Your organization has exceeded 90% of its record export limit. Contact us to increase the number of record exports',
    dismissable: true,
  },
  [LIMIT_REACHED]: {
    type: LIMIT_REACHED,
    buttonIcon: ['fad', 'wand-magic-sparkles'],
    buttonText: 'Talk to Sales',
    message:
      'Your organization has exceeded 100% of its record export limit. Contact us to increase the number of record exports',
    dismissable: true,
  },
};
export const RECORD_EXPORT_ALERTS_DEUX = {
  [OVER_90]: {
    type: OVER_90,
    buttonText: 'Contact us',
    message:
      'You’ve used over 90% of your record export limit, contact our team to discuss options',
    dismissable: true,
  },
  [LIMIT_REACHED]: {
    type: LIMIT_REACHED,
    buttonText: 'Contact us',
    message:
      'You’ve used 100% of your export limit, contact our team to discuss options',
    dismissable: true,
  },
};

const buildTitle = (percent: string) =>
  `You've reached ${percent} of your record export limit`;

export const NEW_RECORD_EXPORT_CALLOUTS = {
  buildTitle,
  'limit-reached': {
    message:
      'Integrations using record exports have been paused and you can no longer export reports',
  },
  'over-90': {
    message:
      'Once the limit is hit integrations using record exports will be paused and you will not be able to export reports',
  },
};

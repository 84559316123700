import axios from 'axios';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  ACTIVE,
  BILLING_PLAN_MONTHLY,
  BILLING_TIERS,
  CANCELLED,
  PAST_DUE,
  SEAT_REMOVAL,
  UNPAID,
} from '@/constants/billing';
import { ordinal } from '@/date_time_utils';
import { captureException } from '@/errors';
import { useRootStore } from '@/stores/RootStore';
import { initStore } from '@/stores/store-utils';
import { BillingInfo, PaymentInfo, PortalInfo } from '@/types/billing';
import { EmptyObject } from '@/types/common';
import urls from '@/urls';
import { createAxiosWithRetry, extractLocalDate } from '@/utils';

export const useBillingStore = defineStore('BillingTwo', () => {
  const rootStore = useRootStore();
  const billingInfo = ref<BillingInfo | EmptyObject>({});
  const paymentInfo = ref<PaymentInfo | EmptyObject>({});
  const portalInfo = ref<PortalInfo | EmptyObject>({});

  const { error, ready, readySync, running, refresh } = initStore(
    async (opts = { syncChargify: false }) => {
      const { currentOrg } = useAuth();
      const { uuid: orgUUID } = currentOrg.value;
      const retryAxios = createAxiosWithRetry();
      if (orgUUID)
        retryAxios.defaults.headers.common['XBeam-Organization'] = orgUUID;
      let url = urls.billing.get;
      let method: 'get' | 'post' = 'get';
      if (opts.syncChargify) {
        method = 'post';
        url = urls.billing.sync;
      }

      /* Do not attempt to call billing endpoint in front of login */
      if (!rootStore.hasProfile && !(import.meta.env.MODE === 'test')) return;

      try {
        const { data } = await (retryAxios || axios)[method](url);
        billingInfo.value = data;
      } catch (err) {
        if (method === 'post') {
          const { data } = await (retryAxios || axios).get(urls.billing.get);
          billingInfo.value = data;
          throw err; /* If the 'POST' call fails then retry with the 'GET' endpoint. Then throw, will be caught + stored in error */
        }
      }
    },
  );

  refresh({ useCache: true, syncChargify: true });

  async function refreshPortalAndPaymentInfo() {
    try {
      const [portalResponse, paymentResponse] = await Promise.all([
        axios.post(urls.billing.syncBillingPortal),
        axios.get(urls.billing.paymentInfo),
      ]);
      portalInfo.value = portalResponse.data;
      paymentInfo.value = paymentResponse.data;
    } catch (err) {
      captureException(err);
    }
  }

  const billingTier = computed(() => billingInfo.value.billing_tier);
  const billingVersion = computed(
    () => billingInfo.value.price_and_packaging_version,
  );
  const isFreeTier = computed(() => billingTier.value === BILLING_TIERS.FREE);
  const isConnectorTier = computed(
    () => billingTier.value === BILLING_TIERS.CONNECTOR,
  );
  const isEnterpriseTier = computed(
    () => billingTier.value === BILLING_TIERS.ENTERPRISE,
  );
  const customPopulationLimit = computed(
    () => billingInfo.value.core?.custom_population_limit ?? Infinity,
  );
  const partnerLimit = computed(
    () => billingInfo.value.core?.partner_limit ?? Infinity,
  );
  const recordExportLimit = computed(
    () => billingInfo.value.core?.record_export_limit,
  );
  const reportExportLimit = computed(
    () => billingInfo.value.core?.report_export_limit ?? Infinity,
  );
  const hasSubscription = computed(() => !!chargifyData.value);
  const subscriptionStatus = computed(() => {
    const status = chargifyData.value?.subscription_status;
    if (status === ACTIVE && updateSummary.value?.cancellation_initiated)
      return CANCELLED;
    return status;
  });

  const hasRemovedSeats = computed(
    () => updateSummary.value?.update_type === SEAT_REMOVAL,
  );
  const isSubscriptionCancelled = computed(
    () => subscriptionStatus.value === CANCELLED,
  );
  const isSubscriptionPastDue = computed(
    () => subscriptionStatus.value === PAST_DUE,
  );
  const isSubscriptionUnpaid = computed(
    () => subscriptionStatus.value === UNPAID,
  );
  const subscriptionCost = computed(
    () => chargifyData.value?.product_total_cents,
  );
  const componentCost = computed(
    () => chargifyData.value?.components_total_cents,
  );
  const grandTotal = computed(
    () => chargifyData.value?.subscription_total_cents,
  );
  const subscriptionErrorMessage = computed(
    () => chargifyData.value?.subscription_sync_error_message,
  );
  const subscriptionType = computed(() =>
    chargifyData.value?.subscription_handle?.split('-').at(1),
  );
  const billingTierCreatedDate = computed(() => {
    const date = billingInfo.value?.tier_first_created_at;
    return date ? extractLocalDate(DateTime.fromISO(date)) : null;
  });
  const subscriptionDay = computed(() => {
    const date = chargifyData.value?.next_billing_date;
    return date ? ordinal(Number(DateTime.fromISO(date).toFormat('d'))) : null;
  });
  const subscriptionNextBillingDate = computed(() => {
    const date = chargifyData.value?.next_billing_date;
    return date ? extractLocalDate(DateTime.fromISO(date)) : null;
  });
  const subscriptionRenewalDate = computed(() => {
    const date = chargifyData.value?.next_renewal_date;
    return date ? extractLocalDate(DateTime.fromISO(date)) : null;
  });
  const lastPaidDate = computed(() => {
    const date = chargifyData.value?.last_paid_date;
    return date ? extractLocalDate(DateTime.fromISO(date)) : null;
  });
  const lastPaidAmount = computed(
    () => chargifyData.value?.last_paid_amount_cents,
  );
  const updateSummary = computed(() => chargifyData.value?.update_summary);
  const isMonthly = computed(
    () => subscriptionType.value === BILLING_PLAN_MONTHLY,
  );
  const billingPeriod = computed(() =>
    subscriptionType.value === BILLING_PLAN_MONTHLY ? 'month' : 'year',
  );
  const inRenewalPeriod = computed(() => chargifyData.value?.in_renewal_period);
  const purchaseSeatsEnabled = computed(
    () => isConnectorTier.value && hasSubscription.value,
  );
  const billingPortalURL = computed(() => portalInfo.value?.url);
  const creditCardType = computed(() => paymentInfo.value?.card_type);
  const creditCardLastFourDigits = computed(() =>
    paymentInfo.value?.masked_card_number?.split('-').at(-1),
  );
  const chargifyData = computed(() => billingInfo.value.chargify_subscription);
  const subtotal = computed(
    () => chargifyData.value?.subscription_subtotal_cents,
  );
  const discount = computed(
    () => chargifyData.value?.subscription_discount_cents,
  );
  const offlinePartnersQuota = computed(
    () => billingInfo.value.core.offline_partners_quota,
  );

  return {
    /* Common State */
    ready,
    readySync,
    running,
    error,
    refreshBillingStore: refresh,

    /* Computeds and Functions */
    refreshPortalAndPaymentInfo,
    billingInfo,
    paymentInfo,
    portalInfo,
    billingTier,
    billingVersion,
    isFreeTier,
    isConnectorTier,
    isEnterpriseTier,
    customPopulationLimit,
    partnerLimit,
    recordExportLimit,
    reportExportLimit,
    hasSubscription,
    subscriptionStatus,
    isSubscriptionCancelled,
    isSubscriptionPastDue,
    isSubscriptionUnpaid,
    hasRemovedSeats,
    subscriptionCost,
    componentCost,
    grandTotal,
    subscriptionErrorMessage,
    subscriptionType,
    billingPeriod,
    billingTierCreatedDate,
    subscriptionDay,
    subscriptionNextBillingDate,
    subscriptionRenewalDate,
    lastPaidDate,
    lastPaidAmount,
    updateSummary,
    isMonthly,
    inRenewalPeriod,
    purchaseSeatsEnabled,
    billingPortalURL,
    creditCardType,
    creditCardLastFourDigits,
    chargifyData,
    subtotal,
    discount,
    offlinePartnersQuota,
  };
});

<template>
  <BittsModal
    :visible="true"
    name="frigg-modal"
    class="c-frigg-modal"
    :loading="modalLoading"
    :saving="saving"
    :show-cancel-button="false"
    save-text="Add Integration"
    :disabled="saving"
    @closed="modalCloseHandler"
    @saved="saving = true"
  >
    <template #content>
      <section class="c-frigg-modal__header-area">
        <div>
          <BittsAvatar
            :org="avatarOrg"
            size="medium"
            shape="circle"
            class="text-center border-none mb-16 mt-12"
          />
          <h2 class="c-frigg-modal__title">
            {{ headerText }}
          </h2>
          <div class="c-frigg-modal__description" v-html="headerDescription" />
        </div>
      </section>
      <!-- integration form area -->
      <FriggFormBuilder
        v-if="!modalLoading"
        :frigg-details="integrationDetails"
        :config-response="friggConfig"
        :saving="saving"
        @saved="onAdd"
      />
    </template>
  </BittsModal>
</template>

<script>
import { BittsAvatar, BittsModal } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import FriggFormBuilder from '@/components/integrations/FriggFormBuilder.vue';

import { FRIGG_THRESHOLD } from '@/constants/frigg';
import {
  useFlashesStore,
  usePartnerCloudStore,
  usePartnersStore,
  useReportsStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'FriggAuthorizationSettingsModal',
  components: {
    BittsAvatar,
    FriggFormBuilder,
    BittsModal,
  },
  data() {
    return {
      integrationDetails: {
        display: {},
      },
      friggType: this.$route.params.integration_type,
      modalLoading: false,
      saving: false,
      friggConfig: {},
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['overlapCounts']),
    ...mapState(usePartnerCloudStore, ['getAvailableIntegrationByType']),
    tooPopular() {
      return this.overlapCounts.overlap_usage?.total > FRIGG_THRESHOLD;
    },
    headerText() {
      return `Set Up ${this.integrationDetails.name} Integration`;
    },
    headerDescription() {
      return `We’ll need some information to connect to your ${this.integrationDetails.name} account.
                If you need help doing this, check out this
                <a href="${this.integrationDetails.learnMore}">help article</a>.`;
    },
    avatarOrg() {
      return this.integrationDetails.avatar
        ? {
            logo_url: new URL(
              `../../assets/pngs/partner-cloud/${this.integrationDetails.avatar}`,
              import.meta.url,
            ).href,
          }
        : { clearbit_domain: this.integrationDetails.display.icon };
    },
  },
  async created() {
    if (this.tooPopular) {
      this.redirectToIntegrations();
    }

    try {
      this.modalLoading = true;
      const url = urls.frigg.authByType(this.friggType);
      const { data: authDetailsResponse } = await axios.get(url);
      this.integrationDetails = this.getAvailableIntegrationByType(
        this.friggType,
      );

      // simulates frigg sending back no config so we can use the form builder as is
      this.integrationDetails.config = [];
      this.friggConfig = authDetailsResponse;
    } catch (_err) {
      this.addErrorFlash({ message: 'Integration not found' });
      this.redirectToIntegrations();
    } finally {
      this.modalLoading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    ...mapActions(usePartnerCloudStore, ['refreshPartnerCloudStore']),
    ...mapActions(useReportsStore, ['refreshReportsStore']),
    async refresh() {
      await this.refreshPartnerCloudStore();
      await this.refreshReportsStore();
    },
    async onAdd({ payload }) {
      try {
        const { config: authorizationDetails } = payload;
        const { data: friggInfo } = await axios.post(urls.frigg.all, {
          integration_type: this.friggType,
          authorization_details: authorizationDetails,
        });
        if (this.integrationDetails.hasUserConfig || friggInfo.needs_config) {
          this.$router.push({
            name: 'frigg-integration',
            params: {
              integration_type: this.friggType,
            },
          });
        } else {
          this.redirectToIntegrations();
        }
      } catch (_err) {
        this.addErrorFlash({
          message: 'Integration creation error',
          description: `If this error persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
        });
        this.redirectToIntegrations();
      } finally {
        this.pageLoading = false;
        this.saving = false;
      }
    },
    async redirectToIntegrations() {
      await this.$router.push({ name: 'integrations' });
    },
    modalCloseHandler() {
      this.redirectToIntegrations();
    },
  },
};
</script>

<style lang="pcss">
.c-frigg-modal {
  .c-frigg-modal__header-area {
    @apply px-40 pb-24;
  }

  .c-frigg-modal__input-description {
    @apply text-sm text-neutral-600 mt-8;

    a {
      @apply text-brand-blue underline;
    }
  }

  .c-frigg-modal__title {
    @apply font-bold text-center text-lg mb-8 text-neutral-900;
  }

  .c-frigg-modal__description {
    @apply text-center text-base text-neutral-800;
    a {
      @apply text-brand-blue;
    }
  }

  .c-bitts-modal__content {
    @apply py-0;
  }

  .c-bitts-btn-medium {
    @apply flex-1;
  }
}
</style>

<template>
  <BittsLoading :is-loading="!ready">
    <BittsLayout :is-page="true">
      <template #content>
        <PageTitle
          title="Plan & Billing"
          :class="{ 'mb-16': hasRecordExportGlowup && showRecordExportCallout }"
        />
        <ExportLimitCallout v-if="!hasRecordExportGlowup" />
        <ExportLimitCalloutDeux
          v-if="hasRecordExportGlowup && showRecordExportCallout"
          class="mb-40"
          v-model="isRecordExportModalVisible"
        />
        <SeatSummaryCard />
        <RecordExportSummaryCard v-if="!hasRecordExportGlowup" />
        <RecordExportSummaryCardDeux
          v-model="isRecordExportModalVisible"
          v-if="hasRecordExportGlowup && !!exportLimit"
        />
        <ExplorerCard v-if="isFreeTier" />
        <ConnectorCard v-else-if="isConnectorTier" />
        <EnterpriseCard v-else-if="isEnterpriseTier" />
        <ExploreBillingPlans v-if="!isEnterpriseTier" />
        <router-view-wrapper />
        <!-- The upgrade modal -->
      </template>
    </BittsLayout>
  </BittsLoading>
  <RecordExportSummaryModal
    v-if="isRecordExportModalVisible && hasRecordExportGlowup"
    @closed:record-export-modal="
      isRecordExportModalVisible = !isRecordExportModalVisible
    "
  />
</template>
<script setup>
import { BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';

import ConnectorCard from '@/components/billing/ConnectorCard.vue';
import EnterpriseCard from '@/components/billing/EnterpriseCard.vue';
import ExploreBillingPlans from '@/components/billing/ExploreBillingPlans.vue';
import ExplorerCard from '@/components/billing/ExplorerCard.vue';
import ExportLimitCallout from '@/components/billing/ExportLimitCallout.vue';
import ExportLimitCalloutDeux from '@/components/billing/ExportLimitCalloutDeux.vue';
import RecordExportSummaryCard from '@/components/billing/RecordExportSummaryCard.vue';
import RecordExportSummaryCardDeux from '@/components/billing/RecordExportSummaryCardDeux.vue';
import SeatSummaryCard from '@/components/billing/SeatSummaryCard.vue';
import RecordExportSummaryModal from '@/components/integrations/RecordExportSummaryModal.vue';
import PageTitle from '@/components/PageTitle.vue';

import useRecordExportLimits from '@/composables/useRecordExportLimits';
import { TEMP_RECORD_EXPORT_GLOWUP } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import {
  allReady,
  useBillingStore,
  useFeatureFlagStore,
  useTeamStore,
} from '@/stores';

useHead({ title: 'Billing' });

const isRecordExportModalVisible = ref(false);

const billingStore = useBillingStore();
const teamsStore = useTeamStore();
const featureFlagStore = useFeatureFlagStore();
const { exportLimitStatus, exportLimit } = useRecordExportLimits();

const { hasSubscription, isFreeTier, isEnterpriseTier, isConnectorTier } =
  storeToRefs(billingStore);

const hasRecordExportGlowup = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_RECORD_EXPORT_GLOWUP),
);
const showRecordExportCallout = computed(() =>
  ['limit-reached', 'over-90'].includes(exportLimitStatus.value),
);

const ready = allReady(teamsStore, billingStore);

onBeforeMount(async () => {
  try {
    const apis = [
      teamsStore.refreshTeamStore(),
      billingStore.refreshBillingStore({ syncChargify: true }),
    ];

    /* If a connector account with subscription, populate the card information into the store */
    if (hasSubscription.value)
      apis.push(billingStore.refreshPortalAndPaymentInfo());
    await Promise.all(apis);
  } catch (err) {
    captureException(err);
  }
});
</script>

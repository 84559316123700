<template>
  <div class="c-team-table-cell-wrapper c-team-table__login-cell">
    <BittsTag v-if="loginType" color="neutral-light">
      <div class="flex items-center leading-6">
        <BittsAvatar
          v-if="loginType === 'google_oauth'"
          size="tiny"
          :is-entity="true"
          :entity-url="imageUrl"
          class="mr-4"
        />
        <FontAwesomeIcon
          v-else
          :icon="['fas', 'lock']"
          class="text-neutral-accent mr-4 text"
          :style="{ width: '10px', color: 'currentColor' }"
        />
        <div>{{ LOGIN_TYPE_MAP[loginType] }}</div>
      </div>
    </BittsTag>
  </div>
</template>

<script setup>
import { BittsAvatar, BittsTag } from '@crossbeam/bitts';

import { computed, onBeforeMount, ref } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

const LOGIN_TYPE_MAP = {
  sso: 'SSO',
  samlp: 'SSO',
  google_oauth: 'Google',
  auth0: 'Standard',
};

const authOrInvite = ref(null);
const loginType = computed(() => {
  return authOrInvite.value?.user ? authOrInvite.value.login_method : '';
});

const imageUrl = computed(
  () => new URL('../../assets/pngs/google.png', import.meta.url).href,
);

onBeforeMount(() => {
  authOrInvite.value = props.params?.data;
});
</script>
<style lang="pcss">
.c-team-table__login-cell {
  @apply h-full flex items-center;
  .ant-tag {
    @apply rounded-full;
  }
}
</style>

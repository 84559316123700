<template>
  <div class="c-individual-list-page__top-bar">
    <div class="flex flex-col">
      <div class="flex justify-between">
        <div class="flex">
          <BittsAvatarStack
            size="medium"
            :org-list="orgsList"
            :transparent="false"
          />
          <div class="flex flex-col ml-20 self-center">
            <p
              class="font-bold text-xl"
              data-testid="c-individual-list-page__name"
            >
              {{ currentList?.name }}
            </p>
          </div>
        </div>
        <div class="flex items-start">
          <BittsUserStack
            class="mr-8"
            size="small"
            :users="allUsersInList"
            :include-hover-text="true"
          />
          <ListUserPermissionModule
            data-testid="c-individual-list-page__invite-btn"
            class="mr-8"
            :list-users="allUsersInList"
            :list-id="currentList?.id"
            :is-visible="permissionsModuleVisible"
            @invite-button-clicked="
              permissionsModuleVisible = !permissionsModuleVisible
            "
            @closed="permissionsModuleVisible = false"
          />
          <BittsButton
            :center-icon="['fas', 'pencil']"
            type="neutral"
            size="small"
            variant="outline"
            @click="onEditListBtnClicked"
          />
        </div>
      </div>
      <div class="mt-16">
        <BittsCallout
          v-if="calloutState"
          :title="calloutState.title"
          :action="!!calloutState.ctaAction"
          :action-text="calloutState.cta || ''"
          :subtitle="calloutState.subtitle"
          size="small"
          type="warning"
          class="mb-16"
          @bitts-callout-action="calloutState.ctaAction || undefined"
        />
        <div class="flex flex-col">
          <p class="max-w-[800px]" data-testid="c-individual-list-page__desc">
            {{ currentList?.description }}
          </p>
          <div class="flex items-center mt-16">
            <p
              class="flex items-center text-neutral-text-weak text-sm mr-8"
              data-testid="c-individual-list-page__create-date"
            >
              <BittsAvatarPip
                size="small"
                class="mr-8"
                :is-user-main-avatar="true"
                :org="ownerOrg"
                :show-initials="true"
                :user="listOwner"
                :include-hover-text="true"
                :show-tooltip-org-info="false"
                hover-text-placement="bottomLeft"
              >
                <template #additionalInfo>
                  <p>{{ listOwner?.email }}</p>
                </template>
              </BittsAvatarPip>
              {{ formattedListCreatedInfo }}
            </p>
            <p
              class="flex items-center text-neutral-text-weak text-sm mr-8"
              data-testid="c-individual-list-page__last-updated"
            >
              <BittsAvatarPip
                size="small"
                class="mr-8"
                :is-user-main-avatar="true"
                :org="
                  userLastUpdatedList
                    ? userLastUpdatedList?.organization_id === currentOrg.id
                      ? currentOrg
                      : partnerOrg
                    : secondaryOrg
                "
                :show-initials="true"
                :user="userLastUpdatedList ? userLastUpdatedList : partnerOwner"
                :include-hover-text="true"
                :show-tooltip-org-info="false"
                :hover-text-placement="
                  userLastUpdatedList ? 'bottomLeft' : 'bottom'
                "
              >
                <template #additionalInfo>
                  <p>{{
                    userLastUpdatedList
                      ? userLastUpdatedList?.email
                      : partnerOwner?.email
                  }}</p>
                </template>
              </BittsAvatarPip>
              {{ formattedListUpdatedInfo }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  BittsAvatarPip,
  BittsAvatarStack,
  BittsButton,
  BittsCallout,
  BittsUserStack,
} from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import ListUserPermissionModule from '@/components/shared-list/ListUserPermissionModule.vue';

import useAuth from '@/composables/useAuth';
import useSharedList from '@/composables/useSharedList';
import {
  CONTACT_ADMIN_ENABLE_PARTNER_CB4S,
  CONTACT_ADMIN_UPGRADE_CB4S_ROLE,
  ENABLE_PARTNER_CB4S,
  UPGRADE_OWN_CB4S_ROLE,
  UPGRADE_OWN_CB4S_ROLE_ENABLE_PARTNER,
} from '@/constants/shared_list';
import { useCollaborateStore } from '@/stores';

const emit = defineEmits<(e: 'open-modal', value: typeof EDIT_LIST) => void>();

const EDIT_LIST = 'edit_list';

const collaborateStore = useCollaborateStore();
const { currentOrg, currentUser } = useAuth();

const { currentList, partnerOrg, allUsersInList, orgsList, sendToSalesError } =
  useSharedList();

const router = useRouter();

const routeToCB4SPartner = async () => {
  await router.push({ path: '/co-sell/crossbeam' });
};

const routeToTeamModal = async () => {
  await router.push({
    name: 'edit_user',
    params: { user_id: currentUser.value.id },
  });
};

const calloutState = computed(() => {
  const partnerOrgName = partnerOrg.value?.name;
  const sendToSalesErrorType = sendToSalesError.value?.type;
  if (sendToSalesErrorType === UPGRADE_OWN_CB4S_ROLE) {
    return {
      title: "You can't send records to Crossbeam for Sales",
      subtitle:
        'Update your sales role to Manager or Standard to send records to Crossbeam for Sales',
      cta: 'Update Sales Role',
      ctaAction: routeToTeamModal,
    };
  }

  if (sendToSalesErrorType === CONTACT_ADMIN_UPGRADE_CB4S_ROLE) {
    return {
      title: "You can't send records to Crossbeam for Sales",
      subtitle:
        'Contact your admin to change your sales role to use Crossbeam for Sales',
      cta: null,
      ctaAction: null,
    };
  }

  if (sendToSalesErrorType === UPGRADE_OWN_CB4S_ROLE_ENABLE_PARTNER) {
    return {
      title: `${partnerOrgName} is not enabled in Crossbeam for Sales`,
      subtitle:
        'Update your sales role to Manager to enable partners in Crossbeam for Sales',
      cta: 'Update Sales Role',
      ctaAction: routeToTeamModal,
    };
  }

  if (sendToSalesErrorType === ENABLE_PARTNER_CB4S) {
    return {
      title: `${partnerOrgName} is not enabled in Crossbeam for Sales`,
      subtitle:
        'To send record to Account Owners, check that your partners are enabled in Crossbeam for Sales',
      cta: 'Enable partners',
      ctaAction: routeToCB4SPartner,
    };
  }

  if (sendToSalesErrorType === CONTACT_ADMIN_ENABLE_PARTNER_CB4S) {
    return {
      title: `${partnerOrgName} is not enabled in Crossbeam for Sales`,
      subtitle: `Contact your partner manager to enable ${partnerOrgName} in Crossbeam for Sales`,
      cta: null,
      ctaAction: null,
    };
  }

  return null;
});

const listOwner = computed(() =>
  collaborateStore.getOwnerByListId(currentList.value?.id),
);
const partnerOwner = computed(() =>
  collaborateStore.getPartnerOwnerByListId(currentList.value?.id),
);

const ownerOrg = computed(() =>
  collaborateStore.getOwnerOrgByListId(currentList.value?.id),
);
const secondaryOrg = computed(() =>
  collaborateStore.getPartnerOrgByListId(currentList.value?.id),
);

const formattedListCreatedInfo = computed(
  () =>
    `Created ${DateTime.fromISO(currentList.value?.created_at).toFormat('MMM d, yyyy')}`,
);
const formattedListUpdatedInfo = computed(
  () =>
    `Last updated ${DateTime.fromISO(currentList.value?.updated_at).toRelative()}`,
);

const userLastUpdatedList = computed(
  () => currentList.value?.last_updated_by_user,
);

const permissionsModuleVisible = ref(false);

function onEditListBtnClicked() {
  emit('open-modal', EDIT_LIST);
}
</script>
<style lang="pcss">
.c-individual-list-page__top-bar {
  @apply pb-24 px-24 border-b border-neutral-border;
}
</style>

<template>
  <component
    :is="divOrTooltip"
    :placement="divOrTooltip !== 'div' ? 'bottom' : null"
    class="timeline-population-content-wrapper"
    data-testid="population-content-tooltip"
    :class="{
      unavailable: !partnerPopulationExists,
      'standard-population': isStandardPopulation,
    }"
  >
    <div class="timeline-population-content" data-testid="population-content">
      <FontAwesomeIcon
        v-if="!partnerPopulationExists"
        :icon="['fas', 'eye-slash']"
        class="unavailable-icon"
      />
      {{ popName }}
    </div>
    <template #title>
      {{ tooltipContent }}
    </template>
  </component>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import { usePopulationsStore } from '@/stores';

const { partnerPopulationId = null, partnerPopulationName = null } =
  defineProps<{
    partnerPopulationId: number | null;
    partnerPopulationName: string | null;
  }>();

const populationStore = usePopulationsStore();

const partnerPopulation = computed(() =>
  partnerPopulationId
    ? populationStore.getPartnerPopulationById(partnerPopulationId)
    : null,
);
const partnerPopulationExists = computed(
  () => !!partnerPopulation.value && !!partnerPopulationName,
);
const isStandardPopulation = computed(
  () => !!partnerPopulation.value?.standard_type,
);

const popName = computed(() =>
  partnerPopulationExists.value ? partnerPopulationName : 'Unavailable',
);

const popNameHasChanged = computed(() => {
  return popName.value !== partnerPopulation.value?.name;
});

const divOrTooltip = computed(() => {
  if (!partnerPopulationExists.value || popNameHasChanged.value)
    return BittsTooltip;
  return 'div';
});

const tooltipContent = computed(() => {
  if (!partnerPopulationExists.value) return 'This population no longer exists';
  if (popNameHasChanged.value) {
    return `This population is now named "${partnerPopulation.value?.name}"`;
  }
  return null;
});
</script>

<style scoped lang="pcss">
.timeline-population-content-wrapper {
  @apply text-sm text-neutral-text-strong rounded-4 border border-neutral-border inline-flex cursor-default px-4 py-2 bg-white;

  &.unavailable {
    @apply bg-neutral-background-disabled border-neutral-background-disabled;
  }

  &.standard-population {
    @apply before:content-[''] before:w-2 before:bg-info-accent before:rounded-full before:mr-4;
  }
}

.timeline-population-content {
  @apply inline-flex items-center font-bold text-neutral-text py-2;
}

.standard-population-border {
  @apply h-full rounded-full bg-info-accent w-2 mr-4;
}

.unavailable-icon {
  @apply text-neutral-400 mr-4;
}
</style>

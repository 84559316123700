<template>
  <div>
    <component :is="feedIsSyncing ? 'BittsTooltip' : 'div'" placement="top">
      <BittsButton
        v-if="canManageDataSources"
        type="neutral"
        variant="outline"
        size="x-small"
        :disabled="feedIsSyncing"
        :left-icon="['fas', 'sync']"
        :left-icon-classes="{
          'animate-spin': feedIsSyncing && !feedIsDeleting,
          'pr-0': feedIsSyncing,
          'mr-4': feedIsSyncing,
        }"
        text="Sync Now"
        @click="showSyncModal = true"
      />
      <template #title> This feed is already syncing </template>
    </component>
    <BittsModal
      v-if="showSyncModal"
      save-text="Sync Now"
      cancel-text="Cancel"
      confirm-type="primary"
      :title="`Sync ${feed.integration.friendly_name}`"
      :visible="showSyncModal"
      :content-text="`Syncing your ${feed.integration.friendly_name} data source will update all populations and reports powered off of them. This may take some time to complete.`"
      @saved="handleSyncFeed"
      @closed="showSyncModal = false"
    />
  </div>
</template>

<script>
import { BittsButton, BittsModal, BittsTooltip } from '@crossbeam/bitts';

import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import useFeedDetails from '@/composables/useFeedDetails';
import { useFeedsStore, useFlashesStore } from '@/stores';

export default {
  name: 'Sync',
  components: {
    BittsButton,
    BittsModal,
    BittsTooltip,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const feedsStore = useFeedsStore();
    const flashesStore = useFlashesStore();

    const { hasPermission } = useAuth();

    const canManageDataSources = computed(() =>
      hasPermission('manage:data-sources'),
    );
    const { feedIsDeleting, feedIsSyncing } = useFeedDetails({
      feedId: props.feed.id,
    });

    const showSyncModal = ref(false);

    async function handleSyncFeed() {
      try {
        await feedsStore.syncFeed(props.feed?.id);
        await feedsStore.refreshFeed(props.feed?.id);
        flashesStore.addSuccessFlash({ message: 'Feed has started syncing' });
      } catch {
        flashesStore.addErrorFlash({ message: 'The feed could not be synced' });
      }
      showSyncModal.value = false;
    }

    return {
      canManageDataSources,
      feedIsSyncing,
      feedIsDeleting,
      showSyncModal,
      handleSyncFeed,
    };
  },
};
</script>

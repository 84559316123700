import { Nullable } from '@/types/common';

type PartnerUser = {
  id: number;
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  email: string;
  picture_url: Nullable<string>;
  gravatar_url: string;
};

type PartnerMetrics = {
  impact_score: string;
  total_revenue_with_partner: Nullable<number>;
  win_rate_with_partner: Nullable<number>;
  time_to_close_with_partner: Nullable<number>;
  deal_size_with_partner: Nullable<number>;
  win_rate_multiplier: Nullable<number>;
  time_to_close_multiplier: Nullable<number>;
  deal_size_multiplier: Nullable<number>;
};

type PartnerOverlapCounts = {
  partner_organization_id: number;
  updated_at: string;
  shared_overlap_count: number;
  total_overlap_count: number;
  open_status_code: Nullable<string>;
  open_deals_count: Nullable<number>;
  open_deals_total_amount: Nullable<number>;
};

type ProposalStatus = 'pending' | 'accepted' | 'declined' | 'revoked';

type SendingUser = { name: Nullable<string>; email: string };
type SendingOrg = {
  id: number;
  name: string;
  clearbit_domain: Nullable<string>;
};

export type OverlapCounts = {
  overlap_usage: {
    total: number;
    total_open_deal_amount: Nullable<number>;
    calculated_at: string;
    by_partner: PartnerOverlapCounts[];
  };
};

export type Partner = {
  tags?: PartnerTag[];
  logo_url: Nullable<string>;
  name: string;
  partnership_created_at: string;
  clearbit_domain: string;
  id: number;
  url: string;
  domain: string;
  uuid: string;
  partnership_authorizer: Nullable<string>;
  metrics: PartnerMetrics;
  offline_partner: boolean;
  users?: PartnerUser[];
  migrated_from_reveal: boolean;
};

export type ReceivedPartnershipProposal = {
  id: number;
  created_at: Date;
  sending_user: Nullable<SendingUser>;
  sending_organization: Nullable<SendingOrg>;
  invite_message: Nullable<string>;
};

export type OfflinePartner = Omit<Partner, 'offline_partner'> & {
  offline_partner: true;
};

export function isOfflinePartner(
  partner: Partner | OfflinePartner,
): partner is OfflinePartner {
  return partner.offline_partner === true;
}

export type PartnerTag = {
  id: string;
  label: string;
};

export type PartnerTagMap = Record<string, number[]>;

export type OldSentPartnershipProposal = {
  id: number;
  status: ProposalStatus;
  created_at: string;
  contact: { name: Nullable<string>; email: Nullable<string>; company: string };
  sending_user: Nullable<SendingUser>;
  migrated_from_reveal: boolean;
};

export type SentPartnershipProposal = {
  id: number;
  status: string;
  created_at: string;
  contact_name: Nullable<string>;
  contact_email: Nullable<string>;
  contact_company: string;
  sending_user: Nullable<SendingUser>;
  migrated_from_reveal: boolean;
};

export type PublicInviteOrg = Nullable<{
  name: string;
  logo_url: Nullable<string>;
  clearbit_domain: Nullable<string>;
}>;

export function isNewProposal(
  proposal: OldSentPartnershipProposal | SentPartnershipProposal,
): proposal is SentPartnershipProposal {
  return !('contact' in proposal);
}

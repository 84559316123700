<template>
  <RoleDetail :role-id="roleId" />
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';

import RoleDetail from '@/components/RoleDetail.vue';

useHead({ titleTemplate: 'View Role - %s' });

const route = useRoute();
const roleId = route.params.role_id;
</script>

<template>
  <div class="flex w-full justify-between">
    <div v-if="!isEmptyHeader" class="max-w-[95%]">
      <div class="flex items-center max-w-full">
        <BittsAvatar
          :org="isOwnColumn ? currentOrg : partnerOrg"
          class="mr-8"
          size="x-small"
          shape="square"
        />
        <p class="text-sm text-neutral-text-weak font-normal truncate">
          {{ !isOwnColumn ? partnerOrg?.name : 'You' }}
        </p>
      </div>
    </div>
    <BittsTooltip
      v-if="isPrivate"
      class="cursor-pointer"
      overlay-class="c-individual-list-page__bitts-tooltip"
      :mount-to-body="true"
      placement="topLeft"
    >
      <template #default>
        <FontAwesomeIcon
          :icon="['fas', 'eye-slash']"
          class="text-neutral-accent"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
        />
      </template>
      <template #title> These columns are only visible to you </template>
    </BittsTooltip>
    <div v-else />
    <div v-if="!isSuppressedColumn" class="flex cursor-pointer items-center">
      <FontAwesomeIcon
        :icon="['fas', 'grip-dots-vertical']"
        class="text-neutral-accent"
        :style="{ height: '16px', width: '16px', color: 'currentColor' }"
      />
    </div>
  </div>
</template>
<script setup>
import { BittsAvatar, BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const isSuppressedColumn = computed(() => props.params.isSuppressedColumn);
const isPrivate = computed(() => props.params.isPrivate);
const isOwnColumn = computed(() => props.params.isOwnColumn);
const partnerOrg = computed(() => props.params.partnerOrg);
const currentOrg = computed(() => props.params.currentOrg);
const isEmptyHeader = computed(() => props.params.isEmptyHeader);
</script>

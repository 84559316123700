<template>
  <div class="c-request-table-type-cell">
    <BittsTag
      v-if="seatRequest.type === CORE"
      class="max-w-[120px]"
      variant="rounded"
    >
      <template #pre>
        <BittsSvg class="w-12 h-12" svg="logoNoText" />
      </template>
      <span> Full Access </span>
    </BittsTag>
    <BittsTag v-else color="success" variant="rounded">
      <template #pre>
        <FontAwesomeIcon class="w-12 h-12" :icon="['fak', 'sales']" />
      </template>
      <span> Sales </span>
    </BittsTag>
  </div>
</template>

<script setup>
import { BittsSvg, BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';

import { CORE } from '@/constants/team';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const seatRequest = computed(() => props.params.data);
</script>
<style lang="pcss">
.c-request-table-type-cell {
  .bitts-tag .ant-tag {
    @apply flex items-center gap-4 h-24;
  }
  img {
    @apply mb-2;
  }
}
</style>

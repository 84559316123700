<template>
  <div class="c-confirm-map-columns">
    <p class="ae-mapping-explainer">
      {{ confirmationMessage }}
    </p>
    <div class="flex justify-end gap-16">
      <BittsButton
        text="Back"
        size="large"
        data-testid="map-new-columns-cancel"
        type="neutral"
        @click="cancel"
      />
      <BittsButton
        text="Continue Upload"
        data-testid="map-new-columns-confirm"
        size="large"
        @click="upload"
      />
    </div>
  </div>
</template>

<script>
import { BittsButton } from '@crossbeam/bitts';

export default {
  name: 'ConfirmMapColumns',
  components: {
    BittsButton,
  },
  props: {
    confirmationMessage: {
      type: String,
      required: true,
    },
  },
  emits: ['cancel', 'upload'],
  setup(_, { emit }) {
    function cancel() {
      emit('cancel');
    }

    function upload() {
      emit('upload');
    }

    return {
      cancel,
      upload,
    };
  },
};
</script>
<style lang="pcss" scoped>
.c-confirm-map-columns {
  @apply pb-24 px-24;

  .ae-mapping-explainer {
    @apply text-neutral-text mb-48;
    font-size: 16px;
  }
}
</style>

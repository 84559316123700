<template>
  <BittsModal
    :disabled="isSaveDisabled"
    :loading="loading"
    :visible="visible && isSharingModalVisible"
    :width="1000"
    name="sharing-settings-modal"
    :title="modalText.title"
    :show-buttons="canEditSharing"
    :show-divider="true"
    class="sharing-settings-modal"
    @closed="hideModal"
    @saved="onSharingSettingsSave"
  >
    <template #content>
      <div
        class="flex items-stretch"
        :class="{ 'sharing-settings-modal__population': !isPartnerType }"
      >
        <div
          :class="{ 'p-24': !isPartnerType }"
          class="flex flex-col gap-16 w-full"
        >
          <BittsSegment
            v-if="isPartnerType"
            :tabs="DEFAULT_TOGGLER_TABS"
            :initial-active-index="partnerDefaultTab"
            :class="{ 'pointer-events-none': !canEditSharing }"
            variant="segmented_control"
            @change:segment="(_, tab) => (partnerDefaultTab = tab)"
          />
          <div
            v-if="!isPartnerType || !!partnerDefaultTab"
            data-testid="pop-and-partner-override"
            class="flex flex-col gap-16"
          >
            <div class="text-neutral-text-strong font-bold">
              {{ modalText.text }}
            </div>
            <div
              :class="{ 'pointer-events-none': !canEditSharing }"
              class="flex gap-8"
            >
              <button
                v-for="viz in SELECTABLE_VISIBILITY_OPTIONS"
                :key="viz.value"
                :class="selectedViz === viz.value ? viz.activeClasses : ''"
                class="sharing-settings-modal__visibility"
                @click="selectedViz = viz.value"
                type="button"
              >
                {{ viz.name }}
              </button>
            </div>
            <div class="sharing-settings-modal__sharing-info">
              {{ sharingText[selectedViz] }}
              <GreenfieldSharingSelector
                v-if="selectedViz === SHARING"
                data-testid="greenfield-sharing"
                :can-edit-sharing="canEditSharing"
                :is-greenfield-checked="isGreenfieldChecked"
                :partner="partner"
                :show-alert="autoDeletingOverrides"
                @greenfield-checked="onGreenfieldChecked"
              />
            </div>
            <BittsAlert
              v-if="selectedViz === SHARING"
              color="info"
              description="Please select Counts or Hidden if you don’t want to share any data"
            />
            <OverrideAlert
              v-if="autoDeletingOverrides && selectedViz === HIDDEN"
              :partner-name="partner?.name"
            />
            <div
              v-if="selectedViz === SHARING"
              data-testid="data-sharing-info"
              :class="{ 'h-[280px]': isUpload }"
              class="sharing-settings-modal__field-sharing"
            >
              <div class="flex items-center justify-between">
                Select fields to share
                <div
                  data-testid="num-of-selected-fields"
                  class="text-neutral-text text-sm"
                >
                  {{
                    `${selectedFields.length}/${allSelectableFields.length} fields selected`
                  }}
                </div>
              </div>
              <div
                v-for="preset in sharingPresets"
                :key="preset.value"
                class="w-full"
              >
                <DataSharingPreset
                  :can-edit-sharing="canEditSharing"
                  :hide-recommended="isUpload"
                  :population-source-id="props.population?.source_id"
                  :preset="preset"
                  class="w-full"
                />
                <BittsDivider
                  v-if="preset.value === RECOMMENDED"
                  text="or"
                  class="my-24"
                />
              </div>
              <BittsCallout
                v-if="isUpload"
                data-testid="add-crm-callout"
                action-text="Add a CRM"
                size="x-small"
                subtitle="For improved data sharing recommendations and account mapping, connect a CRM to sync fields"
                title="Connect a CRM"
                type="warning"
                @bitts-callout-action="onAddCRM"
              />
            </div>
          </div>
          <PopulationSharingDefaults
            v-else
            :can-edit-sharing
            :partner="partner"
            :population-setting="props.population.populationSetting"
            :show-alert="autoDeletingOverrides"
            @edit-pop-defaults="setAndShowModal(CONFIRM_EDIT_DEFAULT)"
          />
          <PartnerPopulationOverrides
            v-if="
              sharingOverridesEnabled &&
              isPartnerType &&
              !isGreenfieldChecked &&
              !!partnerDefaultTab &&
              selectedViz !== HIDDEN &&
              partnerPops?.length
            "
            :can-edit-sharing="canEditSharing"
            :partner-id="props.partnerId"
            :partner-pops="partnerPops"
            :pop-to-pop-overrides-enabled="arePopOverridesEnabled"
            @override-added="(popId) => onOverrideAdded(popId)"
            @override-deleted="(popId) => onOverrideDeleted(popId)"
            @pop-to-pop-checked="
              (checked) => (arePopOverridesEnabled = checked)
            "
          />
        </div>

        <NonDefaultPartnersSidebar
          v-if="!isPartnerType"
          :partners-not-using-defaults="partnersNotUsingDefaults"
          class="w-1/3"
        />
      </div>
      <BittsTooltip>
        <BittsAlert
          v-if="migratedFromReveal && selectedViz === SHARING"
          color="info"
          :description="importedPartnerFromRevealAlertMessage"
          class="my-20"
          data-testid="alert-sharing-partners-imported-from-reveal"
        />
        <template #title>
          <div
            v-for="visiblePartner in visiblePartnerInTooltipList"
            :key="visiblePartner"
          >
            {{
              `${visiblePartner.name}${visiblePartner.isPending ? ' · Pending' : ''}`
            }}
          </div>
          <div v-if="remainingPartnerCount > 0">
            {{ `+${remainingPartnerCount} more` }}
          </div>
        </template>
      </BittsTooltip>
    </template>
  </BittsModal>
  <BittsModal
    :loading
    :visible="modalInfo[currentModal].visible"
    :show-close-button="false"
    :width="400"
    :name="modalInfo[currentModal].name"
    :save-text="modalInfo[currentModal].saveText"
    :title="modalInfo[currentModal].title"
    class="confirm-default-modal"
    @closed="modalInfo[currentModal].toggle"
    @saved="modalInfo[currentModal].onSaved"
  >
    <template #content>
      {{ modalInfo[currentModal].text }}
    </template>
  </BittsModal>
</template>

<script setup>
import {
  BittsAlert,
  BittsCallout,
  BittsDivider,
  BittsModal,
  BittsSegment,
  BittsTooltip,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, onMounted, provide, ref } from 'vue';
import { useRouter } from 'vue-router';

import DataSharingPreset from '@/components/partners/DataSharingSettings/DataSharingPreset.vue';
import GreenfieldSharingSelector from '@/components/partners/DataSharingSettings/GreenfieldSharingSelector.vue';
import NonDefaultPartnersSidebar from '@/components/partners/DataSharingSettings/NonDefaultPartnersSidebar.vue';
import OverrideAlert from '@/components/partners/DataSharingSettings/OverrideAlert.vue';
import PartnerPopulationOverrides from '@/components/partners/DataSharingSettings/PartnerPopulationOverrides.vue';
import PopulationSharingDefaults from '@/components/partners/DataSharingSettings/PopulationSharingDefaults.vue';

import useAuth from '@/composables/useAuth';
import useDataSharing from '@/composables/useDataSharing';
import useGreenfieldSharing from '@/composables/useGreenfieldSharing';
import useIteratively from '@/composables/useIteratively';
import {
  CUSTOM,
  GREENFIELD_SHARING,
  HIDDEN,
  PARTNER_RULE,
  POPULATION_RULE,
  RECOMMENDED,
  SELECTABLE_VISIBILITY_OPTIONS,
  SHARING,
} from '@/constants/data_shares';
import {
  LD_TRACKING_KEYS,
  SHARING_OVERRIDES_ENABLED,
} from '@/constants/feature_flags';
import {
  useDataSharesStore,
  useFeatureFlagStore,
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  partnerId: {
    type: Number,
    default: null,
  },
  population: {
    type: Object,
    required: true,
  },
  visible: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['hide-sharing-modal']);

const DEFAULT_TOGGLER_TABS = [
  {
    title: 'Use Population Sharing Defaults',
    id: 'sharing-defaults',
    icon: ['far', 'gear'],
  },
  {
    title: 'Customize Sharing Settings',
    id: 'custom',
    icon: ['far', 'wrench'],
  },
];

const dataSharesStore = useDataSharesStore();
const ffStore = useFeatureFlagStore();
const flashesStore = useFlashesStore();
const populationsStore = usePopulationsStore();
const sourcesStore = useSourcesStore();
const partnersStore = usePartnersStore();
const { popSourceIdToSourcesMap } = storeToRefs(sourcesStore);
const { partnerOrgs, proposalsSent } = storeToRefs(partnersStore);

const { hasScope, migratedFromReveal } = useAuth();
const {
  checkRequiredFields,
  reqdGreenfieldFieldIds,
  setRequiredGreenfieldFieldIds,
} = useGreenfieldSharing();
const {
  areOnlyPresetFieldsSelected,
  allSelectableFields,
  customSelectedFields,
  deleteAllOverrides,
  isGreenfieldChecked,
  isNewPop,
  isPartnerType,
  isPopulationLevelRule,
  isUpload,
  modalText,
  onOverrideAdded,
  onOverrideDeleted,
  partner,
  partnersNotUsingDefaults,
  popToPopSettings,
  presetFieldIds,
  saveOverrideSettings,
  selectedPreset,
  selectRecommendedFields,
  sharingPresets,
  sharingText,
  trackDataSharingChange,
} = useDataSharing({
  population: props.population,
  partnerId: props.partnerId,
});

const CONFIRM_EDIT_DEFAULT = 'confirm-edit-default';
const CONFIRM_OVERRIDE_DEFAULT = 'confirm-override-default';
const isSharingModalVisible = ref(true);
const isConfirmOverrideDefaultVisible = ref(false);
const isConfirmDefaultModalVisible = ref(false);
const currentModal = ref(CONFIRM_EDIT_DEFAULT);
function toggleConfirmOverrideDefaultVisibility() {
  isConfirmOverrideDefaultVisible.value =
    !isConfirmOverrideDefaultVisible.value;
  isSharingModalVisible.value = !isSharingModalVisible.value;
}
function toggleConfirmDefaultModalVisibility() {
  isConfirmDefaultModalVisible.value = !isConfirmDefaultModalVisible.value;
  isSharingModalVisible.value = !isSharingModalVisible.value;
}
async function confirmEditDefaults() {
  isConfirmDefaultModalVisible.value = false;
  await router.push({
    name: 'populations.sharing',
    params: { population_id: props.population.id },
  });
}
const modalInfo = computed(() => {
  return {
    [CONFIRM_EDIT_DEFAULT]: {
      name: CONFIRM_EDIT_DEFAULT,
      toggle: toggleConfirmDefaultModalVisibility,
      onSaved: confirmEditDefaults,
      saveText: 'Proceed',
      text: 'This will update the default sharing settings for all partners',
      title: 'Edit Population sharing defaults?',
      visible: isConfirmDefaultModalVisible.value,
    },
    [CONFIRM_OVERRIDE_DEFAULT]: {
      name: CONFIRM_OVERRIDE_DEFAULT,
      toggle: toggleConfirmOverrideDefaultVisibility,
      onSaved: saveSharingSettings,
      saveText: 'Confirm',
      text: 'You are customizing the sharing settings for this partner and they will no longer inherit the default sharing settings for this Population',
      title: 'Update sharing settings for this partner?',
      visible: isConfirmOverrideDefaultVisible.value,
    },
  };
});
function setAndShowModal(modal) {
  currentModal.value = modal;
  modalInfo.value[modal].toggle();
}

const sharingOverridesEnabled = computed(() => {
  return ffStore.hasFeatureFlag(SHARING_OVERRIDES_ENABLED);
});

const canEditSharing = computed(() => hasScope('write:sharing'));

const acceptedPartnerImportedFromRevealList = computed(() =>
  partnerOrgs.value
    .filter((partner) => partner.migrated_from_reveal)
    .map((partner) => ({ name: partner.name, isPending: false })),
);
const pendingPartnerImportedFromRevealList = computed(() =>
  proposalsSent.value
    .filter((proposal) => proposal.migrated_from_reveal)
    .map((proposal) => ({ name: proposal.contact_company, isPending: true })),
);
const partnerImportedFromRevealList = computed(() => [
  ...acceptedPartnerImportedFromRevealList.value,
  ...pendingPartnerImportedFromRevealList.value,
]);
const visiblePartnerInTooltipList = computed(() =>
  partnerImportedFromRevealList.value.slice(0, 12),
);
const remainingPartnerCount = computed(
  () =>
    partnerImportedFromRevealList.value.length -
    visiblePartnerInTooltipList.value.length,
);
const importedPartnerFromRevealAlertMessage = computed(
  () =>
    `You’re about to share this population with ${partnerImportedFromRevealList.value.length} partner${partnerImportedFromRevealList.value.length > 1 ? 's' : ''} imported from Reveal.`,
);

const partnerPops = computed(() => {
  if (!isPartnerType.value) return;
  return populationsStore.getPartnerPopulationsByOrg(props.partnerId);
});

const partnerDefaultTab = ref(isPopulationLevelRule.value ? 0 : 1);
const isSettingToDefault = computed(
  () =>
    isPartnerType.value &&
    !isPopulationLevelRule.value &&
    !partnerDefaultTab.value,
);

const sharesForPayload = computed(() => {
  const payload = {
    rule_type: POPULATION_RULE,
    population_id: props.population.id,
    visibility: selectedViz.value,
  };
  if (payload.visibility.includes(SHARING)) {
    payload.source_field_ids = selectedFields.value;
    if (isGreenfieldChecked.value) payload.visibility = GREENFIELD_SHARING;
  }
  if (isPartnerType.value) {
    payload.rule_type = PARTNER_RULE;
    payload.partner_org_id = props.partnerId;
  }
  return payload;
});

const selectedFields = computed(() => {
  if (!selectedPreset.value || !selectedViz.value.includes(SHARING)) return [];
  if (selectedPreset.value === CUSTOM) return customSelectedFields.value;
  return presetFieldIds.value;
});

const isSaveDisabled = computed(() => {
  // if nothing is selected
  if (!selectedPreset.value) return true;
  // full sharing, no fields selected
  if (selectedViz.value.includes(SHARING) && !selectedFields.value?.length)
    return true;
  // greenfield is checked but somehow not all reqd fields are selected
  if (
    isGreenfieldChecked.value &&
    selectedViz.value.includes(SHARING) &&
    !reqdGreenfieldFieldIds.value.every((id) =>
      selectedFields.value.includes(id),
    )
  )
    return true;
  // in partner sharing, you have the default, and you're on the default view
  if (
    isPartnerType.value &&
    !isSettingToDefault.value &&
    !partnerDefaultTab.value
  )
    return true;
  return false;
});

const dataShareId = computed(() => props.population.dataShare.id);
async function saveSharingRule() {
  let url = urls.dataShares.outgoingRules.default;
  let axiosFn = axios.post;
  let shareExists = !!dataShareId.value;
  if (isPartnerType.value && isPopulationLevelRule.value) {
    shareExists = false;
  }
  if (shareExists) {
    url = urls.dataShares.outgoingRules.byId(dataShareId.value);
    axiosFn = axios.patch;
  }
  await axiosFn(url, sharesForPayload.value);
}
async function onSharingSettingsSave() {
  if (
    !isSettingToDefault.value &&
    isPopulationLevelRule.value &&
    isPartnerType.value
  )
    setAndShowModal(CONFIRM_OVERRIDE_DEFAULT);
  else await saveSharingSettings();
}
async function saveSharingSettings() {
  loading.value = true;
  if (isSettingToDefault.value) {
    await dataSharesStore.removeAndDeleteDataShare(dataShareId.value);
  } else {
    await saveSharingRule();
    let fieldsShared = [];
    let prevFieldsShared = [];
    const { dataShare } = props.population;
    if (
      dataShare.source_field_ids &&
      dataShare.visibility &&
      dataShare.visibility.includes(SHARING)
    ) {
      prevFieldsShared = props.population.dataShare.source_field_ids.map((id) =>
        id.toString(),
      );
    }
    if (
      selectedViz.value.includes(SHARING) &&
      sharesForPayload.value.source_field_ids
    ) {
      fieldsShared = sharesForPayload.value.source_field_ids.map((id) =>
        id.toString(),
      );
    }
    trackDataSharingChange({
      new_field_ids_shared: fieldsShared,
      partner_id: props.partnerId ? [props.partnerId.toString()] : [],
      partner_population_id: [],
      previous_sharing_rule: props.population.dataShare.visibility || HIDDEN,
      previous_field_ids_shared: prevFieldsShared,
      sharing_level: sharesForPayload.value.visibility,
      sharing_rule: sharesForPayload.value.rule_type,
    });
    if (arePopOverridesEnabled.value && selectedViz.value !== HIDDEN)
      await saveOverrideSettings();
  }
  if (
    overridesExist.value &&
    ((autoDeletingOverrides.value && selectedViz.value !== HIDDEN) ||
      isDeletingOverrides.value)
  )
    await deleteAllOverrides();
  await dataSharesStore.refreshDataSharesStore();
  ffStore.trackViaLaunchDarkly(
    LD_TRACKING_KEYS.NUMBER_OF_FIELDS,
    selectedFields.value.length,
  );
  flashesStore.addSuccessFlash({ message: 'Sharing settings saved' });
  hideModal();
  loading.value = false;
}

const selectedViz = ref(SHARING);
const arePopOverridesEnabled = ref(
  !!props.population.populationToPopulationSettings?.length,
);
if (props.population.populationToPopulationSettings) {
  popToPopSettings.value =
    props.population?.populationToPopulationSettings.reduce(
      (settings, setting) => {
        settings[setting.partnerPopulation.id] = setting;
        return settings;
      },
      {},
    );
}
const overridesExist = computed(
  () => !!props.population?.populationToPopulationSettings?.length,
);
const isDeletingOverrides = computed(
  () => overridesExist.value && !arePopOverridesEnabled.value,
);
const autoDeletingOverrides = computed(() => {
  if (!overridesExist.value) return false;
  return (
    sharesForPayload.value.visibility === GREENFIELD_SHARING ||
    isSettingToDefault.value ||
    selectedViz.value === HIDDEN
  );
});

const loading = ref(false);

function setSharingSettings() {
  const { dataShare } = props.population;
  if (isNewPop.value) {
    selectedViz.value = SHARING;
    selectRecommendedFields();
    return;
  }
  isGreenfieldChecked.value = dataShare.visibility === GREENFIELD_SHARING;
  if (dataShare.visibility.includes(SHARING)) {
    selectedViz.value = SHARING;
    if (areOnlyPresetFieldsSelected(dataShare.source_field_ids)) {
      selectedPreset.value = RECOMMENDED;
      customSelectedFields.value = [...presetFieldIds.value];
    } else {
      customSelectedFields.value = [...dataShare.source_field_ids];
      selectedPreset.value = CUSTOM;
    }
  } else {
    selectedViz.value = dataShare.visibility;
    selectRecommendedFields();
  }
}

onMounted(async () => {
  loading.value = true;
  await Promise.all([
    dataSharesStore.refreshDataSharesStore(),
    sourcesStore.refreshSourcesStore(),
  ]);
  if (!popSourceIdToSourcesMap.value[props.population.source_id]) {
    await sourcesStore.setSourcesByPopulationSourceId(props.population);
  }
  setRequiredGreenfieldFieldIds(
    props.population.population_type,
    popSourceIdToSourcesMap.value[props.population.source_id],
  );
  setSharingSettings();
  loading.value = false;
});

provide('greenfieldSourceFields', reqdGreenfieldFieldIds);
provide('popToPopOverrides', props.population.populationToPopulationSettings);

function onGreenfieldChecked(checked) {
  isGreenfieldChecked.value = checked;
  if (selectedPreset.value === CUSTOM && checked) {
    customSelectedFields.value = checkRequiredFields(
      customSelectedFields.value,
    );
  }
}

const router = useRouter();
const { iteratively } = useIteratively();
async function onAddCRM() {
  iteratively.userClickedSyncData({
    cta_location: 'Data Sharing Presets', // TODO: Deprecate, this is for EVENT_SITE now
    data_type: 'sync_crm',
    event_site: EVENT_SITES.SHARING_PRESETS_CTA,
  });
  await router.push({ name: 'data-sources' });
}

function hideModal() {
  emit('hide-sharing-modal');
  isConfirmDefaultModalVisible.value = false;
  isConfirmOverrideDefaultVisible.value = false;
}
</script>

<style lang="pcss" scoped>
.sharing-settings-modal__field-sharing {
  @apply border border-solid border-neutral-border font-bold p-12 rounded-bts-md flex flex-col
  items-stretch gap-8 h-full overflow-y-auto;
}
.sharing-settings-modal__greenfield-sharing {
  @apply text-base font-bold flex items-center mt-16;
}
.sharing-settings-modal__population {
  @apply border border-solid border-neutral-border rounded-bts-md;
}
.sharing-settings-modal__sharing-info {
  @apply py-12 px-16 text-neutral-text bg-neutral-background-weak rounded-bts-sm;
}
.sharing-settings-modal__visibility {
  @apply border border-solid border-neutral-border text-neutral-text-weak
   w-full text-center rounded-bts-sm py-12 font-bold;
}
</style>
<style lang="pcss">
.sharing-settings-modal {
  &.ant-modal {
    top: 25px;
  }
  .bitts-callout__subtitle {
    @apply font-normal mr-16;
  }
  .c-bitts-modal__buttons--wrapper {
    @apply justify-between;
  }
  .c-loading {
    height: 727px !important;
  }
}
.confirm-default-modal {
  .ant-btn div {
    @apply justify-center;
  }
  .ant-btn,
  .c-bitts-modal__buttons--wrapper div {
    @apply w-full;
  }
  .c-bitts-modal__content {
    @apply text-center text-neutral-text;
  }
  .c-bitts-modal__title {
    @apply text-xl w-full text-center;
  }
}
</style>

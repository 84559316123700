<template>
  <div>
    <BittsPopover
      v-if="showEditColumnInput || props.isCustomTextColumnFactory"
      :mount-to-body="true"
      :show-menu="props.showMenu"
      :align="align"
      overlay-class="c-shared-list-column-menu-item__popover"
    >
      <BittsButton
        v-if="props.isCustomTextColumnFactory"
        variant="outline"
        size="x-small"
        :center-icon="['fas', 'add']"
        @click="onMenuButtonClicked"
      />
      <div v-else>
        <FontAwesomeIcon
          class="cursor-pointer"
          :icon="['far', 'bars-filter']"
          :style="{ height: '12px', width: '12px' }"
          @click="onMenuButtonClicked"
        />
      </div>
      <template #content>
        <BittsLoading :is-loading="false">
          <div @mouseleave="onMouseLeave">
            <BittsInput
              v-model="columnName"
              class="mb-8"
              form-label="Column Name"
              placeholder="Add a column name"
              name="column_name"
              :status="hasErrorStatus"
              danger-text="Name must be 30 chars or less"
              @keyup.enter="
                () => {
                  if (!isBtnDisabled) onClickedBtn();
                }
              "
            />
            <BittsButton
              :text="menuButtonText"
              size="x-small"
              type="neutral"
              class="w-full"
              :disabled="isBtnDisabled"
              @click="onClickedBtn"
            />
          </div>
        </BittsLoading>
      </template>
    </BittsPopover>
    <BittsDropdown
      v-else
      :align="align"
      :options="dropdownOptions"
      :mount-to-body="true"
      placement="bottomRight"
      @selection="handleSelection"
    >
      <template #prompt>
        <FontAwesomeIcon
          class="cursor-pointer"
          :icon="['far', 'bars-filter']"
          :style="{ height: '12px', width: '12px' }"
        />
      </template>
    </BittsDropdown>
  </div>
</template>
<script setup lang="ts">
import {
  BittsButton,
  BittsDropdown,
  BittsInput,
  BittsLoading,
  BittsPopover,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { GridOptions } from '@ag-grid-community/core';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import useIteratively from '@/composables/useIteratively';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import { SharedList, SharedListPostColumnsPayload } from '@/types/shared_lists';

type ColumnMenuItemParams = GridOptions & {
  column: { colId: string };
  currentList?: SharedList;
  displayName?: string;
};

type Props = {
  isCustomTextColumnFactory?: boolean;
  params?: ColumnMenuItemParams | null;
  showMenu?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  isCustomTextColumnFactory: true,
  params: null,
  showMenu: false,
});

const emit = defineEmits<{
  (e: 'delete-button-clicked'): void;
  (e: 'menu-button-clicked', value: string | boolean): void;
}>();

const MAX_COLUMN_NAME_COUNT = 30;

const flashesStore = useFlashesStore();
const columnName = ref(props.params?.displayName);
const showEditColumnInput = ref(false);

const listId = computed(() => props.params?.currentList?.id);
const colId = computed(() => props.params?.column.colId);

const { iteratively } = useIteratively();

const menuButtonText = computed(() =>
  props.isCustomTextColumnFactory ? 'Create' : 'Save',
);
const hasErrorStatus = computed(() =>
  columnName.value && columnName.value.length > MAX_COLUMN_NAME_COUNT
    ? 'danger'
    : undefined,
);
const isBtnDisabled = computed(
  () =>
    !!hasErrorStatus.value ||
    !columnName.value ||
    columnName.value === props.params?.displayName,
);

const onClickedBtn = computed(() =>
  props.isCustomTextColumnFactory ? onClickedCreateBtn : onClickedSaveBtn,
);

const align = {
  points: ['tr', 'tr'],
  offset: [12, 25],
};

function onMenuButtonClicked(columnName: string | undefined = undefined) {
  const colNameExists = columnName || false;
  emit('menu-button-clicked', colNameExists);
}

function onMouseLeave() {
  onMenuButtonClicked();
  columnName.value = props.params?.displayName;
  showEditColumnInput.value = false;
}

async function onClickedCreateBtn() {
  try {
    if (!listId.value) return;
    const body: SharedListPostColumnsPayload = {
      display_name: columnName.value || '',
    };
    const path = { list_id: listId.value };
    await crossbeamApi.POST('/v0.1/lists/{list_id}/columns', {
      body,
      params: { path },
    });
    submitItlyEvent('Create Column', columnName.value || '');
    onMenuButtonClicked();
    showEditColumnInput.value = false;
    await props.params?.context.resetGrid();
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

async function onClickedSaveBtn() {
  try {
    if (!listId.value || !colId.value) return;
    const path = { list_id: listId.value, column_id: colId.value };
    await crossbeamApi.PATCH('/v0.1/lists/{list_id}/columns/{column_id}', {
      body: {
        display_name: columnName.value || '',
      },
      params: { path },
    });
    submitItlyEvent(
      'Edit Column',
      columnName.value || '',
      props.params?.displayName || null,
    );
    onMenuButtonClicked(columnName.value);
    showEditColumnInput.value = false;
    await props.params?.context.refreshStore();
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

function submitItlyEvent(
  action: string,
  columnName: string,
  oldValue: string | null = null,
) {
  type ItlyPayload = {
    column_action: string;
    column_name: string;
    event_site: typeof EVENT_SITES.SHARED_LIST_COLUMN_MENU;
    old_value?: string;
  };
  const payload: ItlyPayload = {
    column_action: action,
    column_name: columnName,
    event_site: EVENT_SITES.SHARED_LIST_COLUMN_MENU,
  };
  if (oldValue) payload.old_value = oldValue;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iteratively.userActsOnListColumns(payload as any);
}

const dropdownOptions = [
  { display: 'Edit Column', value: 'edit', iconType: ['far', 'pencil'] },
  { display: 'Delete Column', value: 'delete', iconType: ['far', 'trash'] },
];

function handleSelection({ value }: { value: 'edit' | 'delete' }) {
  switch (value) {
    case 'edit':
      showEditColumnInput.value = true;
      onMenuButtonClicked();
      break;
    case 'delete':
      emit('delete-button-clicked');
      break;
  }
}
</script>
<style lang="pcss">
.c-shared-list-column-menu-item__popover {
  .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    @apply p-12;
  }
}
</style>

<template>
  <BittsCard class="c-onboarding-card">
    <div class="card-content">
      <BittsTag
        v-if="tag"
        :color="tag.color"
        variant="rounded"
        size="x-small"
        :text="tag.text"
      />
      <BittsSvg
        :class="{
          'mt-24': !tag,
        }"
        :svg="svg"
        class="h-80 w-80 mb-24"
      />
      <slot />
      <ul class="card-list">
        <li
          v-for="(feature, i) in featureList"
          :key="`feature__${i}`"
          class="card-list__item"
        >
          <FontAwesomeIcon
            :class="{
              'text-danger-accent': !feature.supported,
              'text-neutral-accent': feature.supported,
            }"
            :icon="feature.supported ? ['fas', 'check'] : ['far', 'xmark']"
          />
          <span>
            {{ feature.text }}
          </span>
        </li>
      </ul>
      <div class="what-you-need">
        <h4>What you'll need</h4>
        <ul class="needs-list">
          <slot name="needs" />
        </ul>
      </div>
    </div>
  </BittsCard>
</template>

<script setup>
import { BittsCard, BittsSvg, BittsTag } from '@crossbeam/bitts';

defineProps({
  featureList: {
    type: Array,
    required: true,
  },
  svg: {
    type: String,
    required: true,
  },
  tag: {
    type: Object,
    default: null,
  },
});
</script>
<script>
/* Returns list of objects indicating whether a feature is supported or not */
export function makeFeatureList(features, supported) {
  return features.map((feature, i) => ({
    text: feature,
    supported: supported[i],
  }));
}
</script>

<style lang="pcss" scoped>
.c-onboarding-card {
  @apply h-full;
  .card-content {
    @apply flex flex-col p-16 items-center h-full;

    .card-list {
      @apply mt-24;
    }

    .card-list__item {
      @apply flex items-center text-neutral-text gap-6 mt-4;
    }

    .what-you-need {
      @apply bg-neutral-background-weak rounded-16 p-16 w-full mt-24 flex-1;
      h4 {
        @apply font-bold text-neutral-text-strong text-sm;
      }

      .needs-list {
        @apply text-neutral-text list-disc text-sm mt-4 flex flex-col h-full;
      }
    }
  }
}
</style>
<style lang="pcss">
.c-onboarding-card {
  .bitts-tag {
    @apply block ml-auto;
  }
}
</style>

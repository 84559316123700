<template>
  <section class="purchase-successful">
    <BittsButton
      :center-icon="['fas', 'x']"
      variant="ghost"
      type="white"
      size="large"
      class="close-icon"
      data-testid="close-icon"
      @click="handleClose"
    />
    <div class="welcome-to-the-team__content">
      <BittsSvg svg="logoNoText" class="welcome-to-the-team__logo" />
      <h1 class="main-title">
        {{ V4_BILLING_COPY.purchaseSuccessful.title }}
      </h1>
      <p class="description">
        {{ V4_BILLING_COPY.purchaseSuccessful.description }}
      </p>
      <div class="button-grid">
        <router-link
          class="action-button"
          data-testid="population-action"
          :to="{ name: 'new_population' }"
        >
          <FontAwesomeIcon
            class="mb-2 text-info-accent"
            :icon="['far', 'chart-pie-simple']"
            :style="{ height: '16px', width: '16px' }"
          />
          Create a Custom Population
        </router-link>
        <router-link
          class="action-button"
          data-testid="shared-list-action"
          :to="{ name: 'collaborate' }"
        >
          <FontAwesomeIcon
            class="mb-2 text-info-accent"
            :icon="['far', 'file-chart-column']"
            :style="{ height: '16px', width: '16px' }"
          />
          <div>Create a Shared List</div>
        </router-link>
        <router-link
          class="action-button"
          data-testid="report-action"
          :to="{ name: 'account_mapping' }"
        >
          <FontAwesomeIcon
            class="mb-2 text-info-accent"
            :icon="['far', 'arrow-right-arrow-left']"
            :style="{ height: '16px', width: '16px' }"
          />
          <div>Create an Advanced Report</div>
        </router-link>
        <router-link
          class="action-button"
          data-testid="integrations-action"
          :to="{ name: 'integrations' }"
        >
          <FontAwesomeIcon
            class="mb-2 text-info-accent"
            :icon="['far', 'cube']"
            :style="{ height: '16px', width: '16px' }"
          />
          Install an Integration
        </router-link>
      </div>
      <BittsCallout
        v-if="migratedUsers.length"
        data-testid="migration-callout"
        class="max-w-[670px] mt-16"
        size="small"
        type="info"
        :title="migrationCalloutTitle"
        subtitle="Your new Connector plan comes with Crossbeam for Sales so we auto-upgraded anyone who is an Admin or Partner Manager for you. You can change this on the Teams page."
      >
        <template #left-item>
          <FontAwesomeIcon
            :icon="['fas', 'stars']"
            class="w-24 h-24 mr-12 text-info-accent"
          />
        </template>
        <template #right-item>
          <BittsUserStack :users="migratedUsers" size="small" />
        </template>
      </BittsCallout>
      <BittsButton
        text="Continue to Crossbeam"
        type="neutral"
        class="mt-16"
        size="large"
        @click="handleClose"
      />
    </div>
  </section>
</template>

<script setup>
import {
  BittsButton,
  BittsCallout,
  BittsSvg,
  BittsUserStack,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { V4_BILLING_COPY } from '@/constants/billing';
import { PARTNER_MANAGER } from '@/constants/team';
import { useTeamStore } from '@/stores';

useHead({ title: 'Welcome' });

const router = useRouter();
const teamStore = useTeamStore();

// TODO: Fix this with new sales edge roles
const migratedUsers = computed(() =>
  teamStore.authorizations
    .filter((auth) => auth.sales_edge_role === PARTNER_MANAGER)
    .map((auth) => auth.user),
);

const migrationCalloutTitle = computed(() => {
  if (migratedUsers.value.length === 1)
    return '1 user is now a Manager on Crossbeam for Sales';
  return `${migratedUsers.value.length} users are now Managers on Crossbeam for Sales`;
});

async function handleClose() {
  await router.push({ name: 'team' });
}
</script>

<style lang="pcss" scoped>
.purchase-successful {
  @apply flex flex-1 flex-col items-center px-16 pb-48;
  /* This comes directly from Figma 😁 */
  background: radial-gradient(
      57.15% 41.02% at 23.19% 83.74%,
      theme(colors.upsell.accent / 0.8) 0%,
      theme(colors.upsell.accent / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.info.accent / 0.8) 0%,
      theme(colors.info.accent / 0) 100%
    ),
    linear-gradient(
      180deg,
      theme(colors.info.accent) 0%,
      theme(colors.primary.text-button) 100%
    );
}

.welcome-to-the-team__content {
  @apply flex flex-col items-center h-full flex-1 md:mt-80;
}

.welcome-to-the-team__logo {
  @apply w-64 h-64 md:w-80 md:h-80 mb-40 mt-72;
}

.main-title {
  background: linear-gradient(
    91.59deg,
    theme(colors.info.accent) 0%,
    theme(colors.white) 100%
  );
  @apply text-xl md:text-xxxl bg-clip-text font-bold mb-16 text-center;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.description {
  @apply text-m md:text-lg text-white text-center mb-40 opacity-75;
  max-width: 560px;
}

.action-button {
  @apply text-white rounded-16 py-16 flex items-center gap-8 pl-16 md:w-[330px] text-m border border-transparent border-r-0;
  background: linear-gradient(
    100.88deg,
    theme(colors.white / 0.2) 0%,
    theme(colors.white / 0) 100%
  );

  &:hover {
    @apply border-info-accent border-r;
  }
}

.button-grid {
  @apply grid md:grid-cols-2 gap-16 w-full;
}

.close-icon {
  @apply absolute top-16 right-16;
}
</style>

<template>
  <BittsTree
    v-if="!noReportAvailable && !ownNotSharing"
    :expanded="expandedKeys"
    :tree-data="[parentForTree]"
    :checked-keys="customPopulationsForReport[treeParent.id] || []"
    :show-checkboxes="true"
    :multi-select="true"
    class="report-population-tree"
    @check="onPopulationChecked"
  />
  <div v-else class="report-population-tree report-population-tree__no-report">
    <BittsCheckbox :checked="false" :disabled="true" />
    <BittsAvatar
      :org="treeParent"
      :show-initials="true"
      size="x-small"
      shape="square"
    />
    <span class="font-bold">{{ treeParent.name }}</span>
    <div v-if="!isOwnOrg" class="report-population-tree__sharing-callout">
      {{ sharingText }}
      <button
        class="report-population-tree__sharing-callout-cta"
        @click="onSharingCalloutClicked"
        type="button"
      >
        {{ sharingLinkText }}
        <FontAwesomeIcon
          :icon="['far', 'arrow-up-right']"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          class="text-info-accent ml-2"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { BittsAvatar, BittsCheckbox, BittsTree } from '@crossbeam/bitts';

import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useReports from '@/composables/useReports';
import { OVERLAP_DATA_STATE_KEY } from '@/constants/amm_grid';

const props = defineProps({
  disabledTree: {
    type: Boolean,
    default: false,
  },
  expanded: {
    type: Boolean,
    default: true,
  },
  isOrg: {
    type: Boolean,
    default: true,
  },
  parent: {
    type: Object,
    default: null,
  },
  populations: {
    type: Array,
    default: () => [],
  },
  reportType: {
    type: String,
    default: '',
  },
  outgoingPopExclusions: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const { currentOrg } = useAuth();

const treeParent = computed(() => props.parent || currentOrg.value);

const router = useRouter();

const { customPopulationsForReport, hasReportChanged } = inject(
  OVERLAP_DATA_STATE_KEY,
);
const { getReportPopulationTreeKey } = useReports();

const isOwnOrg = computed(() => !props.parent);

const noReportAvailable = computed(() => !props.populations.length);

const ownNotSharing = computed(() =>
  props.outgoingPopExclusions?.no_revealed_populations.includes(
    treeParent.value.id,
  ),
);

const sharingText = computed(() =>
  ownNotSharing.value
    ? 'You are not sharing data with this partner'
    : 'Partner is not sharing data with you',
);

const sharingLinkText = computed(() =>
  ownNotSharing.value ? 'Change Sharing Settings' : 'Request Data',
);

const populationsForTree = computed(() => {
  return props.populations.map((population) => {
    const scopedSlots = {};
    if (population.standard_type) scopedSlots.suffix = 'standard-tag';
    return {
      ...population,
      class: props.disabledTree
        ? 'report-population-tree__population-node-disabled'
        : 'report-population-tree__population-node',
      title: population.name,
      key: getReportPopulationTreeKey(treeParent.value.id, population.id),
      scopedSlots,
      disabled: props.disabledTree,
    };
  });
});

const parentKey = computed(() =>
  getReportPopulationTreeKey(treeParent.value.id, null, 'org'),
);
const parentForTree = computed(() => {
  const scopedSlots = {};
  if (props.isOrg) scopedSlots.title = 'avatar';
  else scopedSlots.title = 'prefix-icon';
  return {
    children: populationsForTree.value,
    key: parentKey.value,
    isOwn: !props.parent,
    org: treeParent.value,
    scopedSlots,
    prefixIcon:
      props.reportType === 'partner-tags'
        ? ['fas', 'tag']
        : ['fas', 'circle-nodes'],
    prefixIconClasses:
      'text-neutral-accent p-4 bg-primary-background-weak rounded-bts-sm mr-4 w-8 h-8',
    title: treeParent.value.name,
    class: props.disabledTree
      ? 'report-population-tree__parent-node-disabled'
      : null,
    disabled: !props.populations.length || props.disabledTree,
  };
});

function onPopulationChecked(populationKeys) {
  hasReportChanged.value = true;
  customPopulationsForReport.value[treeParent.value.id] = populationKeys;
}
async function onSharingCalloutClicked() {
  if (!props.parent) return;
  if (!ownNotSharing.value) {
    await router.push({
      name: 'partner_details',
      params: { partner_org_id: props.parent.id },
      query: { tab: 'shared_with_you' },
    });
  } else {
    await router.push({
      name: 'partner_details',
      params: { partner_org_id: props.parent.id },
      query: { tab: 'sharing' },
    });
  }
}

const expandedKeys = computed(() => {
  return props.expanded ? [parentKey.value] : [];
});
</script>

<style lang="pcss" scoped>
.report-population-tree {
  @apply py-12 px-16 border border-solid border-neutral-border rounded-bts-md cursor-pointer;
  &.report-population-tree__no-report {
    @apply pl-24 flex items-center gap-8;
  }
}
.report-population-tree__sharing-callout {
  @apply flex items-center text-neutral-text-weak text-sm ml-auto;
}
.report-population-tree__sharing-callout-cta {
  @apply text-info-text ml-8;
}
</style>

<style lang="pcss">
.report-population-tree {
  @apply max-h-[350px] overflow-y-auto overflow-x-hidden;
  .bitts-tag.bitts-tag__rounded .ant-tag {
    @apply pr-4;
  }
  .ant-tree-checkbox {
    @apply z-20;
  }
  .ant-tag {
    @apply font-normal;
  }
  .ant-tree {
    @apply max-h-[350px];
  }
  .ant-tree-title {
    @apply w-full;
    span {
      @apply flex items-center w-full;
    }
    div {
      @apply font-bold;
    }
  }
  .ant-tree .ant-tree-treenode,
  .ant-tree .ant-tree-treenode-motion {
    @apply pb-0 w-full relative;
  }
  .ant-tree-node-content-wrapper {
    @apply flex items-center;
  }
  .ant-tree-switcher {
    @apply absolute z-10 flex;
  }
  .ant-tree-switcher_close {
    @apply h-full w-full flex-col items-end;
    transform: none;
    span {
      transform: rotate(-90deg);
    }
  }
  .ant-tree-switcher_open {
    @apply w-full justify-end;
  }

  .report-population-tree__population-node {
    @apply ml-24;
  }
  .report-population-tree__parent-node-disabled {
    .ant-tree-checkbox-disabled:not(.ant-tree-checkbox-checked)
      > .ant-tree-checkbox-inner {
      @apply bg-neutral-background-disabled border-neutral-border;
    }
  }
  .report-population-tree__population-node-disabled {
    @apply ml-24;
    .ant-tree-checkbox-disabled:not(.ant-tree-checkbox-checked)
      > .ant-tree-checkbox-inner {
      @apply bg-neutral-background-disabled border-neutral-border;
    }
  }
}
</style>

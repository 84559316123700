<template>
  <BittsLoading :is-loading="!ready">
    <BittsLayout class="populations-page" :is-page="true">
      <template #content>
        <PageTitle
          title="Populations"
          :subtitle
          :class="{ 'mb-16': hasRecordExportGlowup }"
        >
          <template #action>
            <router-link
              v-if="canManagePopulations && !atOrAboveCap && !isOnboarding"
              :to="createPopulationRoute"
            >
              <BittsButton text="Create Population" />
            </router-link>
          </template>
        </PageTitle>
        <OnboardingCallout
          v-if="!showUniqueRecordInfo"
          kind="populations"
          class="mb-40"
        />
        <UniqueRecordsCallout
          v-if="showUniqueRecordInfo && !hasRecordExportGlowup"
          class="mb-40"
        />
        <UniqueRecordsCalloutDeux
          v-if="showUniqueRecordInfo && hasRecordExportGlowup"
          class="mb-40"
          v-model="isRecordExportModalVisible"
        />
        <OnboardingEmptyState v-if="isOnboarding" type="Populations" />
        <PopulationsEmptyState v-else-if="showNoFeedsEmptyState" />
        <div v-else>
          <StandardPopulations />
          <CustomPopulations />
        </div>
      </template>
      <template #populations-modal>
        <router-view-wrapper />
      </template>
    </BittsLayout>
  </BittsLoading>
  <RecordExportSummaryModal
    v-if="isRecordExportModalVisible"
    @closed:record-export-modal="
      isRecordExportModalVisible = !isRecordExportModalVisible
    "
  />
</template>
<script setup>
import { BittsButton, BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import OnboardingEmptyState from '@/components/data-sources/OnboardingEmptyState.vue';
import RecordExportSummaryModal from '@/components/integrations/RecordExportSummaryModal.vue';
import OnboardingCallout from '@/components/onboarding/OnboardingCallout.vue';
import PageTitle from '@/components/PageTitle.vue';
import CustomPopulations from '@/components/populations/CustomPopulations.vue';
import PopulationsEmptyState from '@/components/populations/PopulationsEmptyState.vue';
import StandardPopulations from '@/components/populations/StandardPopulations.vue';
import UniqueRecordsCallout from '@/components/populations/UniqueRecordsCallout.vue';
import UniqueRecordsCalloutDeux from '@/components/populations/UniqueRecordsCalloutDeux.vue';

import useAuth from '@/composables/useAuth';
import useOnboardingSteps from '@/composables/useOnboardingSteps';
import usePopulations from '@/composables/usePopulations';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import {
  COMPLETED_STATUSES,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import { TEMP_RECORD_EXPORT_GLOWUP } from '@/constants/feature_flags';
import {
  useFeatureFlagStore,
  useFeedsStore,
  useFileUploadsStore,
  usePopulationsStore,
} from '@/stores';

useHead({ title: 'Populations - Crossbeam' });

const feedsStore = useFeedsStore();
const fileUploadsStore = useFileUploadsStore();
const populationsStore = usePopulationsStore();
const featureFlagStore = useFeatureFlagStore();

const { feeds } = storeToRefs(feedsStore);
const { uploadTables, ready } = storeToRefs(fileUploadsStore, populationsStore);
const { populations } = storeToRefs(populationsStore);

const { onboardingClosed } = useOnboardingSteps();
const { atOrAboveCap, standardPopulations } = usePopulations();

const { hasScope } = useAuth();
const canManagePopulations = computed(() => hasScope('write:populations'));

const hasPops = computed(() => populations.value.length > 0);
const isOnboarding = computed(() => !onboardingClosed.value && !hasPops.value);

const isRecordExportModalVisible = ref(false);

const { exportLimit } = useRecordExportLimits();
const showUniqueRecordInfo = computed(() => {
  if (!populations.value.length) return false;
  return !!exportLimit.value;
});
const hasRecordExportGlowup = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_RECORD_EXPORT_GLOWUP),
);
const subtitle = computed(() => {
  if (hasRecordExportGlowup.value) {
    return `These are lists of accounts you can choose to share with partners`;
  }
  return 'Create segments of accounts or people that have special meaning to your company';
});

/* Empty State post-Onboarding Flow */
const showNoFeedsEmptyState = computed(
  () => !hasFeeds.value && !populations.value.length,
);
const hasFeeds = computed(() => {
  const feedsInCompleteOrWarningState = feeds.value.filter((feed) => {
    const isFileUpload = feed.integration.type !== FILE_UPLOAD_DATA_SOURCE_TYPE;
    const isPendingOrActiveOrWarning = [
      ...COMPLETED_STATUSES,
      ...WARNING_STATUSES,
    ].includes(feed.status);
    return isFileUpload && isPendingOrActiveOrWarning;
  });

  return (
    !!feedsInCompleteOrWarningState.length ||
    (!!uploadTables.value && !!uploadTables.value.length)
  );
});

const createPopulationRoute = computed(() =>
  standardPopulations.value.length < 3
    ? { name: 'new_population' }
    : { name: 'create_population' },
);
</script>

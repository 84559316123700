<template>
  <div v-if="hasScope('write:members')" class="c-team-table__actions-cell">
    <BittsButton
      v-if="isUser"
      variant="ghost"
      size="x-small"
      type="primary"
      :left-icon="['fas', 'pencil']"
      text="Edit"
      @click="handleEdit"
    />
    <component
      :is="cannotDelete ? BittsTooltip : 'div'"
      :mount-to-body="true"
      placement="left"
    >
      <div>
        <BittsButton
          variant="ghost"
          size="x-small"
          type="danger"
          :center-icon="['fas', 'trash']"
          :disabled="cannotDelete"
          @click="handleDeleteOrRevoke"
        />
      </div>
      <template #title>
        {{ TOOLTIP_SELF }}
      </template>
    </component>
  </div>
</template>

<script setup>
import { BittsButton, BittsTooltip } from '@crossbeam/bitts';

import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { TOOLTIP_SELF } from '@/constants/team';

const props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

const { hasScope } = useAuth();

const authOrInvite = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params?.data;
});
const { isUser, cannotDelete, id } = useAuthOrInvite(authOrInvite);

const router = useRouter();
async function handleDeleteOrRevoke() {
  if (isUser.value) {
    await router.push({
      name: 'remove_user',
      params: {
        user_id: authOrInvite.value.user.id,
      },
    });
  } else {
    await router.push({
      name: 'revoke_invite',
      params: {
        user_id: authOrInvite.value.id,
        email: authOrInvite.value.email,
      },
    });
  }
}

async function handleEdit() {
  await router.push({ name: 'edit_user', params: { user_id: id.value } });
}
</script>

<style lang="pcss">
.c-team-table__actions-cell {
  @apply flex items-center gap-4;
}
</style>

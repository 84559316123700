/**
 * TEMPORARY FLAGS
 * Generally used for gating access to features while we beta test and / or
 * the feature needs to go through design review
 */
export const SALESFORCE_APP_EARLY_ADOPTER = 'early_adopter_salesforce_widget';
export const BQ_CSV_ACCOUNT_EXECUTIVE_FIELDS =
  'bq_csv_account_executive_fields';
export const MULTI_SELECT_SF = 'multi_select_sf';
export const SNOWFLAKE_PULL_LIMIT = 'snowflake_pull_limits';
export const SALES_EDGE_ORG_UUID_TOKENS = 'sales_edge_org_uuid_tokens';
export const TEMP_PP_UNLIMITED_CUSTOM = 'pp_unlimited_custom_pops';
export const TEMP_LAST_ACTIVITY = 'temp_last_activity';
export const TEMP_TIMELINE_ATTRIBUTION_ACTIVITY =
  'temp_timeline_attribution_activity';
export const TEMP_EXCLUDE_OFFLINE_PARTNERS = 'temp_exclude_offline_partners';
export const TEMP_HAS_BAD_HUBSPOT_PUSH_AUTH =
  'temp_has_bad_hubspot_push_authentication';
export const TEMP_INTEGRATION_PROFILES_GLOWUP =
  'temp_integration_profiles_glowup';
export const TEMP_INTERNAL_TEST_ORG = 'temp_internal_test_org';
export const TEMP_EXEC_UP_SHARED_LIST_ROWS = 'temp_exec_up_shared_list_rows';
export const TEMP_REBUILT_DEBOOKING = 'temp_rebuilt_debooking';
export const TEMP_VAT_NUMBER = 'temp_vat_number';
export const TEMP_TEST_DEBOOKING = 'temp_test_debooking';
export const TEMP_PIPEDRIVE = 'temp_pipedrive';
export const TEMP_PIPEDRIVE_BETA = 'temp_pipedrive_beta';
export const TEMP_HUBSPOT_INTEGRATION_STATUS_TRACKING =
  'temp_hubspot_integration_status_tracking';
export const TEMP_DEAL_DASHBOARD = 'temp_deal_dashboard';
export const TEMP_GOOGLE_SHEETS_REBUILD = 'temp_google_sheets_rebuild';
export const TEMP_CSV_REBUILD = 'temp_csv_rebuild';
export const TEMP_RECORD_EXPORT_GLOWUP = 'temp_record_export_glowup';
export const TEMP_CHILIPIPER_ONBOARDING = 'temp_chilipiper_onboarding';
export const TEMP_REDESIGNED_FILTER_GROUPS = 'temp_redesigned_filter_groups';
export const TEMP_SELF_SERVE_IMPROVEMENTS = 'temp_self_serve_improvements';

// Launch Darkly flags
export const LD_BILLING_TOGGLE = 'ld_billing_toggle';

// homepage glowup A/B test
export const TEMP_DASHBOARD_GLOWUP = 'temp_dashboard_glowup';
export const HOMEPAGE_GLOWUP = 'glowup';
export const HOMEPAGE_CONTROL = 'control';

// new onboarding
export const NEW_ONBOARDING = 'new_onboarding';

// population filters a/b
export const LD_POPULATION_FILTER_MODAL = 'ld_population_filter_modal';
export const POPULATION_FILTER_MODAL = 'population_filter_modal';

// Leverage picklist options in report and population filters
export const TEMP_POPULATION_FILTER_WITH_PICKLIST_OPTIONS =
  'temp_population_filter_with_picklist_options';
export const TEMP_REPORT_FILTER_WITH_PICKLIST_OPTIONS =
  'temp_report_filter_with_picklist_options';

/**
 * PERMANENT FLAGS
 * These flags are not globally released, they are turned on a case-by-case basis.
 * They are typically used for individual paid features
 */
export const SSO = 'sso';
export const REST_API = 'rest_api';
export const SNOWFLAKE_PUSH = 'snowflake_push';
export const SALESFORCE_APP_PUSH = 'salesforce_app_push';
export const AUDIT_LOG = 'audit_log';
export const SALESFORCE_PUSH_CONFIG = 'salesforce_push_config';
export const ATTRIBUTION_ACCESS_OVERRIDE =
  'attribution_access'; /* Enterprise customers automatically get attribution */
export const PREVENT_DEFAULT_PARTNERSTACK_COL =
  'prevent_default_partnerstack_col'; /* does not add PartnerStack Column as a default in Reports/Shared Lists when enabled (requested for demo orgs) */
export const OVER_SEAT_QUOTA_RESTRICTED = 'over_seat_quota_restricted';
export const PARTNERSTACK_PARTNERSHIP_KEY = 'partnerstack_partnership_key';
export const DISABLE_SELF_SERVE_DEBOOKING = 'disable_self_serve_debooking';
export const LD_LATEST_BILLING_VERSION = 'latest_billing_version';
export const BIG_CSV_FILE_LIMIT_500MB = 'big_csv_file_limit_500MB';
export const SHARING_OVERRIDES_ENABLED = 'sharing_overrides_enabled';

// Partner cloud flags
export const PC_INTEGRATIONS = {
  HUBSPOT: 'pc_hubspot',
  HUBSPOT_V2: 'pc_hubspot_v2',
  TRAY: 'pc_tray',
  PARTNER_PORTAL: 'pc_partner_portal',
  TORCHLITE: 'pc_torchlite',
  ROLLWORKS: 'pc_rollworks',
  SALESFORCE_ATTRIBUTION_PUSH: 'pc_salesforce_attribution',
  MARKETO: 'pc_marketo',
  GONG: 'pc_gong',
  CLARI: 'pc_clari',
  DYNAMICS: 'pc_dynamics',
};

export const LD_TRACKING_KEYS = {
  NUMBER_OF_FIELDS: 'number-of-fields',
  ORG_PURCHASED_CONNECTOR: 'org-purchased-connector',
};

export const LD_VARIATIONS = {
  // [LD_ECOSYSTEM_INSIGHTS]: {
  //   ALL_SCORES_PLUS_PERCENT: 'all_scores_plus_percent',
  //   ALL_SCORES_PLUS_DECIMAL: 'all_scores_plus_decimal',
  //   FEWER_SCORES_PLUS_DECIMAL: 'fewer_scores_plus_decimal',
  //   FEWER_SCORES_PLUS_PERCENT: 'fewer_scores_plus_percent',
  // },
};

<template>
  <BittsCollapse
    :is-open="isCollapseOpen"
    class="c-billing-plan-comparison-tag"
    :show-underline="false"
    @change="onChange"
  >
    <template #header>
      <div class="flex flex-col">
        <p class="c-billing-plan-comparison-header-title">
          Compare with our Supernode plan</p
        >
        <p class="text-sm text-neutral-text">
          Build a scalable and predictable Ecosystem-Led Growth engine</p
        >
      </div>
    </template>
    <div v-if="isCollapseOpen" class="pb-24" />
    <div class="rounded-lg bg-white">
      <div class="p-12">
        <BillingPlanComparisonFeatureList tier="supernode" />
        <BittsButton
          text="Talk to Sales"
          :left-icon="['fad', 'magic-wand-sparkles']"
          type="upsell"
          size="x-small"
          @click="
            handleBillingInteraction({
              talkToSalesReason: 'Upgrade to Supernode',
              cta: BILLING_CTAS.BILLING,
              event_site: EVENT_SITES.SELF_SERVE_FLOW_COMPARISON_TAG,
            })
          "
        />
      </div>
    </div>
  </BittsCollapse>
</template>
<script setup lang="ts">
import { BittsButton, BittsCollapse } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { ref } from 'vue';

import BillingPlanComparisonFeatureList from '@/components/billing/BillingPlanComparisonFeatureList.vue';

import useBilling from '@/composables/useBilling';

const { handleBillingInteraction } = useBilling();

const isCollapseOpen = ref(false);
function onChange(isOpen: boolean) {
  isCollapseOpen.value = isOpen;
}
</script>
<style scoped lang="pcss">
.c-billing-plan-comparison-tag {
  @apply rounded-lg p-12;
  background: linear-gradient(
    90deg,
    theme(colors.denim.50 / 0.15) -10.62%,
    theme(colors.pink[600] / 0.08) 108.71%
  );
  .c-billing-plan-comparison-header-title {
    @apply text-base font-bold;
    background: linear-gradient(
      90deg,
      theme(colors.blue.400) 0.13%,
      theme(colors.pink.600/ 0.75) 24.57%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
<style lang="pcss">
.c-billing-plan-comparison-tag {
  .ant-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-expand-icon
    .ant-collapse-arrow {
    svg {
      color: theme(colors.neutral.accent) !important;
    }
  }
}
</style>

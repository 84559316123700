import { BILLING_TIERS } from '@/constants/billing';
import {
  PC_INTEGRATIONS,
  SALESFORCE_APP_PUSH,
  SNOWFLAKE_PUSH,
} from '@/constants/feature_flags';
import { IntegrationProfileType } from '@/types/integration_profiles';
import type { PCloudIntegration, TrayTag } from '@/types/integrations';

/**
  PCLOUD_INTEGRATIONS is a complete list of the integrations available for Crossbeam. These objects
  are processed and transformed to be the individual cards on the Integrations page. Some of the keys
  are marked as (required). Some of the keys logically go together (such as `isTray` and `trayTag`),
  that's not marked in this description.

  avatar (required): The icon displayed both in the available list and the installed list.
  beta: A fancy "Beta" icon next to the icon.
  description (required): The text displayed in the available list.
  ff: If the user doesn't have the feature flag, don't display the card as available.
  installLink: The Salesforce integration requires installing specifically at this link.
  isFrigg: Use the FriggCard component?
  isTray: Use the TrayCard component?
  learnMore: Link to the Crossbeam-specific marketing page for the integration.
  longDescription: Frigg only. Shows a longer display in the config display.
  name (required): The display name of the integration. This will also be the display name of the solution instance in tray
  tags: Array of "tag" objects. Currently only 3 used, listed below.
  tier: Minimum tier needed to install integration.
  trayTag: The tray project tag this integration will connect to.
  type: Slug of the integration title.
  unavailableWithoutFeatureFlag: Override other checks to only display if the user has the feature flag.
  */

const salesEdge = { text: 'Crossbeam for Sales', status: 'neutral' };

export const NOT_LIMITED = [
  'salesforce',
  'chrome',
  'slack',
  'outreach',
  'salesedge-hubspot',
  'tray_gong',
  'tray_salesforce_attribution',
];

export const MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE: Partial<
  Record<TrayTag, IntegrationProfileType>
> = {
  'xb-salesforce-overlaps-push': 'salesforce',
  'xb-hubspot': 'hubspot',
  'xb-clari': 'clari',
  'xb-microsoft-dynamics': 'microsoft_dynamics',
};

export const MIN_SUPPORTED_SALESFORCE_VERSION = 1.63;

export const PCLOUD_INTEGRATIONS: PCloudIntegration[] = [
  {
    name: 'Adobe Marketo Engage',
    isTray: true,
    type: 'tray_marketo',
    description:
      'Use partner data to create co-marketing campaigns in Adobe Marketo Engage',
    learnMore:
      'https://marketplace.crossbeam.com/partners/adobe-marketo-engage',
    avatar: 'AdobeMarketo.png',
    tier: BILLING_TIERS.ENTERPRISE,
    ff: PC_INTEGRATIONS.MARKETO,
    trayTag: 'xb-marketo',
  },
  {
    name: 'Crossbeam Copilot for Chrome',
    type: 'chrome',
    learnMore:
      'https://chrome.google.com/webstore/detail/crossbeam-account-mapping/helpjbehgodflkpbifaolbjmkjffmeop',
    description:
      'See existing & new Crossbeam partner overlaps while in your Chrome browser',
    avatar: 'Chrome.png',
    tier: BILLING_TIERS.FREE,
    recommended: true,
    settingsRoute: 'crossbeam_copilot_settings',
    tags: [salesEdge],
  },
  {
    name: 'Crossbeam Copilot for Outreach',
    type: 'outreach',
    learnMore: 'https://marketplace.outreach.io/apps/crossbeam-copilot',
    description:
      'Access proprietary Ecosystem Intelligence and insights in the tools you work from every day to close bigger deals, faster.',
    avatar: 'Outreach.png',
    tier: BILLING_TIERS.ENTERPRISE,
    recommended: true,
    tags: [salesEdge],
    settingsRoute: 'crossbeam_copilot_settings',
  },
  {
    name: 'Clari',
    isTray: true,
    type: 'tray_clari',
    description: 'Send Partner Data to Clari',
    learnMore:
      'https://marketplace.crossbeam.com/partners/clari-da99b3be-12f9-4a2f-9a94-a18a61aaec17',
    avatar: 'Clari.png',
    tier: BILLING_TIERS.CONNECTOR,
    ff: PC_INTEGRATIONS.CLARI,
    trayTag: 'xb-clari',
    beta: true,
    preventAutoEnable: true,
    unavailableWithoutFeatureFlag: true,
  },
  {
    name: 'Microsoft Dynamics Custom Object',
    isTray: true,
    type: 'tray_microsoft_dynamics',
    description: 'Push Crossbeam partner data directly into Microsoft Dynamics',
    learnMore:
      'https://marketplace.crossbeam.com/partners/microsoft-dynamics-custom-object',
    avatar: 'MSDynamics.png',
    tier: BILLING_TIERS.ENTERPRISE,
    trayTag: 'xb-microsoft-dynamics',
    beta: true,
    preventAutoEnable: true,
  },
  {
    name: 'Gong',
    isTray: true,
    type: 'tray_gong',
    description:
      'Add Crossbeam Copilot to Gong Engage and uncover partner mentions within calls in the Crossbeam Activity Timeline.',
    learnMore:
      'https://help.crossbeam.com/en/articles/8365716-gong-inbound-integration',
    avatar: 'Gong.png',
    tier: BILLING_TIERS.CONNECTOR,
    ff: PC_INTEGRATIONS.GONG,
    trayTag: 'xb-gong',
  },
  {
    name: 'HubSpot Custom Object (legacy)',
    learnMore: 'https://marketplace.crossbeam.com/partners/hubspot-outbound',
    isFrigg: true,
    type: 'hubspot',
    description:
      'Send Crossbeam partner data into HubSpot to target accounts in co-marketing campaigns',
    longDescription:
      'Automatically create Contact Lists from your selected Crossbeam reports for easy automated marketing to partner overlaps. For enterprise accounts, the Crossbeam Overlaps Custom Object allows you to powerfully extend the HubSpot for reporting, automation, and in-object viewing.',
    avatar: 'Hubspot.png',
    ff: PC_INTEGRATIONS.HUBSPOT,
    tier: BILLING_TIERS.ENTERPRISE,
    recommended: true,
  },
  {
    name: 'HubSpot Custom Object',
    isTray: true,
    type: 'tray_hubspot',
    description:
      'Send Crossbeam partner data into HubSpot to target accounts in co-marketing campaigns',
    learnMore: 'https://marketplace.crossbeam.com/partners/hubspot-outbound',
    longDescription:
      'Automatically create Contact Lists from your selected Crossbeam reports for easy automated marketing to partner overlaps. For enterprise accounts, the Crossbeam Overlaps Custom Object allows you to powerfully extend the HubSpot for reporting, automation, and in-object viewing.',
    avatar: 'Hubspot.png',
    ff: PC_INTEGRATIONS.HUBSPOT_V2,
    tier: BILLING_TIERS.ENTERPRISE,
    trayTag: 'xb-hubspot',
    recommended: true,
    preventAutoEnable: true,
  },
  {
    name: 'Crossbeam Copilot for HubSpot',
    type: 'salesedge-hubspot',
    description: 'Make partner data available directly in HubSpot',
    learnMore:
      'https://help.crossbeam.com/en/articles/8940278-crossbeam-hubspot-ecosystem-cards-beta',
    avatar: 'Hubspot.png',
    tier: BILLING_TIERS.CONNECTOR,
    recommended: true,
    tags: [salesEdge],
  },
  {
    name: 'LeanData',
    learnMore: 'https://marketplace.crossbeam.com/partners/leandata',
    type: 'leandata',
    description:
      'Put partner data in front of your revenue team and guide the right actions at every step of the buyer journey',
    avatar: 'LeanData.png',
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
  {
    name: 'Impartner',
    learnMore: 'https://marketplace.crossbeam.com/partners/impartner-prm',
    type: 'impartner',
    description:
      "Connect your partners' data in Crossbeam via Impartner's API connector tools for an enhanced deal registration process",
    avatar: 'Impartner.png',
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
  {
    name: 'Make',
    learnMore: 'https://marketplace.crossbeam.com/partners/make',
    type: 'make',
    description:
      'Build automated multi-step workflows between all your sales automation tools',
    avatar: 'Make.png',
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
  {
    name: 'PartnerPortal.io',
    learnMore: 'https://marketplace.crossbeam.com/partners/partnerportal-io',
    type: 'partner_portal',
    description:
      'Automatically monitor leads and pay partners with PartnerPortal.io',
    avatar: 'PartnerPortal.png',
    ff: PC_INTEGRATIONS.PARTNER_PORTAL,
    tier: BILLING_TIERS.CONNECTOR,
    requiresContactToInstall: true,
  },
  {
    name: 'PartnerStack',
    learnMore: 'https://marketplace.crossbeam.com/partners/partnerstack',
    type: 'partner_stack',
    description:
      'Refer leads directly from Crossbeam and track deals in PartnerStack',
    avatar: 'PartnerStackNew.png',
    tier: BILLING_TIERS.CONNECTOR,
  },
  {
    name: 'RollWorks',
    learnMore: 'https://marketplace.crossbeam.com/partners/rollworks',
    isFrigg: true,
    type: 'rollworks',
    description:
      'Use Crossbeam partner data in RollWorks to power your ABM strategy',
    avatar: 'Rollworks.png',
    ff: PC_INTEGRATIONS.ROLLWORKS,
    tier: BILLING_TIERS.CONNECTOR,
    unavailableWithoutFeatureFlag: true,
  },
  {
    name: 'Crossbeam Copilot for Salesforce',
    type: 'salesforce',
    learnMore: 'https://marketplace.crossbeam.com/partners/salesforce-widget',
    installLink:
      'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FvKuqUAF',
    description: 'Make partner data available directly in Salesforce',
    avatar: 'Salesforce.png',
    recommended: true,
    tier: BILLING_TIERS.FREE,
    tags: [salesEdge],
  },
  {
    name: 'Salesforce Custom Object',
    type: 'salesforce_v2',
    learnMore:
      'https://marketplace.crossbeam.com/partners/salesforce-custom-object',
    installLink:
      'https://appexchange.salesforce.com/listingDetail?listingId=a0N3A00000FvKuqUAF&tab=e',
    description:
      'Push insights from Crossbeam into Salesforce reports and dashboards with the custom object',
    avatar: 'Salesforce.png',
    ff: SALESFORCE_APP_PUSH,
    tier: BILLING_TIERS.ENTERPRISE,
    recommended: true,
  },
  {
    name: 'Salesforce Attribution Push',
    isTray: true,
    trayTag: 'xb-salesforce-attribution-push',
    type: 'tray_salesforce_attribution',
    learnMore:
      'https://marketplace.crossbeam.com/partners/salesforce-custom-object',
    description:
      'Connect any Salesforce IDs you have to record attribution in Crossbeam for Sales',
    avatar: 'Salesforce.png',
    ff: PC_INTEGRATIONS.SALESFORCE_ATTRIBUTION_PUSH,
    tier: BILLING_TIERS.ENTERPRISE,
    tags: [salesEdge],
  },
  {
    name: 'Salesforce Custom Object',
    isTray: true,
    trayTag: 'xb-salesforce-overlaps-push',
    type: 'tray_salesforce_push',
    learnMore:
      'https://marketplace.crossbeam.com/partners/salesforce-custom-object',
    description:
      'Push insights from Crossbeam into Salesforce reports and dashboards with the custom object',

    installLink:
      'https://appexchange.salesforce.com/listingDetail?listingId=a0N3A00000FvKuqUAF&tab=e',
    avatar: 'Salesforce.png',
    ff: SALESFORCE_APP_PUSH,
    tier: BILLING_TIERS.ENTERPRISE,
    recommended: true,
    preventAutoEnable: true,
  },
  {
    name: 'Slack',
    type: 'slack',
    learnMore: 'https://marketplace.crossbeam.com/partners/slack',
    description:
      'Query Crossbeam partner data with the /crossbeam Slack command and alert on new overlaps via public, private, or Slack Connect channels',
    avatar: 'Slack.png',
    tier: BILLING_TIERS.FREE,
    recommended: true,
  },
  {
    name: 'Snowflake Custom Object',
    type: 'snowflake',
    learnMore: 'https://marketplace.crossbeam.com/partners/snowflake',
    description:
      'Push partner data into Snowflake to create dashboards & augment your CRM',
    avatar: 'Snowflake.png',
    ff: SNOWFLAKE_PUSH,
    tier: BILLING_TIERS.ENTERPRISE,
    recommended: true,
    requiresContactToInstall: true,
  },
  {
    name: 'Superglue',
    learnMore: 'https://marketplace.crossbeam.com/partners/superglue',
    type: 'superglue',
    description: 'Generate more co-selling revenue, faster',
    avatar: 'Superglue.png',
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
  {
    name: 'Torchlite',
    learnMore:
      'https://marketplace.crossbeam.com/partners/send-crossbeam-data-to-torchlite',
    type: 'torchlite',
    description:
      'Pipe your partner data into Torchlite for centralized partner collaboration',
    avatar: 'Torchlite.png',
    ff: PC_INTEGRATIONS.TORCHLITE,
    tier: BILLING_TIERS.CONNECTOR,
    requiresContactToInstall: true,
  },
  {
    name: 'Tray.io',
    learnMore: 'https://marketplace.crossbeam.com/partners/tray-io',
    type: 'tray',
    description:
      'Integrate with Tray.io for automated marketing, sales, & customer success workflows',
    avatar: 'Trayio.png',
    ff: PC_INTEGRATIONS.TRAY,
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
  {
    name: 'Clay',
    learnMore: 'https://marketplace.crossbeam.com/partners/clay',
    type: 'clay',
    description:
      'The Clay + Crossbeam integration allows you to seamlessly import accounts based on overlaps with key partners, automatically crafting tailored outreach and syncing it to your sales engagement tools',
    avatar: 'Clay.png',
    tier: BILLING_TIERS.ENTERPRISE,
    requiresContactToInstall: true,
  },
];

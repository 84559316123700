<template>
  <div class="remove-seats-copy">
    <FontAwesomeIcon
      :icon="['fad', 'chair-office']"
      class="text-white w-72 h-72 mb-24"
    />
    <h2>Manage your team on Crossbeam</h2>
    <p
      >Remove seats or teammates that no longer need access, you can only do
      this in the 30 days before your renewal</p
    >
  </div>
</template>

<script setup></script>
<style lang="pcss" scoped>
.remove-seats-copy {
  @apply h-full px-40 py-32 lg:px-80 lg:pt-120;
  background: radial-gradient(
      41.02% 57.15% at 23.19% 83.74%,
      theme(colors.white / 0.3) 0%,
      theme(colors.white / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.white / 0.5) 0%,
      theme(colors.white / 0) 100%
    ),
    theme(colors.red.700);

  h2 {
    @apply text-xxl text-white font-bold;
  }

  p {
    @apply text-m text-white opacity-75 mt-8;
  }
}
</style>

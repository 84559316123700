<template>
  <div class="c-connector-card">
    <h3 class="title"> Plan Summary </h3>
    <BittsCallout
      v-if="debookingEnabled && !isSubscriptionCancelled"
      class="mb-16"
      title="You're in your renewal period"
      :subtitle="`You can remove paid seats or downgrade your account before your renewal date on ${subscriptionRenewalDate}`"
      type="info"
      size="small"
      :icon="['fad', 'file-contract']"
    />
    <BittsCallout
      v-else-if="
        (!onLatestBilling && hasSubscription) ||
        hasSupernodeTrayIntegrations ||
        hasSupernodeFriggIntegrations
      "
      title="You're on a legacy version of this plan"
      subtitle="Don't worry, we'll reach out before your plan renewal to let you know of any changes"
      data-testid="v3-migration-callout"
      size="small"
      class="my-16 mt-0"
      type="neutral"
      :icon="['fad', 'clock-rotate-left']"
    />
    <BittsCard class="mb-40 overflow-hidden">
      <div class="c-connector-card__card-content">
        <CurrentPlanCardSummary
          class="flex-1 rounded-b-none"
          color="info"
          tier="Connector"
          :start-date="billingTierCreatedDate"
        >
          <BittsLink
            class="mt-10"
            text="View billing & payment terms"
            url="https://www.crossbeam.com/legal/terms/payment-terms/"
            target="_blank"
          />
          <div
            v-if="canDebook && !isSubscriptionCancelled"
            class="flex items-center gap-16 mt-40"
          >
            <BittsButton
              v-if="onLatestBilling"
              size="small"
              text="Manage seats"
              data-testid="manage-seats-button"
              variant="outline"
              type="neutral"
              :disabled="!isAdmin"
              @click="handleRemoveSeatsClick"
            />
            <BittsButton
              data-testid="downgrade-button"
              size="small"
              text="Change plan"
              variant="outline"
              type="neutral"
              :disabled="!isAdmin"
              @click="handleDowngradeClick"
            />
          </div>
        </CurrentPlanCardSummary>
        <div
          v-if="!hasSubscription"
          class="payment-info"
          data-testid="sales-led-info"
        >
          <div class="flex justify-between mb-24">
            <div class="text-neutral-text-strong font-bold text-m">
              Payment Method
            </div>
            <div class="text-neutral-text-weak text-m"> ACH (•••••••••) </div>
          </div>
          <p class="text-neutral-text">
            You’re currently using order forms for your plan with us
          </p>
          <BittsButton
            size="small"
            text="Contact us"
            data-testid="connector-contact-us-button"
            variant="outline"
            type="neutral"
            class="mt-auto mr-auto"
            @click="
              handleBillingInteraction({
                talkToSalesReason: 'Sales Led Connector',
                event_site: EVENT_SITES.CONNECTOR_CARD_CONTACT_US,
              })
            "
          />
        </div>
        <PaymentInfo v-else data-testid="payment-info" class="payment-info" />
        <CostSummary
          v-if="showCostSummary"
          class="cost-summary"
          :core-seat-count="coreSeatLimit"
          :cost-per-core-seat="costPerCoreSeat"
          :core-seats-total-cost="coreSeatTotalCosts"
          :sales-seat-count="salesSeatLimit"
          :cost-per-sales-seat="costPerSalesSeat"
          :sales-seats-total-cost="salesSeatTotalCosts"
          :period="billingPeriod"
          :summary-total="grandTotal"
          summary-header="Total"
        />
      </div>
    </BittsCard>
  </div>
</template>

<script setup>
import {
  BittsButton,
  BittsCallout,
  BittsCard,
  BittsLink,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import CostSummary from '@/components/billing/CostSummary.vue';
import CurrentPlanCardSummary from '@/components/billing/CurrentPlanCardSummary.vue';
import PaymentInfo from '@/components/billing/PaymentInfo.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useHasFeature from '@/composables/useHasFeature';
import useSeats from '@/composables/useSeats';
import { TEMP_REBUILT_DEBOOKING } from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePartnerCloudStore,
} from '@/stores';
import { intercomOrEmailMessage } from '@/utils';

const billingStore = useBillingStore();
const partnerCloudStore = usePartnerCloudStore();
const featureFlagStore = useFeatureFlagStore();

const router = useRouter();
const { handleDowngrade, handleBillingInteraction } = useBilling();

const {
  hasSubscription,
  billingPeriod,
  grandTotal,
  billingTierCreatedDate,
  isSubscriptionCancelled,
  subscriptionRenewalDate,
} = storeToRefs(billingStore);

const { debookingEnabled, onLatestBilling } = useHasFeature();
const {
  coreSeatLimit,
  coreSeatTotalCosts,
  costPerCoreSeat,
  salesSeatLimit,
  salesSeatTotalCosts,
  costPerSalesSeat,
} = useSeats();
const discount = computed(() => !!billingStore.discount);
const showCostSummary = computed(
  () =>
    hasSubscription.value &&
    onLatestBilling.value &&
    !discount.value &&
    !isSubscriptionCancelled.value,
);

// For handling hubspot/marketo to supernode tier billing change
const hasSupernodeTrayIntegrations = computed(() =>
  partnerCloudStore.enabledTray.some((i) =>
    ['tray_hubspot', 'tray_marketo'].includes(i.type),
  ),
);
const hasSupernodeFriggIntegrations = computed(() =>
  partnerCloudStore.enabledFriggs.some((i) => i.type === 'hubspot'),
);

/* Downgrade */
const { currentOrg, currentAuth } = useAuth();
const isAdmin = computed(() => currentAuth.value.role.name === 'Admin');
const canDebook = computed(() => isAdmin.value && debookingEnabled.value);

function handleDowngradeClick() {
  if (featureFlagStore.hasFeatureFlag(TEMP_REBUILT_DEBOOKING))
    handleDowngrade(EVENT_SITES.MANAGE_PLAN_BUTTON);
  else {
    intercomOrEmailMessage(
      'sales@crossbeam.com',
      `Downgrade ${currentOrg.value.name}`,
      `Hello, I'd like to talk to someone about downgrading my account, ${currentOrg.value.name}`,
    );
  }
}

async function handleRemoveSeatsClick() {
  if (featureFlagStore.hasFeatureFlag(TEMP_REBUILT_DEBOOKING)) {
    await router.push({ name: 'remove-seats' });
  } else {
    intercomOrEmailMessage(
      'sales@crossbeam.com',
      `Downgrade ${currentOrg.value.name}`,
      `Hello, I'd like to talk to someone about removing seats from my account, ${currentOrg.value.name}`,
    );
  }
}
</script>

<style lang="pcss" scoped>
.c-connector-card {
  .c-connector-card__card-content {
    @apply flex flex-col flex-wrap lg:flex-row min-h-[400px] lg:min-h-[200px];
  }

  .title {
    @apply mb-16 text-neutral-text-strong text-lg font-bold;
  }

  .payment-info {
    @apply flex-1 border-neutral-border p-24 flex flex-col border-l;

    .item-header {
      @apply text-sm;
    }
  }

  .cost-summary {
    @apply border-t border-neutral-border p-24;
    flex: 1 1 100%;
  }
}
</style>

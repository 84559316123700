<template>
  <div class="c-map-column-selector">
    <div class="flex">
      <div class="c-map-new-columns-form__subtitle">
        {{ questionPrefix }} <b>{{ type }}</b
        >?
      </div>
      <span
        v-if="type !== ACCOUNT_OWNER_EMAIL_DISPLAY"
        class="c-map-new-columns-form__subtitle ml-auto text-neutral-accent"
      >
        Optional
      </span>
    </div>
    <component
      v-bind="isCsv ? { showCheckboxes: false } : null"
      :is="isCsv ? BittsMultiselect : BittsSelect"
      v-model="mapColumnSelectorValue"
      :mode="isCsv ? 'multiple' : 'default'"
      :disabled="disabled"
      :class="selectorClass"
      :options="unmappedColumns"
      :placeholder="placeholderText"
      :allow-clear="true"
      none-remaining-message="All columns mapped"
    />
  </div>
</template>

<script setup>
import { BittsMultiselect, BittsSelect } from '@crossbeam/bitts';

import { computed, ref, watch } from 'vue';

import {
  ACCOUNT_OWNER_DISPLAY,
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
} from '@/constants/mdm';

const props = defineProps({
  allColumns: {
    type: Array,
    required: true,
  },
  unmappedColumns: {
    type: Array,
    required: true,
  },
  fieldMappings: {
    type: Object,
    required: true,
  },
  type: {
    mappingType: String,
    required: true,
    validator: (value) =>
      [
        ACCOUNT_OWNER_NAME_DISPLAY,
        ACCOUNT_OWNER_EMAIL_DISPLAY,
        ACCOUNT_OWNER_PHONE_DISPLAY,
      ].includes(value),
  },
  isCsv: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update']);

const placeholderText = computed(() => {
  const typeName = props.type.split(' ').pop(); /* Phone, Name or Email */
  let placeholder = `Select ${ACCOUNT_OWNER_DISPLAY} ${typeName}`;
  if (props.isCsv) placeholder += 's';
  return placeholder;
});
const questionPrefix = computed(() =>
  props.isCsv ? 'Which columns contain' : 'Which column contains',
);
const selectorClass = computed(
  () =>
    `account-executive-${props.type.split(' ').at(-1).toLowerCase()}-selector`,
);
const mapColumnSelectorValue = ref(undefined);

// react to a new selected value.  The returned value isn't an array when the mode is set to 'multiple' so
// change it to an array if it isn't and the mode is 'multiple'
watch(mapColumnSelectorValue, (value) => {
  handleSelectedColumns(
    props.isCsv && !Array.isArray(value) ? [value] : value,
    props.type,
  );
});

/* We need to add the column to the list of selections, and also
remove it from the list of available selections so it can't be
selected again. We also need to, for Google Sheets, add _back_
the old selection to the list of available selections when
a new one is chosen, or when the selector is cleared */
function handleSelectedColumns(selection, type) {
  let newFieldMappings = {};
  let newUnmappedColumns = [];

  if (props.isCsv) {
    /* In CSV 'multiple' mode, the selection is an array of all selections */
    newFieldMappings = {
      ...props.fieldMappings,
      [type]: selection.map((c) => ({ label: c, value: c })),
    };

    newUnmappedColumns = props.allColumns.filter((item) => {
      for (const v of Object.values(newFieldMappings)) {
        const fields = v.map((v) => v.value);
        if (fields.includes(item.value)) return false;
      }
      return true;
    });
  } else {
    /* By default, the selection is the string selected */
    const oldVal = props.fieldMappings[type].at(-1);
    if (selection) {
      newFieldMappings = {
        ...props.fieldMappings,
        [type]: [{ label: selection, value: selection }],
      };
      newUnmappedColumns = props.unmappedColumns.filter(
        (col) => col.value !== selection,
      );
      if (oldVal) newUnmappedColumns.push(oldVal);
    } else if (oldVal) {
      /* If a clear event */
      newUnmappedColumns = [...props.unmappedColumns, oldVal];
      newFieldMappings = {
        ...props.fieldMappings,
        [type]: [],
      };
    }
  }

  emit('update', {
    unmappedColumns: newUnmappedColumns,
    fieldMappings: newFieldMappings,
  });
}
</script>

<style lang="pcss" scoped>
.c-map-new-columns-form__subtitle {
  @apply text-neutral-text-strong text-sm inline-block mb-8 leading-4;

  .bitts-tooltip {
    @apply inline;
  }
}
</style>

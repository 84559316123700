<template>
  <BittsCard
    class="c-billing-plan-card-updated w-1/2"
    :class="{
      'c-billing-plan-card-updated__connector': isConnectorTier,
      'c-billing-plan-card-updated__enterprise': isEnterpriseTier,
    }"
  >
    <div class="c-billing-plan-card-updated__highlight-tag">
      <BittsTag v-if="isConnectorTier" color="early-adopter" variant="rounded">
        <template #pre>
          <FontAwesomeIcon class="mr-4 h-12 w-12" :icon="['far', 'star']" />
        </template>
        <span>Recommended</span>
      </BittsTag>
    </div>
    <div class="flex flex-col p-24 gap-24 h-full">
      <div>
        <p
          class="pb-8 font-bold text-lg c-billing-plan-card-updated__tier-title"
        >
          {{ capitalize(tier) }}
        </p>
        <p class="text-neutral-text-weak text-base">
          {{ tierDescription.line1 }}
        </p>
        <p class="text-neutral-text-weak text-base">
          {{ tierDescription.line2 }}
        </p>
      </div>

      <div>
        <p class="text-neutral-text-strong font-bold text-xxl">
          {{ corePrice }}
          <span
            v-if="isConnectorTier"
            class="text-neutral-text-weak text-base font-normal"
          >
            per seat/month
          </span>
        </p>
        <p v-if="isEnterpriseTier">for 10 seats minimum</p>
        <p v-else-if="isConnectorTier" class="text-success-text"
          >or {{ salesPrice }} per seat/month for Sales seats</p
        >
      </div>

      <BittsButton
        :text="buttonText"
        :type="buttonType"
        size="medium"
        :left-icon="
          isEnterpriseTier ? ['fad', 'wand-magic-sparkles'] : undefined
        "
        class="self-start"
        @click="handleButtonClick"
      />

      <BillingPlanComparisonFeatureList :tier :comparison-modal="true" />
    </div>
  </BittsCard>
</template>

<script setup lang="ts">
import { BittsButton, BittsCard, BittsTag } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { computed } from 'vue';

import BillingPlanComparisonFeatureList from '@/components/billing/BillingPlanComparisonFeatureList.vue';

import { FULL_ACCESS, SALES_ONLY, SEAT_COSTS } from '@/constants/billing';
import { centsToDollars } from '@/utils';

interface Props {
  tier: string;
  period: 'month' | 'year';
}

const props = defineProps<Props>();

const emit = defineEmits<(e: 'action') => void>();

const isConnectorTier = computed(() => props.tier === 'connector');
const isEnterpriseTier = computed(() => props.tier === 'supernode');

const buttonText = computed(() => {
  if (isEnterpriseTier.value) return 'Talk to Sales';
  return 'Upgrade to Connector';
});

const buttonType = computed(() => {
  if (isEnterpriseTier.value) return 'upsell';
  return 'primary';
});

const tierDescription = computed(() => {
  if (isEnterpriseTier.value)
    return {
      line1: 'Build a scalable and predictable Ecosystem-Led-Growth engine',
      line2: 'For medium team and large teams',
    };
  return {
    line1: 'Activate your partner ecosystem & boost GTM efficiencies',
    line2: 'For individuals and small teams',
  };
});

const corePrice = computed(() => {
  if (isEnterpriseTier.value) return 'Custom';
  return centsToDollars(
    SEAT_COSTS[FULL_ACCESS][props.period] / (props.period === 'year' ? 12 : 1),
  );
});

const salesPrice = computed(() => {
  if (isEnterpriseTier.value) return null;
  return centsToDollars(
    SEAT_COSTS[SALES_ONLY][props.period] / (props.period === 'year' ? 12 : 1),
  );
});

function handleButtonClick() {
  emit('action');
}
</script>

<style scoped lang="pcss">
.c-billing-plan-card-updated {
  @apply relative;
  &.c-billing-plan-card-updated__connector {
    @apply flex border border-solid border-teal-400 bg-info-background-weak/25;
    .c-billing-plan-card-updated__highlight-tag {
      @apply w-full left-24 absolute top-[-12px];
    }
    .c-billing-plan-card-updated__tier-title {
      @apply text-neutral-text-strong;
    }
  }
  &.c-billing-plan-card-updated__enterprise {
    @apply border border-solid border-denim-400;
    .c-billing-plan-card-updated__tier-title {
      background: linear-gradient(
        90deg,
        theme(colors.denim.400) 0.13%,
        theme(colors.pink.600/ 0.75) 24.57%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>

<template>
  <BittsTooltip
    v-if="displayPermission.level != 'manage'"
    class="c-permission-indicator"
  >
    <slot>
      <div
        class="border-2 border-neutral-100 rounded text-sm py-6 px-16 text-brand-navy flex flex-none"
        data-testid="permission-indicator-copy"
      >
        {{ displayPermission.displayLevel }}
        <FontAwesomeIcon
          :icon="['far', 'question-circle']"
          :style="{ height: '14px', width: '14px', color: 'currentColor' }"
          class="text-neutral-400 mt-2 ml-4 cursor-pointer"
        />
      </div>
    </slot>
    <template #title>
      {{ displayPermission.description }}
    </template>
  </BittsTooltip>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import useAuth from '@/composables/useAuth';

const DISPLAY_PERMISSIONS = {
  manage: {
    displayLevel: 'Manage',
    description: null,
  },
  participate: {
    displayLevel: 'Participate only',
    description:
      'You have "participate only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
  view: {
    displayLevel: 'View only',
    description:
      'You have "view only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
  'view-only': {
    displayLevel: 'View only',
    description:
      'You have "view only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
};

export default {
  name: 'PermissionIndicator',
  components: {
    BittsTooltip,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { hasPermission, resourcePermission } = useAuth();

    return { hasPermission, resourcePermission };
  },
  computed: {
    manager() {
      const permission = `manage:${this.resource}`;
      return this.hasPermission(permission);
    },
    displayPermission() {
      const permission = this.resourcePermission(this.resource);
      return {
        level: permission.level,
        displayLevel: DISPLAY_PERMISSIONS[permission.level].displayLevel,
        description: DISPLAY_PERMISSIONS[permission.level].description,
      };
    },
  },
};
</script>

<style lang="pcss">
.c-permission-indicator {
  @apply cursor-pointer;
}
</style>

<template>
  <BittsLoading :class="{ 'pb-36': loading }" :is-loading="loading">
    <div class="c-file-upload-form">
      <BittsTabs
        :current-value="activeTab"
        :tabs="fileUploadTabs"
        @change:tab="activeTabChanged"
      />
      <div v-show="uploadTabActive" class="c-file-upload-form__tab-content">
        <div class="mb-24">
          <VuelidateWrapper
            data-testid="file-input-vuelidate-wrapper"
            property="file"
            :errors="v$.$errors"
          >
            <FileInput ref="fileInput" @file-chosen="fileChosenHandler" />
          </VuelidateWrapper>
        </div>
        <BittsInput
          v-if="!sourceId"
          v-model.trim="tableName"
          form-label="CSV Name"
          data-testid="file-upload-input"
          :disabled="fileNameDisabled"
          :status="v$.tableName.$errors.length ? 'danger' : 'default'"
          :danger-text="v$.tableName.$errors?.at(-1)?.$message || ''"
          placeholder="Name your CSV"
          name="file_name"
          caption="Note: This name will be shared with partners when you share data"
          @update:model-value="() => v$.$touch()"
        />
        <BittsCallout
          v-if="sourceId"
          class="mt-16"
          title="Adding data to a CSV merges these datasets"
          size="small"
          subtitle="This action can't be undone, make sure you are sure"
          type="warning"
        />
        <div
          v-if="!sourceId"
          class="c-file-upload-form__row--no-margin mb-24"
          :class="{
            'mt-24': !offlinePartnerUuid,
          }"
        >
          <component
            :is="offlinePartnerUuid ? 'BittsTooltip' : 'div'"
            overlay-class="pt-24"
            :mount-to-body="true"
            placement="right"
          >
            <BittsRadioGroupCards
              v-if="!sourceId"
              form-label="What type of data is this?"
              :options="uploadTypes"
              :disabled="!!offlinePartnerUuid"
              class="w-full"
              :class="{
                'opacity-50': offlinePartnerUuid,
              }"
              orientation="horizontal"
              :initial-value="selectedUploadType"
              @change="handleUploadType"
            />
            <template #title>
              Offline partners only support company data
            </template>
          </component>
        </div>
      </div>
      <div v-show="!uploadTabActive" class="c-file-upload-form__tab-content">
        <BittsCard class="px-16 pt-16">
          <div v-if="showAccountFields" class="flex flex-col">
            <div class="c-file-upload-form__field">
              <div class="c-file-upload-form__field__label">
                <span>Company Name</span>
                <FontAwesomeIcon
                  :icon="['fas', 'chevron-right']"
                  class="text-neutral-accent"
                />
              </div>
              <VuelidateWrapper
                v-if="showAccountFields"
                class="w-[280px]"
                property="selectedNameField"
                :errors="v$.$errors"
              >
                <BittsSelect
                  v-model="selectedNameField"
                  :allow-clear="true"
                  :searchable="true"
                  :options="availableFields"
                  class="w-full"
                  data-testid="name-selector"
                  placeholder="Select CSV Column"
                />
              </VuelidateWrapper>
            </div>
            <div class="c-file-upload-form__field">
              <div class="c-file-upload-form__field__label">
                <div class="flex items-center gap-8">
                  <span>Website</span>
                  <BittsTag size="x-small" variant="rounded" color="info">
                    Recommended
                  </BittsTag>
                </div>
                <FontAwesomeIcon
                  :icon="['fas', 'chevron-right']"
                  class="text-neutral-accent"
                />
              </div>
              <BittsSelect
                v-if="showAccountFields"
                v-model="selectedWebsiteField"
                class="w-[280px]"
                :allow-clear="true"
                :options="availableFields"
                placeholder="Select CSV Column"
              />
            </div>
          </div>
          <div v-if="showLeadFields" class="c-file-upload-form__field">
            <div class="c-file-upload-form__field__label">
              <span>Email</span>
              <FontAwesomeIcon
                :icon="['fas', 'chevron-right']"
                class="text-neutral-accent"
              />
            </div>
            <VuelidateWrapper
              property="selectedEmailField"
              :errors="v$.$errors"
            >
              <BittsSelect
                v-model="selectedEmailField"
                class="w-[280px]"
                :allow-clear="true"
                :options="availableFields"
                :close-on-select="true"
                placeholder="Select CSV Column"
              />
            </VuelidateWrapper>
          </div>
        </BittsCard>
        <BittsCard class="p-16 mt-24 mb-16">
          <div
            class="flex items-center justify-between"
            :class="{
              'mb-16': showAeFields,
            }"
          >
            <div class="flex-1">
              <div class="text-neutral-text-strong text-base font-bold">
                Map {{ ACCOUNT_OWNER_DISPLAY }}
              </div>
              <p class="text-sm text-neutral-text">
                Unlock notifications for your go to market and sales team
              </p>
            </div>
            <div class="flex flex-col items-center gap-4 min-w-[60px]">
              <BittsSwitch
                id="show-ae-fields-toggle"
                v-model="showAeFields"
                @change="toggleAeMapping"
              />
            </div>
          </div>
          <div v-if="showAeFields">
            <div class="c-file-upload-form__field">
              <div class="c-file-upload-form__field__label">
                <span>Email</span>
                <FontAwesomeIcon
                  :icon="['fas', 'chevron-right']"
                  class="text-neutral-accent"
                />
              </div>
              <VuelidateWrapper
                property="selectedAEEmailField"
                class="w-[280px]"
                :errors="v$.$errors"
              >
                <BittsSelect
                  v-model="selectedAEEmailField"
                  :allow-clear="true"
                  :options="availableFields"
                  placeholder="Select Email Column"
                  data-testid="ae-email-selector"
                />
              </VuelidateWrapper>
            </div>
            <div class="c-file-upload-form__field">
              <div class="c-file-upload-form__field__label">
                <div class="flex items-center gap-8">
                  <span>Name</span>
                  <BittsTag size="x-small" variant="rounded">
                    Optional
                  </BittsTag>
                </div>
                <FontAwesomeIcon
                  :icon="['fas', 'chevron-right']"
                  class="text-neutral-accent"
                />
              </div>
              <BittsSelect
                v-model="selectedAENameField"
                class="w-[280px]"
                :allow-clear="true"
                :options="availableFields"
                placeholder="Select Name Column"
                data-testid="ae-name-selector"
              />
            </div>
            <div class="c-file-upload-form__field">
              <div class="c-file-upload-form__field__label">
                <div class="flex items-center gap-8">
                  <span>Phone</span>
                  <BittsTag size="x-small" variant="rounded">
                    Optional
                  </BittsTag>
                </div>
                <FontAwesomeIcon
                  :icon="['fas', 'chevron-right']"
                  class="text-neutral-accent"
                />
              </div>
              <BittsSelect
                v-model="selectedAEPhoneField"
                class="w-[280px]"
                :allow-clear="true"
                :options="availableFields"
                placeholder="Select Phone Column"
                data-testid="ae-phone-selector"
              />
            </div>
          </div>
        </BittsCard>
      </div>
      <div v-if="submissionError" class="w-full px-24 mb-24">
        <BittsAlert
          :description="submissionError"
          color="error"
          message="Error"
        />
      </div>
      <div class="c-file-upload-form__footer">
        <BittsButton
          text="Cancel"
          size="large"
          type="neutral"
          variant="outline"
          @click="cancel"
        />
        <BittsButton
          v-if="uploadTabActive"
          :disabled="!tab1complete || v$.$errors.length > 0"
          data-testid="next-button"
          size="large"
          text="Next"
          @click="nextTab"
        />
        <BittsButton
          v-else
          :disabled="disableSubmit"
          data-testid="upload-csv-button"
          text="Upload"
          size="large"
          @click="uploadFile"
        />
      </div>
    </div>
  </BittsLoading>
</template>

<script>
import {
  BittsAlert,
  BittsButton,
  BittsCallout,
  BittsCard,
  BittsInput,
  BittsLoading,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSwitch,
  BittsTabs,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import {
  helpers,
  maxLength,
  required,
  requiredIf,
} from '@vuelidate/validators';
import axios from 'axios';
import { mapState } from 'pinia';
import { computed, inject, ref } from 'vue';
import { useRoute } from 'vue-router';

import FileInput from '@/components/data-sources/FileInput.vue';
import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import useCSV from '@/composables/useCSV';
import {
  ACCOUNT_OWNER_DISPLAY,
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
  AO_DISPLAY,
} from '@/constants/mdm';
import { captureException } from '@/errors';
import { useFileUploadsStore, useSourcesStore } from '@/stores';
import urls from '@/urls';

const alphaNum = (value) => Boolean(!value || value.match(/^[a-zA-Z0-9_ ]+$/));
const ACCOUNT_SOURCE = 'account';
const LEAD_SOURCE = 'lead';

const MAP_COLUMNS_TAB = 'map_columns';
const ADD_FILE_TAB = 'add_file';

export default {
  name: 'FileUploadForm',
  components: {
    BittsCard,
    BittsCallout,
    VuelidateWrapper,
    BittsTag,
    BittsAlert,
    FileInput,
    BittsButton,
    BittsLoading,
    BittsInput,
    BittsSelect,
    BittsRadioGroupCards,
    BittsTabs,
    BittsSwitch,
    BittsTooltip,
  },
  props: {
    sourceId: {
      type: Number,
      default: null,
    },
  },
  emits: ['success', 'cancel', 'error', 'file-uploaded'],
  setup(props) {
    const file = ref(null);
    const selectedAEEmailField = ref(null);
    const selectedAENameField = ref(null);
    const selectedAEPhoneField = ref(null);
    const selectedEmailField = ref(null);
    const selectedNameField = ref(null);
    const selectedUploadType = ref(null);
    const tableName = ref(null);

    const offlineSources = inject('offlineSources', null);
    const offlineCurrentUploadNames = inject('offlineCurrentUploadNames', null);
    const refreshOfflineData = inject('refreshOfflineData', null);

    const { getSourceById } = useSourcesStore();

    const { maxFileUploadLimit } = useCSV();

    const route = useRoute();
    const offlinePartnerUuid = route.query?.offlinePartnerUuid;

    if (!offlinePartnerUuid && props.sourceId) {
      const source = getSourceById(props.sourceId);
      tableName.value = source.table;
      selectedUploadType.value = source.mdm_type;
    } else {
      selectedUploadType.value = ACCOUNT_SOURCE;
    }

    const activeTab = ref(ADD_FILE_TAB);
    const uploadTabActive = computed(() => activeTab.value === ADD_FILE_TAB);

    const rules = {
      file: {
        required: helpers.withMessage('A file is required', required),
        fileSize: helpers.withMessage(
          `Maximum file size ${maxFileUploadLimit.value} MB exceeded`,
          (value) => {
            if (!value) return true;
            return !(
              Math.round(value.size / 1000000) > maxFileUploadLimit.value
            );
          },
        ),
      },

      selectedAEEmailField: {
        required: helpers.withMessage(
          `Please choose an option when any other ${ACCOUNT_OWNER_DISPLAY}  field is mapped`,
          requiredIf(() => {
            if (uploadTabActive.value) return null;
            return selectedAENameField.value || selectedAEPhoneField.value;
          }),
        ),
      },
      selectedEmailField: {
        required: helpers.withMessage(
          'Please choose an option',
          requiredIf(() => {
            if (uploadTabActive.value) return null;
            return selectedUploadType.value === LEAD_SOURCE;
          }),
        ),
      },
      selectedNameField: {
        required: helpers.withMessage(
          'Please choose an option',
          requiredIf(() => {
            if (uploadTabActive.value) return null;
            return selectedUploadType.value === ACCOUNT_SOURCE;
          }),
        ),
      },
      tableName: {
        required: helpers.withMessage('A file name is required', required),
        alphaNum: helpers.withMessage(
          'Only letters, numbers, spaces, and underscores are allowed',
          alphaNum,
        ),
        maxLength: helpers.withMessage(
          'Must be no more than 60 characters',
          maxLength(60),
        ),
        isUnique: helpers.withMessage(
          'CSV file name must be unique',
          (value, _, vm) => {
            if (vm.sourceId) return true;
            const name = offlinePartnerUuid
              ? vm.offlineCurrentUploadNames.includes(value)
              : vm.getByCSVName(value || '');
            return Boolean(!name);
          },
        ),
      },
    };

    const vuelidateState = {
      tableName,
      file,
      selectedNameField,
      selectedEmailField,
      selectedAEEmailField,
    };

    const v$ = useVuelidate(rules, vuelidateState, { $lazy: true });
    return {
      file,
      offlinePartnerUuid,
      selectedAENameField,
      selectedAEPhoneField,
      selectedAEEmailField,
      selectedEmailField,
      selectedNameField,
      selectedUploadType,
      tableName,
      uploadTabActive,
      activeTab,
      offlineSources,
      v$,
      refreshOfflineData,
      offlineCurrentUploadNames,
      ACCOUNT_OWNER_DISPLAY,
      AO_DISPLAY,
      ACCOUNT_OWNER_NAME_DISPLAY,
      ACCOUNT_OWNER_EMAIL_DISPLAY,
      ACCOUNT_OWNER_PHONE_DISPLAY,
      maxFileUploadLimit,
    };
  },
  data() {
    return {
      fileHeaders: [],
      parseErrors: [],
      submissionError: null,
      errorText:
        'We ran into an error processing your file. Please try again, or contact support.',
      selectedWebsiteField: null,
      loading: true,
      showAeFields: false,
      uploadTypes: [
        {
          value: ACCOUNT_SOURCE,
          label: 'Companies',
          description: null,
        },
        {
          value: LEAD_SOURCE,
          label: 'People',
          description: null,
        },
      ],
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    ...mapState(useFileUploadsStore, ['getByCSVName']),
    showAccountFields() {
      return (
        this.fileHeaders.length > 0 &&
        this.selectedUploadType === ACCOUNT_SOURCE
      );
    },
    showLeadFields() {
      return (
        this.fileHeaders.length > 0 && this.selectedUploadType === LEAD_SOURCE
      );
    },
    templateUrl() {
      if (this.selectedUploadType === ACCOUNT_SOURCE) {
        return 'https://s3.amazonaws.com/assets.crossbeam.com/companies_template.csv';
      }
      return 'https://s3.amazonaws.com/assets.crossbeam.com/people_template.csv';
    },
    gSheetsHelpUrl() {
      return 'https://help.crossbeam.com/en/articles/5613345-getting-started-with-google-sheets-as-a-data-source';
    },
    tab1complete() {
      return this.fileHeaders.length > 0 && this.tableName;
    },
    disabledTabs() {
      return this.tab1complete ? [] : [2];
    },
    fileNameDisabled() {
      return !!this.sourceId || !this.file;
    },
    fileNameLabel() {
      return this.sourceId ? 'Which file are you adding data to?' : 'File Name';
    },
    selectedColumns() {
      return [
        this.selectedWebsiteField,
        this.selectedEmailField,
        this.selectedNameField,
        this.selectedAEEmailField,
        this.selectedAENameField,
        this.selectedAEPhoneField,
      ];
    },
    availableFields() {
      return this.fileHeaders
        .filter((col) => !this.selectedColumns.includes(col.value))
        .sort();
    },
    requiredAeEmailMissing() {
      if (!this.showAeFields) return false;
      if (Array.isArray(this.selectedAEEmailField))
        return !this.selectedAEEmailField.length;
      return !this.selectedAEEmailField;
    },
    fileUploadTabs() {
      return [
        {
          name: 'Add File',
          icon: [this.uploadTabActive ? 'fas' : 'far', 'circle-1'],
          value: ADD_FILE_TAB,
        },
        {
          name: 'Map Columns',
          icon: [!this.uploadTabActive ? 'fas' : 'far', 'circle-2'],
          value: MAP_COLUMNS_TAB,
        },
      ];
    },
    disableSubmit() {
      return (
        this.requiredAeEmailMissing ||
        (this.showLeadFields
          ? !this.selectedEmailField
          : !this.selectedNameField)
      );
    },
  },
  async created() {
    const offlineUuid = this.$route.query?.offlinePartnerUuid;
    if (offlineUuid) {
      await this.refreshOfflineData(offlineUuid);
      const source = this.offlineSources.find(
        (s) => s.id === Number(this.$route.params?.id),
      );
      if (source) {
        this.tableName = source.table;
        this.selectedUploadType = source.mdm_type;
      }
    }
    this.loading = false;
  },
  methods: {
    getChosenField(selection) {
      if (Array.isArray(selection) && !selection.length) return null;
      return selection;
    },
    getErrorText(err, defaultText) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.length > 0
      ) {
        return err.response.data.errors[0];
      }
      return defaultText;
    },
    setTableName(name) {
      this.tableName = name;
    },
    async fileChosenHandler(event_) {
      this.submissionError = null;
      this.v$.file.$touch();

      if (event_.target.files.length === 1) {
        this.$emit('file-uploaded');
        this.file = event_.target.files[0];
        if (Math.round(this.file.size / 1000000) <= this.maxFileUploadLimit) {
          // take first 100kb of the uploaded file
          const fileSample = this.file.slice(0, 102400);
          const data = new FormData();
          data.append('file', fileSample);
          try {
            const fileOptionsUrl = this.offlinePartnerUuid
              ? `${urls.fileUploads.fileOptions}?offline_partner_org_uuid=${this.offlinePartnerUuid}`
              : urls.fileUploads.fileOptions;
            const response = await axios.post(fileOptionsUrl, data);
            this.fileOptions = response.data;
            this.fileHeaders = response.data.headers.map((header) => {
              return { value: header, label: header };
            });
            if (!this.fileHeaders || this.fileHeaders.length === 0) {
              this.setError(
                'You need a header row and at least 1 row of data to upload',
              );
            }

            if (!this.sourceId) {
              let fileName = this.file.name
                .split('.')
                .slice(0, -1)
                .join('.')
                .replace(/ /g, '_');
              fileName = fileName
                .replace(/-/g, '_')
                .replace(/[^a-z0-9]/gim, '')
                .replace(/\s+/g, '');
              this.setTableName(fileName);
            }
          } catch (err) {
            captureException(err);
            this.setError(this.getErrorText(err, this.errorText));
            await this.reset();
          }
        }
      }
    },
    setError(error) {
      this.submissionError = error;
      this.loading = false;
      this.$emit('error', { errorText: error });
    },
    reset() {
      this.file = null;
      this.fileOptions = null;
      this.tableName = null;
      this.selectedNameField = null;
      this.selectedAENameField = null;
      this.selectedAEEmailField = null;
      this.selectedAEPhoneField = null;
      this.selectedWebsiteField = null;
      this.selectedEmailField = null;
      this.selectedUploadType = ACCOUNT_SOURCE;
      this.fileHeaders = [];
      this.loading = false;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.reset();
      }
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.clear();
      }

      this.v$.$reset();
    },
    cancel() {
      this.$emit('cancel');
    },
    findTabWithError(vuelidate) {
      // figures out which tab is causing the vuelidate error
      // if any error is found on Add File Tab, we can direct the user there
      if (vuelidate.file.$error || vuelidate.tableName.$error) {
        return ADD_FILE_TAB;
        // the only things that can error on Add File are file and tableName
      }
      return MAP_COLUMNS_TAB;
    },
    nextTab() {
      this.activeTab = MAP_COLUMNS_TAB;
    },
    async uploadFile() {
      this.submissionError = null;
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.activeTab = this.findTabWithError(this.v$);
        return;
      }
      this.loading = true;

      /* Build Payload */
      const fieldMappings = {};
      if (this.selectedUploadType === ACCOUNT_SOURCE) {
        fieldMappings[this.selectedNameField] = 'Company Name';
        if (
          this.selectedWebsiteField &&
          typeof this.selectedWebsiteField === 'string'
        ) {
          fieldMappings[this.selectedWebsiteField] = 'Company Website';
        }
      } else {
        fieldMappings[this.selectedEmailField] = 'Email';
      }

      /* We only include AE data when the toggle is "ON" */
      if (this.showAeFields) {
        if (this.selectedAENameField) {
          fieldMappings[this.selectedAENameField] =
            this.ACCOUNT_OWNER_NAME_DISPLAY;
        }
        if (this.selectedAEEmailField) {
          fieldMappings[this.selectedAEEmailField] =
            this.ACCOUNT_OWNER_EMAIL_DISPLAY;
        }
        if (this.selectedAEPhoneField) {
          fieldMappings[this.selectedAEPhoneField] =
            this.ACCOUNT_OWNER_PHONE_DISPLAY;
        }
      }

      /* Otherwise, upload the data */
      try {
        const baseDetailsPayload = {
          table_name: this.tableName.trim(),
          mdm_type: this.selectedUploadType,
          file_name: this.file.name,
          separator: this.fileOptions.separator,
          quote: this.fileOptions.quote,
          field_mappings: fieldMappings,
        };

        const detailsUrl = urls.fileUploads.jsonUploadV3;
        if (this.offlinePartnerUuid) {
          baseDetailsPayload.offline_partner_org_uuid = this.offlinePartnerUuid;
        }

        const upload = await axios.post(detailsUrl, baseDetailsPayload);
        const uploadId = upload.data.id;
        const uploadUrl = upload.data.upload_url;

        await axios.put(uploadUrl, this.file, {
          withCredentials: false,
          headers: { 'Content-Type': this.file.type },
          transformRequest: (data, headers) => {
            delete headers['XBeam-Organization'];
            return data;
          },
        });

        if (this.offlinePartnerUuid) {
          await axios.post(urls.fileUploads.processFileUpload(uploadId), {
            offline_partner_org_uuid: this.offlinePartnerUuid,
          });
        } else {
          await axios.post(urls.fileUploads.processFileUpload(uploadId), {});
        }

        this.$emit('success', { tableName: this.tableName });

        if (this.offlinePartnerUuid)
          await this.refreshOfflineData(this.offlinePartnerUuid);
      } catch (err) {
        captureException(err);
        this.setError(this.getErrorText(err, this.errorText));
      }
    },
    activeTabChanged(val) {
      if (!this.tab1complete && val === MAP_COLUMNS_TAB) return;
      this.activeTab = val;
    },
    toggleAeMapping() {
      this.selectedAENameField = null;
      this.selectedAEEmailField = null;
      this.selectedAEPhoneField = null;
    },
    handleUploadType(val) {
      this.selectedUploadType = val;
    },
  },
};
</script>
<style lang="pcss">
.c-file-upload-form {
  @apply mt-24 w-full;

  .c-file-upload-form__tab-content {
    @apply px-24 pt-24 mb-24;
  }

  .bitts-radio-group-cards__option {
    @apply w-full;
  }

  .c-file-upload-form__footer {
    @apply flex justify-between items-center w-full pb-24 pt-16 px-24 border-t border-neutral-border;
  }
}

.c-file-upload-form__link {
  @apply text-brand-blue;
  &:hover {
    @apply no-underline;
  }
}

.c-file-upload-form__row--note {
  @apply text-neutral-500 text-sm;
}

.c-file-upload-form__field {
  @apply flex items-center justify-between gap-24 mb-16;
  .c-file-upload-form__field__label {
    @apply flex justify-between items-center flex-1;
  }
}

.c-file-upload-form__subtitle {
  @apply text-neutral-text-strong text-sm inline-block mb-8;
}

.c-file-upload-form__ae-mapping {
  @apply mt-16 p-16 flex flex-col gap-16 border border-neutral-300 rounded-8;
}

.c-file-upload-form
  .bitts-input-select
  .multiselect--disabled
  .multiselect__tags {
  @apply bg-neutral-border border border-neutral-400;
}

.c-file-upload-form__confirmation {
  @apply pb-24 px-24;
}
</style>

<template>
  <div class="c-team-table-cell-wrapper c-team-table-seat-cell">
    <BittsTag v-if="activeRole !== NO_ACCESS_ROLE" variant="rounded">
      <template #pre>
        <BittsSvg class="w-12 h-12" svg="logoNoText" />
      </template>
      <span> Full Access </span>
    </BittsTag>
    <BittsTag v-else color="success" variant="rounded">
      <template #pre>
        <FontAwesomeIcon class="w-12 h-12" :icon="['fak', 'sales']" />
      </template>
      <span> Sales </span>
    </BittsTag>
  </div>
</template>

<script setup>
import { BittsSvg, BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';

import { NO_ACCESS_ROLE } from '@/constants/team';
import { useRolesStore } from '@/stores';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const rolesStore = useRolesStore();

const authOrInvite = computed(() => props.params.data);
const currentRole = computed(() =>
  rolesStore.getRoleById(authOrInvite.value?.role_id),
);
const activeRole = computed(() => currentRole.value ?? NO_ACCESS_ROLE);
</script>

<style lang="pcss">
.c-team-table-seat-cell {
  .bitts-tag .ant-tag {
    @apply flex items-center gap-8;
  }
}
</style>

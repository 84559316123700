<template>
  <BittsEmptyState
    :title
    :font-awesome-icon="pngName ? null : ['fad', 'wand-magic-sparkles']"
    :font-awesome-icon-class="pngName ? '' : 'text-upsell-accent'"
    :font-awesome-icon-style="{
      height: '40px',
      width: '40px',
      color: 'currentColor',
    }"
    :png-name="pngName"
    png-width="424px"
    class="upsell-overlay"
  >
    <template #subtitle>
      <div class="text-neutral-text">
        {{ text }}
      </div>
      <BittsButton
        :left-icon="['fas', 'wand-magic-sparkles']"
        type="upsell"
        size="small"
        text="Upgrade"
        class="mt-16"
        @click="onCtaClicked"
      />
    </template>
  </BittsEmptyState>
  <div>
    <BillingPlanComparisonModal
      v-if="comparisonModalVisible"
      :billing-interaction
      @close-modal="comparisonModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsEmptyState } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import BillingPlanComparisonModal from '@/components/billing/BillingPlanComparisonModal.vue';

import useBilling from '@/composables/useBilling';
import { TEMP_SELF_SERVE_IMPROVEMENTS } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';
import { BillingInteraction } from '@/types/billing';

interface Props {
  pngName?: string;
  text?: string;
  title?: string;
  billingInteraction: BillingInteraction;
}

const props = defineProps<Props>();

const { handleBillingInteraction } = useBilling();
const { isFreeTier } = storeToRefs(useBillingStore());
const ffStore = useFeatureFlagStore();

const hasSelfServeImprovements = computed(() =>
  ffStore.hasFeatureFlag(TEMP_SELF_SERVE_IMPROVEMENTS),
);

const comparisonModalVisible = ref(false);
function onCtaClicked() {
  if (
    hasSelfServeImprovements.value &&
    isFreeTier.value &&
    !props.billingInteraction.talkToSalesReason
  ) {
    comparisonModalVisible.value = true;
  } else {
    handleBillingInteraction(props.billingInteraction);
  }
}
</script>

<style scoped lang="pcss">
.upsell-overlay {
  @apply w-full h-full absolute z-30;
  background: linear-gradient(
    180deg,
    theme(colors.white / 0) 0%,
    theme(colors.neutral.background) 55.08%
  );
}
</style>
<style lang="pcss">
.upsell-overlay {
  .c-bitts-empty-state-large {
    @apply w-full;
  }
  &.c-bitts-empty-state-large-border {
    @apply border-none rounded-none;
  }
}
</style>

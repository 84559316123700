<template>
  <div class="c-card-header">
    <span class="text-lg font-bold text-neutral-text-strong">
      {{ title }}
      <BittsTooltip
        v-if="!!infoTooltip"
        placement="top"
        class="c-card-header__tooltip"
      >
        <FontAwesomeIcon
          :icon="['fas', 'info-circle']"
          class="c-card-header__info-icon"
        />
        <template #title>
          {{ infoTooltip }}
        </template>
      </BittsTooltip>
    </span>
  </div>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import { useDataSharesStore, usePartnersStore } from '@/stores';

export default {
  name: 'CardHeader',
  components: {
    BittsTooltip,
  },
  props: {
    infoTooltip: {
      type: String,
      default: null,
    },
    source: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sharingTypes: [],
    };
  },
  computed: {
    partnerOrgs() {
      // De-dupe as these can repeat from the backend after xbdm
      const appliedOutgoingDataShares = Array.from(
        new Set(this.source.sharing.outgoing),
      ).map((dataShareId) => this.getDataShareById(dataShareId));
      const partnerOrgIds = new Set([
        ...appliedOutgoingDataShares.flatMap((dataShare) => {
          const partnerIds = [];
          dataShare.partner_populations.forEach((popInfo) => {
            partnerIds.push(popInfo.partner_population_id);
          });
        }),
      ]);
      return [...partnerOrgIds].map((orgId) => {
        const orgSendingDataShares = appliedOutgoingDataShares
          .filter((dataShare) => dataShare.partner_org_id === orgId)
          .map((dataShare) => this.getDataShareInfo(dataShare, 'sending'))
          .filter(
            (dataShareWithPopulationInfo) =>
              !!dataShareWithPopulationInfo.partnerPopulation,
          );

        return {
          ...this.getPartnerOrgById(orgId),
          dataShares: orgSendingDataShares,
        };
      });
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useDataSharesStore, [
      'getDataShareById',
      'getDataShareWithPopulationInfo',
    ]),
    getDataShareInfo(dataShare, type) {
      return {
        type,
        ...this.getDataShareWithPopulationInfo(dataShare.id),
      };
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-card-header {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-card-header__info-icon {
  @apply pl-2 text-m text-neutral-accent;
}
.c-card-header__tooltip {
  display: inline-block;
}
</style>

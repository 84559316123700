<template>
  <notification :item="item">
    <template #logo>
      <BittsSvg v-if="integrationType" :svg="integrationType + 'Icon'" />
      <BittsAvatar
        v-else
        :is-icon="true"
        :org="null"
        :user="null"
        icon-slot-bg-color="bg-beta-background-medium"
        size="medium"
      >
        <template #icon>
          <FontAwesomeIcon
            :icon="['far', 'building']"
            :style="{ height: '20px', width: '20px', color: 'white' }"
          />
        </template>
      </BittsAvatar>
    </template>
    <template #message> Connection Error </template>
    <template #description>
      <span> Your {{ friendlyName }} integration encountered an error </span>
    </template>
    <template #action>
      <BittsButton
        text="View Error"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewError"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'database']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-danger-accent ml-4"
      />
    </template>
  </notification>
</template>
<script>
import { BittsAvatar, BittsButton, BittsSvg } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import {
  SALESFORCE_DATA_SOURCE_TYPE,
  SALESFORCE_INTEGRATION_FRIENDLY_NAME,
  SNOWFLAKE_DATA_SOURCE_TYPE,
  SNOWFLAKE_INTEGRATION_FRIENDLY_NAME,
} from '@/constants/data_sources';
import { useNotificationsStore } from '@/stores';

export default {
  name: 'PushConnectionErrorNotification',
  components: {
    BittsAvatar,
    BittsButton,
    BittsSvg,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    friendlyName() {
      try {
        return this.item.data.integration_friendly_name;
      } catch (_e) {
        return '';
      }
    },
    integrationType() {
      switch (this.item.data.integration_friendly_name) {
        case SALESFORCE_INTEGRATION_FRIENDLY_NAME:
          return SALESFORCE_DATA_SOURCE_TYPE;
        case SNOWFLAKE_INTEGRATION_FRIENDLY_NAME:
          return SNOWFLAKE_DATA_SOURCE_TYPE;
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapActions(useNotificationsStore, ['markRead']),
    async viewError() {
      this.$emit('action-clicked', 'View Error');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'integrations',
      });
    },
  },
};
</script>
